import { AvatarFrame } from '../../types';
import React from 'react';
import styled from 'styled-components';

const AvatarPlaceholder = new URL('./placeholder.png', import.meta.url).href;
const BronzeWeeklyLeaderFrame = new URL('./Frames/weekly-leader-3.png', import.meta.url).href;
const SilverWeeklyLeaderFrame = new URL('./Frames/weekly-leader-2.png', import.meta.url).href;
const GoldWeeklyLeaderFrame = new URL('./Frames/weekly-leader-1.png', import.meta.url).href;

const FrameComponent: Record<AvatarFrame, string | null> = {
  [AvatarFrame.None]: null,
  [AvatarFrame.WeeklyLeaderGold]: GoldWeeklyLeaderFrame,
  [AvatarFrame.WeeklyLeaderSilver]: SilverWeeklyLeaderFrame,
  [AvatarFrame.WeeklyLeaderBronze]: BronzeWeeklyLeaderFrame,
};

const AvatarRoot = styled.div<{ $size?: number }>`
  position: relative;
  height: ${({ $size }) => ($size ? `${$size}px` : `100%`)};
  width: ${({ $size }) => ($size ? `${$size}px` : `100%`)};
`;

export const Image = styled.img<{ $avatar?: boolean }>`
  color: transparent;
  width: 100%;
  height: 100%;
  background: ${({ $avatar }) => ($avatar ? null : 'rgba(211, 206, 239, 0.2)')};
  object-fit: cover;
  text-align: center;
  text-indent: -9999px;
  border-radius: 50%;
`;

export const Frame = styled.div<{ $size?: number; $frame: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ $frame }) => `url(${$frame})`};
  background-size: contain;
  transform: scale(1.5);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
`;

interface AvatarProps {
  src?: string;
  size?: number | string;
  label?: string;
  frame?: AvatarFrame;
}

export const Avatar = React.forwardRef(
  ({ src, size, frame, ...props }: AvatarProps, ref?: React.Ref<any>) => {
    const mySize = typeof size === 'string' ? parseInt(size, 10) : size;

    const myFrame = FrameComponent[frame!];

    return (
      <AvatarRoot ref={ref} $size={mySize} {...props}>
        <Image src={src || AvatarPlaceholder} $avatar={!!src} />
        {myFrame && <Frame $frame={myFrame} />}
      </AvatarRoot>
    );
  },
);
