import React from 'react';
import { toast, ToastContent, ToastContentProps, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import CheckRoundSvg from './check-round.svg';
import DeniedRoundSvg from './denied-round.svg';
import InfoRoundSvg from './info-round.svg';
import WarningRoundSvg from './warning-round.svg';

const ToastBody = styled.div`
  align-items: center;
  display: flex;
`;

const ToastIcon = styled.svg`
  fill: currentColor;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
`;

const ToastContentWrapper = styled.div`
  margin-left: 15px;
`;

interface ToastBodyProps {
  children: React.ReactNode;
}

export const InfoToastBody = ({ children }: ToastBodyProps) => (
  <ToastBody>
    <ToastIcon as={InfoRoundSvg} />
    <ToastContentWrapper>{children}</ToastContentWrapper>
  </ToastBody>
);

export const SuccessToastBody = ({ children }: ToastBodyProps) => (
  <ToastBody>
    <ToastIcon as={CheckRoundSvg} />
    <ToastContentWrapper>{children}</ToastContentWrapper>
  </ToastBody>
);

export const ErrorToastBody = ({ children }: ToastBodyProps) => (
  <ToastBody>
    <ToastIcon as={DeniedRoundSvg} />
    <ToastContentWrapper>{children}</ToastContentWrapper>
  </ToastBody>
);

export const WarningToastBody = ({ children }: ToastBodyProps) => (
  <ToastBody>
    <ToastIcon as={WarningRoundSvg} />
    <ToastContentWrapper>{children}</ToastContentWrapper>
  </ToastBody>
);

export const infoToast = (content: ToastContent, options?: ToastOptions) => {
  if (typeof content === 'function') {
    return toast((props: ToastContentProps) => <InfoToastBody>{content(props)}</InfoToastBody>, {
      ...options,
    });
  }

  return toast(<InfoToastBody>{content}</InfoToastBody>, { ...options });
};

export const successToast = (content: ToastContent, options?: ToastOptions) => {
  if (typeof content === 'function') {
    return toast.success(
      (props: ToastContentProps) => <SuccessToastBody>{content(props)}</SuccessToastBody>,
      { ...options },
    );
  }

  return toast.success(<SuccessToastBody>{content}</SuccessToastBody>, {
    ...options,
  });
};

export const errorToast = (content: ToastContent, options?: ToastOptions) => {
  if (typeof content === 'function') {
    return toast.error(
      (props: ToastContentProps) => <ErrorToastBody>{content(props)}</ErrorToastBody>,
      { ...options },
    );
  }

  return toast.error(<ErrorToastBody>{content}</ErrorToastBody>, {
    ...options,
  });
};

export const warningToast = (content: ToastContent, options?: ToastOptions) => {
  if (typeof content === 'function') {
    return toast.warn(
      (props: ToastContentProps) => <WarningToastBody>{content(props)}</WarningToastBody>,
      { ...options },
    );
  }

  return toast.warn(<WarningToastBody>{content}</WarningToastBody>, {
    ...options,
  });
};
