import { CheckInStatus, FirestoreCheckIn } from './types';

export default class CheckInViewModel {
  readonly status: CheckInStatus;

  readonly startAt: Date;

  readonly finishAt: Date;

  readonly participants: string[];

  constructor(props?: { status: CheckInStatus; startAt: Date; finishAt: Date; participants: string[] }) {
    this.status = props?.status || CheckInStatus.Pending;
    this.startAt = props?.startAt || new Date();
    this.finishAt = props?.finishAt || new Date();
    this.participants = props?.participants || [];
  }

  static fromFirestore(data: FirestoreCheckIn): CheckInViewModel {
    if (data) {
      return new CheckInViewModel({
        finishAt: data?.finish_at?.toDate(),
        participants: (data?.participants || []).map((p) => p.user),
        startAt: data?.start_at?.toDate(),
        status: data?.status,
      });
    }
    return new CheckInViewModel({
      finishAt: new Date(),
      participants: [],
      startAt: new Date(),
      status: CheckInStatus.Finished,
    });
  }

  get isFinished() {
    return this.status === CheckInStatus.Finished;
  }

  get isActive() {
    return this.status === CheckInStatus.Active;
  }

  get isPending() {
    return this.status === CheckInStatus.Pending;
  }

  hasParticipant(id?: string): boolean {
    return Boolean(this.participants.find((p) => p === id));
  }
}
