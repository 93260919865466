import { useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import pDebounce from 'p-debounce';

import axiosClient from './axiosClient';
import ClientErrorCode from './ClientErrorCode';
import ErrorWithCode from './ErrorWithCode';
import useLoadingState from './useLoadingState';
import { Currency, Locale } from './types';

const debouncedPatch = pDebounce(axiosClient.patch, 1000);

const useUpdateLocaleAndCurrency = (user?: string) => {
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();
  const updateLocaleAndCurrency = async (locale: Locale, currency: Currency) => {
    if (!user) return;
    setLoading();
    try {
      await debouncedPatch(`/api/users/${user}`, { locale, currency });
      setLoaded();
    } catch (_error) {
      unstable_batchedUpdates(() => {
        setLoadFailed();
        setError(
          _error instanceof ErrorWithCode
            ? _error
            : new ErrorWithCode(ClientErrorCode.UnknownError),
        );
      });
    }
  };
  return {
    loadingState,
    error,
    updateLocaleAndCurrency,
  };
};

export default useUpdateLocaleAndCurrency;
