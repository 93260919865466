import React from 'react';
import ModalLayerContext from './ModalLayerContext';

interface ModalLayerContextProviderProps {
  children: React.ReactNode;
}

export default class ModalLayerContextProvider extends React.Component<ModalLayerContextProviderProps> {
  state = {
    active: 0,
  };

  register = () => {
    const active = this.state.active + 1;
    this.setState({ active });
    return active;
  };

  unregister = () => {
    this.setState({ active: this.state.active - 1 });
  };

  render() {
    return (
      <ModalLayerContext.Provider
        value={{ active: this.state.active, register: this.register, unregister: this.unregister }}
      >
        {this.props.children}
      </ModalLayerContext.Provider>
    );
  }
}
