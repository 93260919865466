enum JoinWolfPackChallengeErrorCode {
  InvalidWolfPackChallengeTeam = 400,
  InvalidEntriesBundle = 401,
  ExperienceNotFound = 402,
  InvalidExperienceType = 403,
  InvalidWolfPackChallengeStatus = 404,
  AlreadyParticipatesInExperience = 405,
  ProfileNotFound = 406,
  NotEnoughMoney = 407,
  CantJoinDifferentSweepstakeTeam = 408,
  InvalidParticipationMode = 409,
  CantJoinSweepstakeAsLeader = 410,
  SteamRequired = 411,
  TeamIsFull = 412,
  MaxEntriesReached = 413,
  AlternativeEntryAlreadyUsed = 414,
  InvalidFirstName = 415,
  InvalidLastName = 416,
  InvalidEmail = 417,
  InvalidWhy = 418,
  MaxParticipantsReached = 419, // TODO: remove
  Banned = 420,
  ExperienceInterferes = 421,
}

export default JoinWolfPackChallengeErrorCode;
