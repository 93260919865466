import {
  CreatePayPalCheckoutSessionResponse,
  CreateSkinsCashCheckoutSessionResponse,
  CreateStripeCheckoutSessionResponse,
  PaymentMethod,
} from '@/common/types';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import axiosClient from '../common/axiosClient';
import ClientErrorCode from '../common/ClientErrorCode';
import ErrorWithCode from '../common/ErrorWithCode';
import useLoadingState from '../common/useLoadingState';

type PaymentHandlerType = (bundlePrice: number, paymentPopup: Window) => Promise<string>;

const stripeHandler: PaymentHandlerType = async (bundlePrice, paymentPopup) => {
  const { data } = await axiosClient.post<CreateStripeCheckoutSessionResponse>(
    '/payments/checkouts/stripe',
    { price: bundlePrice },
  );

  if (paymentPopup?.location.href) {
    paymentPopup.location.href = `/payments/stripe/start?sessionId=${data.stripeSessionId}`;
  }

  return data.checkoutSessionId;
};

const payPalHandler: PaymentHandlerType = async (bundlePrice, paymentPopup) => {
  const { data } = await axiosClient.post<CreatePayPalCheckoutSessionResponse>(
    '/payments/checkouts/paypal',
    { price: bundlePrice },
  );

  if (paymentPopup?.location.href) {
    paymentPopup.location.href = data.approvalUrl;
  }

  return data.checkoutSessionId;
};

const skinsCashHandler: PaymentHandlerType = async (bundlePrice, paymentPopup) => {
  const { data } = await axiosClient.post<CreateSkinsCashCheckoutSessionResponse>(
    '/payments/checkouts/skins-cash',
    { price: bundlePrice },
  );

  if (paymentPopup?.location.href) {
    paymentPopup.location.href = data.checkoutUrl;
  }

  return data.checkoutSessionId;
};

const PaymentHandler: Record<PaymentMethod, PaymentHandlerType> = {
  [PaymentMethod.PayPal]: payPalHandler,
  [PaymentMethod.SkinsCash]: skinsCashHandler,
  [PaymentMethod.Stripe]: stripeHandler,
};

const usePayment = (paymentMethod: PaymentMethod, bundlePrice: number, paymentPopup: Window) => {
  const [checkoutSessionId, setCheckoutSessionId] = useState<string>();
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();
  useEffect(() => {
    (async () => {
      setLoading();
      try {
        const pay = PaymentHandler[paymentMethod];
        const sessionId = await pay(bundlePrice, paymentPopup);
        unstable_batchedUpdates(() => {
          setLoaded();
          setCheckoutSessionId(sessionId);
        });
      } catch (_error) {
        unstable_batchedUpdates(() => {
          setLoadFailed();
          setError(
            _error instanceof ErrorWithCode
              ? _error
              : new ErrorWithCode(ClientErrorCode.UnknownError),
          );
        });
      }
    })();
  }, [paymentMethod, bundlePrice, paymentPopup]);
  return {
    checkoutSessionId,
    loadingState,
    error,
  };
};

export default usePayment;
