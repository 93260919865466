enum UpdateWolfPackChallengeReadyCheckStatusErrorCode {
  InvalidParticipantId = 600,
  InvalidReadyCheckParticipantStatus = 601,
  ReadyCheckNotFound = 602,
  ReadyCheckFinished = 603,
  ParticipantNotFound = 604,
  ExperienceNotFound = 605,
  InvalidExperienceType = 606,
  InvalidWolfPackChallengeStatus = 607,
}

export default UpdateWolfPackChallengeReadyCheckStatusErrorCode;
