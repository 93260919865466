import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import React, { ReactNode, useContext } from 'react';

import Footer from '../footer/Footer';
import Header from './Header';
import NetworkStatusContext from '../serviceWorker/NetworkStatusContext';
import OfflineBanner from '../serviceWorker/OfflineBanner';
import Sidebar from '../sidebar/Sidebar';
import { BREAKPOINT_MEDIUM, Z_INDEX_5 } from './ui';

const Root = styled.div`
  /* background: #0a1023; */
  background: #15192c;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: inherit;
  flex-grow: 1;
  position: relative;
  margin-left: 60px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-left: 0;
  }
`;

const MyOfflineBanner = styled(OfflineBanner)`
  max-width: inherit;
  width: 100%;
  z-index: ${Z_INDEX_5};
`;

const Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
`;

interface TemporatyAdaptiveLayoutProps extends RouteComponentProps {
  children: ReactNode;
}

const TemporaryAdaptiveLayout = ({ children }: TemporatyAdaptiveLayoutProps) => {
  const { offline } = useContext(NetworkStatusContext);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Root>
        <MediaQuery minWidth={BREAKPOINT_MEDIUM}>
          <Sidebar />
        </MediaQuery>
        <Container>
          {offline && <MyOfflineBanner />}
          <StyledHeader />
          <Main>{children}</Main>
          <Footer />
        </Container>
      </Root>
    </>
  );
};

export default TemporaryAdaptiveLayout;
