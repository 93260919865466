/* eslint-disable react-hooks/rules-of-hooks */
import { Placement, PositioningStrategy } from '@popperjs/core';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import styled, { keyframes } from 'styled-components';

import Portal from './Portal';
import { Z_INDEX_8, FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from './ui';
import useCombinedRef from './useCombinedRef';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TooltipBody = styled.div`
  background: rgba(240, 244, 248, 1);
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(10, 16, 35, 0.1);
  color: rgba(10, 16, 35, 1);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  padding: 7px 15px;
  z-index: ${Z_INDEX_8};
  animation: ${appear} 200ms ease-in;
`;

const decorateEvent =
  (
    func: React.EventHandler<React.SyntheticEvent>,
    decorator: React.EventHandler<React.SyntheticEvent>,
  ): React.EventHandler<React.SyntheticEvent> =>
  (event: React.SyntheticEvent) => {
    event.persist();
    decorator(event);
    func(event);
  };

type Rect = {
  width: number;
  height: number;
  x: number;
  y: number;
};

type OffsetsFunction = (args: {
  popper: Rect;
  reference: Rect;
  placement: Placement;
}) => [number?, number?];

export interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactNode;
  placement?: Placement;
  offset?: OffsetsFunction | [number?, number?] | number | string;
  strategy?: PositioningStrategy;
  triggeredBy?: 'hover' | 'click';
  delay?: string | number;
  timeout?: string | number;
  disabled?: boolean;
  className?: string;
}

const Tooltip = React.forwardRef(
  (
    {
      content,
      placement = 'bottom',
      strategy = 'absolute',
      offset,
      triggeredBy = 'hover',
      delay = '500',
      timeout = 1000,
      disabled = false,
      className = '',
      children,
    }: TooltipProps,
    referenceRef,
  ) => {
    const [closeTimer, setCloseTimer] = useState<number | undefined>();
    const [showDelayTimer, setShowDelayTimer] = useState<number | undefined>();
    const [isVisible, setVisible] = useState<boolean>(false);
    const innerReferenceRef = useRef<any>();
    const [innerTooltipRef, setInnerTooltipRef] = useState<any>();
    const offsetFunction = useCallback<OffsetsFunction>(
      ({ placement: place }) => {
        if (place === 'bottom' || place === 'top') {
          return [0, Number(offset)];
        }
        return [Number(offset), 0];
      },
      [offset],
    );

    const showTooltip = () => {
      if (!disabled) setVisible(true);
    };
    const hideTooltip = () => setVisible(false);

    const modifiers = [];

    if (offset) {
      let offsetValue = offset;
      if (typeof offset === 'number' || typeof offset === 'string') {
        offsetValue = offsetFunction;
      }

      modifiers.push({
        name: 'offset',
        options: {
          offset: offsetValue,
        },
      });
    }

    const onMouseOver = () => {
      setShowDelayTimer(window.setTimeout(showTooltip, Number(delay)));
    };

    const onMouseOut = () => {
      clearTimeout(showDelayTimer);
      hideTooltip();
    };

    const handleBodyClick = (event: MouseEvent) => {
      if (isVisible) {
        const target = event.target as (Node & ParentNode) | null;

        if (!innerTooltipRef?.contains(target) && !innerReferenceRef.current?.contains(target)) {
          hideTooltip();
        }
      }
    };

    const onClick = () => {
      clearTimeout(closeTimer);

      unstable_batchedUpdates(() => {
        showTooltip();
        setCloseTimer(window.setTimeout(hideTooltip, Number(timeout)));
      });
    };

    useEffect(() => {
      document.addEventListener('click', handleBodyClick, { capture: true });

      return () => {
        document.removeEventListener('click', handleBodyClick, { capture: true });
      };
    }, [isVisible]);

    const getChildProps = (refProps: {
      onMouseOver?: React.MouseEventHandler;
      onMouseOut?: React.MouseEventHandler;
      onClick?: React.MouseEventHandler;
    }) => {
      const childProps: any = {};

      if (triggeredBy === 'hover') {
        childProps.onMouseOver = refProps.onMouseOver
          ? decorateEvent(refProps.onMouseOver, onMouseOver)
          : onMouseOver;
        childProps.onMouseOut = refProps.onMouseOut
          ? decorateEvent(refProps.onMouseOut, onMouseOut)
          : onMouseOut;
      } else {
        childProps.onClick = refProps.onClick ? decorateEvent(refProps.onClick, onClick) : onClick;
      }

      return childProps;
    };

    return (
      <Manager>
        <Reference>
          {({ ref }) =>
            React.cloneElement(children, {
              ...children.props,
              ...getChildProps(children.props),
              ref: useCombinedRef(ref, referenceRef, innerReferenceRef),
            })
          }
        </Reference>
        {isVisible && (
          <Portal>
            <Popper placement={placement} modifiers={modifiers} strategy={strategy}>
              {({ ref, style }) => (
                <TooltipBody
                  ref={useCombinedRef(ref, setInnerTooltipRef)}
                  style={style}
                  className={className}
                >
                  {content}
                </TooltipBody>
              )}
            </Popper>
          </Portal>
        )}
      </Manager>
    );
  },
);

export default Tooltip;
