enum CreateDuelOfHonorCounterStrikeErrorCode {
  InvalidRivalId = 3101,
  ChallengerNotFound = 3102,
  ChallengerSteamRequired = 3103,
  RivalNotFound = 3104,
  InvalidMessage = 3105,
  InvalidDonation = 3106,
  RivalNotPro = 3107,
  NotEnoughMoney = 3108,
  RivalIsSameUserAsChallenger = 3109,
  RequestAlreadySent = 3110,
}

export default CreateDuelOfHonorCounterStrikeErrorCode;
