enum WolfPackChallengeCheckInErrorCode {
  WolfPackChallengeNotFound = 4000,
  InvalidWolfPackChallengeMode = 4001,
  InvalidCheckInStatus = 4002,
  WolfPackChallengeParticipantNotFound = 4003,
  WolfPackChallengeParticipantIsLeader = 4004,
  AlreadyHasCheckIn = 4005,
}

export default WolfPackChallengeCheckInErrorCode;
