import React, { ReactNode } from 'react';
import styled from 'styled-components';

import BackIconSvg from './back-icon.svg';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from './ui';

const Button = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.02em;
  padding: 0;
  width: max-content;

  &:focus:not(:focus-visible),
  &:hover {
    color: rgba(255, 255, 255, 0.65);
  }
`;

const Icon = styled(BackIconSvg)`
  fill: currentColor;
  height: 9px;
`;

const Content = styled.span`
  margin-left: 5px;
`;

interface BackButtonProps {
  className?: string;
  onClick: () => void;
  children: ReactNode;
}

const BackButton = ({ className, onClick, children }: BackButtonProps) => (
  <Button className={className} type="button" onClick={onClick}>
    <Icon />
    <Content>{children}</Content>
  </Button>
);

export default BackButton;
