import { navigate } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';

import { EntriesReceivedNotificationView } from '../Views';
import getExperiencePagePath from '@/paths/getExperiencePagePath';
import NotificationConsumer from './NotificationConsumer';
import { NotificationType } from '../../types';
import NotificationViewModel from '../model/NotificationViewModel';

class EntriesReceivedNotificationConsumer implements NotificationConsumer {
  type = NotificationType.EntriesReceived;

  onClick = (notification: NotificationViewModel) => {
    navigate(getExperiencePagePath(notification.experience!.id));
  };

  perform(notification: NotificationViewModel, read: () => void) {
    toast.info(this.render(notification), {
      onClick: () => {
        this.onClick(notification);
        read();
      },
      toastId: notification.id,
      hideProgressBar: true,
    });
  }

  render(notification: NotificationViewModel) {
    return <EntriesReceivedNotificationView notification={notification} />;
  }
}

export default EntriesReceivedNotificationConsumer;
