enum ChatRoomWolfredMessage {
  InitialMessage = 'initialMessage',
  NewParticipantArrivedInBuyout = 'newParticipantArrivedInBuyout',
  NewParticipantArrivedInSweepstake = 'newParticipantArrivedInSweepstake',
  ParticipantIncreasedChancesInSweepstake = 'participantIncreasedChancesInSweepstake',
  ParticipantKicked = 'participantKicked',
  ExperienceFailed = 'experienceFailed',
  ExperienceFinished = 'experienceFinished',
  ChatRoomClosed = 'chatRoomClosed',
  ParticipantBanned = 'participantBanned',
}

export default ChatRoomWolfredMessage;
