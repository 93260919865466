import { NotificationType } from '../types';
import { createContext } from 'react';

import NotificationConsumer from './Consumers/NotificationConsumer';
import NotificationModel from './model/NotificationViewModel';

export interface NotificationsContextType {
  notifications: NotificationModel[];
  consumers: Map<NotificationType, NotificationConsumer>;
  read: (notificationId: string) => Promise<any> | void;
  readAll: () => Promise<any> | void;
}

const getNotificationContext = () => ({
  consumers: new Map<NotificationType, NotificationConsumer>(),
  notifications: [],
  read: () => {},
  readAll: () => {},
});

const NotificationsContext = createContext<NotificationsContextType>(getNotificationContext());

export default NotificationsContext;
