import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from '../styleConstants';

import ApplyArrowIcon from './ApplyArrow.svg';
import LockedIcon from './Locked.svg';

interface MessageInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}

const LockedSvg = styled(LockedIcon)`
  margin-left: 10px;
`;

const InputRoot = styled.form`
  position: relative;
  height: 52px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input<MessageInputProps>`
  background: 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 16px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  height: 100%;
  padding-right: 40px;
  padding-left: 10px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: rgba(211, 206, 239, 0.2);
  }

  &:disabled {
    color: rgba(211, 206, 239, 0.4);
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
`;

const SendMessageButton = styled.button`
  position: absolute;
  right: 10px;
  opacity: 0.5;
  border: none;
  padding: 0;
  height: 24px;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

interface ChatInputProps extends MessageInputProps {
  sendMessageAction: (e: any) => any;
}

export const ChatInput = ({
  sendMessageAction,
  value,
  placeholder,
  onChange,
  disabled,
}: ChatInputProps) => (
  <InputRoot onSubmit={sendMessageAction}>
    {disabled && <LockedSvg />}
    <StyledInput
      disabled={disabled}
      onChange={(e: any) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value}
    />
    {!disabled && (
      <SendMessageButton disabled={!value}>
        <ApplyArrowIcon />
      </SendMessageButton>
    )}
  </InputRoot>
);
