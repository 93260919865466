import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { ReactNode, Component, ReactText } from 'react';
import { toast } from 'react-toastify';
import { messageSW } from 'workbox-window/messageSW';
import {
  WorkboxLifecycleWaitingEvent,
  WorkboxLifecycleEvent,
} from 'workbox-window/utils/WorkboxEvent';
import { Workbox } from 'workbox-window/Workbox';
import { infoToast, SuccessToastBody } from '../common/Toast';
import i18n from '../i18n/i18n';

import MessageType from './MessageType';
import NetworkStatusContext from './NetworkStatusContext';
import onVisibilityChange from './onVisibilityChange';
import { ServiceWorkerContext, ServiceWorkerContextType } from './ServiceWorkerContext';

declare global {
  interface Window {
    ServiceWorkerManager: ServiceWorkerManager;
  }
}

interface ServiceWorkerManagerProps {
  children: ReactNode;
  scope?: string;
}

interface ServiceWorkerManagerState {
  isUpdateAvailable: boolean;
  isUpdating: boolean;
  version: string;
  newVersion?: string;
  online: boolean;
}

const wait = (t: number) => new Promise((res) => setTimeout(res, t));

class ServiceWorkerManager extends Component<ServiceWorkerManagerProps, ServiceWorkerManagerState> {
  static RELOAD_TIME = 15000;

  static MIN_UPDATE_TIME = 3500;

  workbox: Workbox;

  registration?: ServiceWorkerRegistration;

  clearListeners: () => void;

  updateToastId?: ReactText;

  updateStartedAt?: number;

  constructor(props: ServiceWorkerManagerProps) {
    super(props);

    if (process.env.DEBUG) {
      window.ServiceWorkerManager = this;
    }

    // @ts-ignore
    this.workbox = new Proxy(
      {},
      {
        get() {
          console.warn(`Your Workbox instance wasn't created yet.`);
        },
      },
    );
    this.clearListeners = () => {};

    this.state = {
      isUpdateAvailable: false,
      isUpdating: false,
      version: '0.0.0',
      online: true,
    };
  }

  componentDidMount() {
    if (
      (process.env.NODE_ENV === 'production' || process.env.DEBUG) &&
      'serviceWorker' in navigator
    ) {
      const { scope } = this.props;

      this.workbox = new Workbox('/service-worker.js', { scope });
      this.workbox.addEventListener('waiting', this.onServiceWorkerWaiting);
      this.workbox.addEventListener('controlling', this.onServiceWorkerControlling);
      this.workbox.addEventListener('activated', this.onServiceWorkerActivated);
      this.workbox.register({ immediate: true }).then(this.onRegister);

      const removeVisibilityChangeListener = onVisibilityChange(this.checkForUpdate);
      window.addEventListener('online', this.setNetworkStatusOnline);
      window.addEventListener('offline', this.setNetworkStatusOffline);
      window.addEventListener('message', this.handleMessage);

      this.clearListeners = () => {
        removeVisibilityChangeListener();
        window.removeEventListener('online', this.setNetworkStatusOnline);
        window.removeEventListener('offline', this.setNetworkStatusOffline);
        window.removeEventListener('message', this.handleMessage);
      };
    }
  }

  setNetworkStatusOnline = () => {
    this.setState({
      online: true,
    });
  };

  setNetworkStatusOffline = () => {
    this.setState({
      online: false,
    });
  };

  onRegister = async (registration?: ServiceWorkerRegistration) => {
    if (process.env.DEBUG) {
      console.group('Service Worker was registered');
      console.dir(registration);
      console.groupEnd();
    }
    this.registration = registration;
    this.getCurrentAppVersion();
  };

  checkForUpdate = async () => {
    const { online } = this.state;
    if (!online || !this.registration || !this.workbox) return;

    if (process.env.DEBUG) {
      console.log('Checking for update...');
    }

    try {
      await this.workbox.update();
    } catch (error) {
      console.log(error);
      if (/Failed to update a ServiceWorker/.test(error.message)) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.allSettled(registrations.map((registration) => registration.unregister()));
        window.location.reload();
      }
    }

    if (this.registration?.waiting) {
      const { version: currentVersion } = this.state;
      const nextVersion = await this.getServiceWorkerVersion(this.registration.waiting);

      if (currentVersion !== nextVersion) {
        this.setState({
          newVersion: nextVersion,
          isUpdateAvailable: true,
        });
      }
    }
  };

  getServiceWorkerVersion = async (serviceWorker?: ServiceWorker) => {
    if (serviceWorker instanceof ServiceWorker) {
      const v = await messageSW(serviceWorker, { type: MessageType.GET_VERSION });
      return v;
    }

    if (this.registration?.active) {
      const v = await messageSW(this.registration?.active, { type: MessageType.GET_VERSION });
      return v;
    }

    const v = await this.workbox.messageSW({ type: MessageType.GET_VERSION });
    return v;
  };

  getServiceWorkerStatus = async (serviceWorker?: ServiceWorker) => {
    if (serviceWorker instanceof ServiceWorker) {
      const status = await messageSW(serviceWorker, { type: MessageType.GET_STATUS });
      return status;
    }

    const status = await this.workbox.messageSW({ type: MessageType.GET_STATUS });
    return status;
  };

  onServiceWorkerWaiting = async (event: WorkboxLifecycleWaitingEvent) => {
    if (process.env.DEBUG) {
      console.group('Service Worker is waiting:');
      console.dir(event);
      console.groupEnd();
    }

    const { version: currentVersion } = this.state;
    const nextVersion = await this.getServiceWorkerVersion(event.sw!);
    const status = await messageSW(event.sw!, { type: MessageType.GET_STATUS });

    if (status === 'skipping') {
      this.updateStartedAt = 0;
      this.updateToastId = infoToast(
        i18n.t('serviceWorker:update.skipping', { version: nextVersion }),
        {
          autoClose: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          draggable: false,
          closeButton: false,
          pauseOnHover: false,
        },
      );
      return;
    }

    if (currentVersion !== nextVersion) {
      this.updateStartedAt = Date.now();
      this.updateToastId = infoToast(
        i18n.t('serviceWorker:update.updating', { version: nextVersion }),
        {
          autoClose: 30000,
          closeOnClick: false,
          hideProgressBar: false,
          pauseOnFocusLoss: false,
          draggable: false,
          closeButton: false,
          onClose: () => window.location.reload(),
          pauseOnHover: false,
        },
      );

      this.setState({
        newVersion: nextVersion,
        isUpdateAvailable: true,
      });
      this.update();
    }
  };

  onServiceWorkerControlling = async () => {
    const updateTook = Date.now() - (this.updateStartedAt ?? Date.now());
    await wait(ServiceWorkerManager.MIN_UPDATE_TIME - updateTook);

    // Reload in n sec. even if not toasted
    setTimeout(() => window.location.reload(), ServiceWorkerManager.RELOAD_TIME);

    toast.update(this.updateToastId!, {
      render: () => (
        <SuccessToastBody>
          {i18n.t('serviceWorker:update.installed', {
            count: ServiceWorkerManager.RELOAD_TIME / 1000,
          })}
        </SuccessToastBody>
      ),
      type: toast.TYPE.SUCCESS,
      autoClose: ServiceWorkerManager.RELOAD_TIME,
      onClick: () => window.location.reload(),
      closeOnClick: true,
      pauseOnFocusLoss: false,
    });
  };

  getCurrentAppVersion = async () => {
    if (process.env.DEBUG) {
      console.log(`Getting current app version`);
    }
    const version = await this.getServiceWorkerVersion();

    this.setState(
      {
        version,
      },
      () => {
        Sentry.setContext('ServiceWorker', {
          version,
        });
        window.intercomSettings.swVersion = version;
        if (process.env.DEBUG) {
          console.log(`You are running app of version ${version}`);
        }
      },
    );
  };

  handleMessage = async (event: MessageEvent) => {
    if (process.env.DEBUG) {
      console.groupCollapsed('Window received an event message:');
      console.dir(event);
      console.groupEnd();
    }

    const message = 'ok';

    switch (event.data.type) {
      case MessageType.RELOAD_CLIENT:
        window.location.reload();
        break;
      default:
        break;
    }

    event.ports.forEach((port) => {
      port.postMessage(message);
    });
  };

  onServiceWorkerActivated = async (event: WorkboxLifecycleEvent) => {
    if (event.isUpdate && event.sw) {
      await messageSW(event.sw, { type: MessageType.CLIENTS_CLAIM });
    }

    // this.getCurrentAppVersion();
  };

  update = () => {
    const { isUpdating } = this.state;
    if (isUpdating) return;

    this.setState(
      {
        isUpdating: true,
      },
      () => {
        this.workbox.messageSkipWaiting();
      },
    );
  };

  render() {
    const {
      props: { children },
      state: { isUpdateAvailable, isUpdating, version, newVersion, online },
      update,
      checkForUpdate,
    } = this;

    const contextValue: ServiceWorkerContextType = {
      isUpdateAvailable,
      isUpdating,
      version,
      update,
      checkForUpdate,
      newVersion,
      isInstalled: Boolean(this.registration),
    };

    return (
      <ServiceWorkerContext.Provider value={contextValue}>
        <NetworkStatusContext.Provider value={{ online, offline: !online }}>
          {children}
        </NetworkStatusContext.Provider>
      </ServiceWorkerContext.Provider>
    );
  }
}

export default ServiceWorkerManager;
