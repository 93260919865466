enum WolfPackChallengeNotificationType {
  Challenged = 'challenged',
  Accepted = 'accepted',
  ExperienceStartsSoon = 'experienceStartsSoon',
  ReadyCheck = 'readyCheck',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Launched = 'launched',
}

export default WolfPackChallengeNotificationType;
