import { getAuth } from 'firebase/auth';
import { clearIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import { getApp, getApps, initializeApp } from 'firebase/app';

import firebaseConfig from './firebaseConfig';

if (!getApps().length) {
  initializeApp(firebaseConfig);
}

export const app = getApp();

export const auth = getAuth(app);

export const firestore = getFirestore(app);

clearIndexedDbPersistence(firestore).catch(() => {});
