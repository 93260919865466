import FirestoreGame from '../pages/Experience/model/game/FirestoreGame';

export default class GameViewModel {
  readonly id: string;

  readonly name: string;

  readonly bannerURL: string;

  readonly boxArtURL: string;

  readonly logoURL: string;

  readonly iconURL: string;

  constructor(props: {
    id: string;
    name: string;
    bannerURL: string;
    boxArtURL: string;
    logoURL: string;
    iconURL: string;
  }) {
    this.id = props.id;
    this.name = props.name;
    this.bannerURL = props.bannerURL;
    this.boxArtURL = props.boxArtURL;
    this.logoURL = props.logoURL;
    this.iconURL = props.iconURL;
  }

  static fromFirestore(data: FirestoreGame): GameViewModel {
    return new GameViewModel({
      id: data.id,
      name: data.name,
      bannerURL: data.banner_url,
      boxArtURL: data.box_art_url,
      logoURL: data.logo_url,
      iconURL: data.icon_url,
    });
  }
}
