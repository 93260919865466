enum UpdateDuelOfHonorReadyCheckStatusErrorCode {
  InvalidParticipantId = 600,
  InvalidReadyCheckParticipantStatus = 601,
  ReadyCheckNotFound = 602,
  ReadyCheckFinished = 603,
  ParticipantNotFound = 604,
  ExperienceNotFound = 605,
  InvalidExperienceType = 606,
  InvalidDuelOfHonorStatus = 607,
}

export default UpdateDuelOfHonorReadyCheckStatusErrorCode;
