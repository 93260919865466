import { Link } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AmpliWrapper from '@/components/AmpliWrapper';
import NavLink from '@/common/NavLink';
import SessionContext from '@/common/SessionContext';
import { Avatar, Tooltip } from '@/common/ui';

import getCampaignPagePath from '@/paths/getCampaignPagePath';
import getExperiencesPagePath from '@/paths/getExperiencesPagePath';
import getHomePagePath from '@/paths/getHomePagePath';
import getProfilePagePath from '@/paths/getProfilePagePath';
import getTournamentsPath from '../tournaments/getTournamentsPath';
import prosPagePath from '../pros/prosPagePath';

import CharitySvg from '@/assets/icons/charity.svg';
import ExperiencesSvg from './experiences.svg';
import GGSvg from '../common/GloryToUkraine/logo-glory-to-ukraine.svg';
import ProsSvg from './pros.svg';
import TournamentsSvg from './tournaments.svg';

const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(32, 34, 55, 1);
  z-index: 1;
`;

const Container = styled.div`
  position: sticky;
  top: 0;
  padding: 30px 5px;
  width: 100%;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavLinks = styled.div`
  padding: 30px 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 2px solid rgba(210, 207, 236, 5%);
  border-bottom: 2px solid rgba(210, 207, 236, 5%);
`;

const NavItem = styled(NavLink)`
  &:not(:first-child) {
    margin-top: 45px;
  }

  &[data-active='true'] {
    svg {
      fill: #fff;
    }
  }

  &[aria-current='page'] {
    pointer-events: none;
    cursor: default;
    svg {
      fill: #54acf3;
    }
  }

  svg {
    fill: #8f909b;

    &:focus:not(:focus-visible),
    &:hover {
      fill: #fff;
    }
  }
`;

const Followers = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 330px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FollowersOverlay = styled.div`
  background: linear-gradient(180deg, rgba(32, 34, 55, 0) 0%, #202237 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
`;

const UserInfoWrapper = styled.div`
  align-items: center;
  display: inline-flex;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const StyledAvatar = styled(Avatar)`
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
`;

const Sidebar = () => {
  const { privateProfile } = useContext(SessionContext);
  const { t } = useTranslation('sidebar');
  const [isOverlayed, setIsOverlayed] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollListener = () => {
    const cur = scrollRef.current;
    if (cur) {
      const canBeScrolled = !(cur.offsetHeight + cur.scrollTop >= cur.scrollHeight);
      setIsOverlayed(canBeScrolled);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', scrollListener);
      return () => {
        scrollRef.current?.removeEventListener('scroll', scrollListener);
      };
    }
  }, []);

  return (
    <Root>
      <Container>
        <AmpliWrapper event="click_left_menu" data={{ item: 'click_logo_left_menu' }}>
          <LogoLink to={getHomePagePath()}>
            <GGSvg />
          </LogoLink>
        </AmpliWrapper>

        <NavLinks>
          <Tooltip offset={[0, 5]} placement="right" content={<div>{t('experiences')}</div>}>
            <AmpliWrapper event="click_left_menu" data={{ item: 'click_experience_left_menu' }}>
              <NavItem to={getExperiencesPagePath()}>
                <ExperiencesSvg />
              </NavItem>
            </AmpliWrapper>
          </Tooltip>

          <Tooltip offset={[0, 5]} placement="right" content={<div>{t('charity')}</div>}>
            <NavItem to={getCampaignPagePath('streamers-with-ukraine')}>
              <CharitySvg />
            </NavItem>
          </Tooltip>

          <Tooltip offset={[0, 5]} placement="right" content={<div>{t('tournaments')}</div>}>
            <AmpliWrapper event="click_left_menu" data={{ item: 'click_tournaments_left_menu' }}>
              <NavItem to={getTournamentsPath()}>
                <TournamentsSvg />
              </NavItem>
            </AmpliWrapper>
          </Tooltip>

          <Tooltip offset={[0, 5]} placement="right" content={<div>{t('pros')}</div>}>
            <AmpliWrapper event="click_left_menu" data={{ item: 'click_streamers_left_menu' }}>
              <NavItem to={prosPagePath.getPath()}>
                <ProsSvg />
              </NavItem>
            </AmpliWrapper>
          </Tooltip>
        </NavLinks>

        <Followers ref={scrollRef}>
          {privateProfile?.follows?.map((follow) => (
            <Tooltip
              key={`tooltip-${follow.id}`}
              offset={[0, 5]}
              placement="right"
              content={<div>{follow.username}</div>}
            >
              <UserInfoWrapper>
                <Link to={getProfilePagePath(follow.id)}>
                  <StyledAvatar src={follow.avatar_url!} size={40} />
                </Link>
              </UserInfoWrapper>
            </Tooltip>
          ))}
          {isOverlayed && <FollowersOverlay />}
        </Followers>
      </Container>
    </Root>
  );
};

export default Sidebar;
