import React from 'react';
import styled from 'styled-components';

import Image from '@/components/Image';

const ImageEl = styled(Image)`
  opacity: 0.15;
  position: absolute;
  right: -55px;
  top: 0;
  height: 100%;
`;

interface Props {
  src: string;
}

const CardBackground: React.FC<Props> = (props) => {
  const { src } = props;
  return <ImageEl src={src} />;
};

export default CardBackground;
