import { Timestamp } from 'firebase/firestore';

export enum CheckInStatus {
  Pending = 'pending',
  Active = 'active',
  Finished = 'finished',
}

export interface FirestoreCheckIn {
  status: CheckInStatus;
  finish_at: Timestamp;
  start_at: Timestamp;
  participants?: {
    user: string;
    created_at: Timestamp;
  }[];
}
