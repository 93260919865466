import React from 'react';
import styled from 'styled-components';

import { ScrollMixin } from '../mixins';

import ChatSvg from './chat-icon.svg';

const ChatRoomRoot = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ChatRoomBody = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-bottom: 10px;
  }

  ${ScrollMixin}
`;

const ChatIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ChatProps {
  messages: React.ReactNode[] | React.ReactNode;
}

export const Chat = React.forwardRef(({ messages, ...props }: ChatProps, ref: React.Ref<any>) => (
  <ChatRoomRoot {...props}>
    <ChatRoomBody ref={ref}>
      <ChatIconWrapper>
        <ChatSvg />
      </ChatIconWrapper>

      {messages}
    </ChatRoomBody>
  </ChatRoomRoot>
));

export * from './ChatInput';
export * from './ChatInfo';
export * from './ChatMessage';
export * from './ChatUser';
export * from './SystemMessage';
export * from './DonateMessage';
export * from './ChatArrow';
