import styled from 'styled-components';
import { useMatch } from '@reach/router';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UserRewardTotal, UserRewardType } from './types';

import getProfilePagePath from '@/paths/getProfilePagePath';
import getSettingsPagePath from '../settings/getSettingsPagePath';

import SettingsCategory from '../settings/SettingsCategory';

import Button from './Button';
import SessionContext from './SessionContext';
import { FONT_FAMILY_ROBOTO, FONT_NAME_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from './ui';

const RootElement = styled.div`
  background: #fbc02d;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
`;

const Description = styled.p`
  color: #100930;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 1.1;
  margin: 0 45px 0 15px;
`;

const FsRewards = styled.span`
  color: #100930;
  font-family: ${FONT_NAME_BRUTAL_TYPE};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.1;
`;

const Action = styled(Button).attrs({ type: 'transparent' })`
  background: rgba(16, 9, 48, 0.1);
  color: #100930;
  height: 24px;
  font-size: 14px;

  &:focus:not(:focus-visible),
  &:hover {
    background: rgba(16, 9, 48, 0.05);
    color: #100930;
  }
`;

interface RewardsBannerProps {
  className?: string;
  rewards: Partial<Record<UserRewardType, number>>;
}

const RewardsBanner = ({ className, rewards }: RewardsBannerProps) => {
  const { user } = useContext(SessionContext);
  const match = useMatch(getProfilePagePath(user?.uid!));
  const { t } = useTranslation('rewards');
  const rewardTypes = Object.values(UserRewardType).map((it) => it as UserRewardType);
  const totalRewardAmount = rewardTypes
    .filter((it) => !rewards[it])
    .reduce((acc, cur) => acc + UserRewardTotal[cur], 0);
  const availableRewards = rewardTypes.filter((r) => !rewards[r]).map((it) => t(`rewards.${it}`));
  const isEmailRemainig = rewards.discord && rewards.steam;

  return (
    <RootElement className={className}>
      {!match && (
        <Content>
          <Description>
            <Trans
              i18nKey="rewards:description"
              values={{ amount: totalRewardAmount, rewards: availableRewards }}
              components={{ fs: <FsRewards /> }}
            />
          </Description>

          <Action
            href={getSettingsPagePath(isEmailRemainig ? SettingsCategory.Security : SettingsCategory.Connections)}
          >
            {t('button')}
          </Action>
        </Content>
      )}
    </RootElement>
  );
};

export default RewardsBanner;
