import { useCallback, useState } from 'react';
import React from 'react';
import StreamerModeContext from './StreamerModeContext';

interface StreamerModeContextProviderProps {
  children: React.ReactNode;
}

const getInitialStreamerMode = () => {
  const streamerMode = localStorage.getItem('streamerMode');
  if (streamerMode) {
    return JSON.parse(streamerMode).visible;
  }
  return false;
};

const DEFAULT_AVATAR =
  'https://storage.googleapis.com/prod-6dc34.appspot.com/avatars/Default-Avatar.png';

const WOLF_NAMES = [
  'White',
  'Rex',
  'Butler',
  'Barney',
  'Skiff',
  'Tucker',
  'Mike',
  'Baron',
  'Polkan',
  'Dante',
  'Chester',
  'Olympus',
  'Spartacus',
  'Bond',
  'Geta',
  'Oscar',
  'Gray',
  'Zeus',
  'Wonder',
  'Pike',
  'Pulion',
  'Casper',
  'Preston',
  'Toby',
  'Troy',
  'Otho',
  'Tiberius',
  'Harold',
  'Cliff',
  'Nord',
  'Onyx',
  'Hans',
  'Nick',
  'Bike',
  'Osborn',
  'Green',
  'Lexus',
  'Luke',
  'Bim',
  'Tyson',
  'Apollo',
  'Ice',
  'Jerry',
  'Snake',
  'Scout',
  'Nike',
  'Ernie',
  'Cooper',
  'Ringo',
  'Oliver',
  'Duncan',
  'Hans',
  'Rockefeller',
  'Thunder',
  'Loyd',
  'James',
  'Zidane',
  'Georges',
  'Henry',
  'Plato',
  'Ronnie',
  'Sheldon',
  'Shah',
  'Stifler',
  'Heidi',
  'Harris',
  'Walter',
  'Torias',
  'Zach',
  'Jacques',
  'Prime',
  'Rolf',
  'Buddy',
  'Rocky',
  'Yarmak',
  'Clark',
  'Claude',
  'Rudolph',
  'Walt',
  'Vegas',
  'Lyon',
  'Osman',
  'Luke',
  'Sonic',
  'Texas',
  'Diesel',
  'Hardy',
  'Maurice',
  'Kazbek',
  'Simon',
  'Messi',
  'Cupid',
  'Gore',
  'Yakut',
  'Raymond',
  'Timon',
  'Teach',
  'Sparkley',
  'Chase',
  'Shaman',
];

const StreamerModeContextProvider = ({ children }: StreamerModeContextProviderProps) => {
  const [isStreamerMode, setMode] = useState(getInitialStreamerMode);

  const setStreamerMode = useCallback(() => {
    setMode((state: boolean) => {
      localStorage.setItem('streamerMode', JSON.stringify({ visible: !state }));
      return !state;
    });
  }, []);

  const getWolfName = (id: string) => {
    const index = Math.abs(
      id.split('').reduce((result, item) => {
        result = (result << 5) - result + item.charCodeAt(0);
        return result & result;
      }, 0),
    );
    return WOLF_NAMES[Number(index.toString().slice(0, 2))];
  };

  return (
    <StreamerModeContext.Provider
      value={{ isStreamerMode, setStreamerMode, defaultAvatar: DEFAULT_AVATAR, getWolfName }}
    >
      {children}
    </StreamerModeContext.Provider>
  );
};

export default StreamerModeContextProvider;
