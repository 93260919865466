import { useEffect, useState } from 'react';
import useQueryParams from '@/common/useQueryParams';

export interface CampaignsReferrers {
  [campaign: string]: string | undefined;
}

const useCampaignReferrer = (campaign: string) => {
  const { fundraiser } = useQueryParams<{ fundraiser: string }>();
  const [data, setData] = useState<CampaignsReferrers>({});
  useEffect(() => {
    if (fundraiser) {
      setData((prevData) => {
        const newData = {
          ...prevData,
          [campaign]: fundraiser,
        };
        return newData;
      });
    }
  }, [fundraiser]);

  return data[campaign];
};

export default useCampaignReferrer;
