import { CheckoutSession, Database } from '@/common/types';
import { doc, DocumentSnapshot, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import ClientErrorCode from '../common/ClientErrorCode';
import ErrorWithCode from '../common/ErrorWithCode';
import { firestore } from '../common/firebaseClient';
import useLoadingState from '../common/useLoadingState';

import CheckoutSessionViewModel from './CheckoutSessionViewModel';

const useCheckoutSession = (checkoutSessionId?: string) => {
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSessionViewModel>();
  const { loadingState, setLoading, setLoaded, setLoadFailed, reset } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();

  const onFirestoreSnapshot = (snapshot: DocumentSnapshot) => {
    const data = snapshot.data() as CheckoutSession | undefined;
    if (!data) {
      unstable_batchedUpdates(() => {
        setLoadFailed();
        setCheckoutSession(undefined);
        setError(new ErrorWithCode(ClientErrorCode.UnknownError));
      });
      return;
    }
    unstable_batchedUpdates(() => {
      setLoaded();
      setError(undefined);
      setCheckoutSession({
        id: snapshot.id,
        status: data.status,
        paymentMethod: data.paymentMethod,
      });
    });
  };

  const onFirestoreSnapshotError = (_error: FirestoreError) => {
    console.log(_error);
    unstable_batchedUpdates(() => {
      setLoadFailed();
      setCheckoutSession(undefined);
      setError(new ErrorWithCode(ClientErrorCode.UnknownError));
    });
  };

  useEffect(() => {
    if (!checkoutSessionId) {
      return () => {};
    }
    setLoading();
    const unsubscribe = onSnapshot(
      doc(firestore, Database.CheckoutSessions, checkoutSessionId),
      onFirestoreSnapshot,
      onFirestoreSnapshotError
    );
    return () => {
      unstable_batchedUpdates(() => {
        reset();
        setError(undefined);
        setCheckoutSession(undefined);
      });
      unsubscribe();
    };
  }, [checkoutSessionId]);

  return {
    checkoutSession,
    loadingState,
    error,
  };
};

export default useCheckoutSession;
