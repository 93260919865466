import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PrimaryHeading from '@/common/PrimaryHeading';
import PrimaryParagraph from '@/common/PrimaryParagraph';

const DescriptionWrapper = styled.div`
  margin-top: 5px;
`;

const HintWrapper = styled.div`
  margin-top: 15px;

  ${PrimaryParagraph} {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Link = styled.a`
  color: inherit;
`;

interface ForgotPasswordSuccessProps {
  email: string;
}

const ForgotPasswordSuccess = ({ email }: ForgotPasswordSuccessProps) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <PrimaryHeading as="h3">{t('forgotPasswordSuccess.heading')}</PrimaryHeading>
      <DescriptionWrapper>
        <PrimaryParagraph>{t('forgotPasswordSuccess.description', { email })}</PrimaryParagraph>
      </DescriptionWrapper>
      <HintWrapper>
        <PrimaryParagraph $size="small">
          {t('forgotPasswordSuccess.hint')}&nbsp;
          <Link href="mailto:support@organization.gg">support@organization.gg</Link>.
        </PrimaryParagraph>
      </HintWrapper>
    </>
  );
};

export default ForgotPasswordSuccess;
