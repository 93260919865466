/// <reference path="index.d.ts" />

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { render } from 'react-dom';

import ampli from '@/services/ampli';
import Application from './Application';

ampli.init();

const rootElement = document.getElementById('react-root');

Sentry.init({
  dsn:
    process.env.FIREBASE_ENV === 'production'
      ? 'https://e172648b7bdd44cbbf3988795f5b37da@o939208.ingest.sentry.io/5889114'
      : '',
  environment: process.env.FIREBASE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  tracesSampleRate: 1.0,
});

render(<Application />, rootElement);
