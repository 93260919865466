import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '@/common/Button';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '@/common/ui';

const Content = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorRoot = styled.div`
  text-align: center;
  max-width: 700px;
`;

const CloseButton = styled(Button)`
  margin-top: 30px;
`;

const ErrorHeader = styled.h1`
  color: rgba(233, 30, 99, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 36px;
  text-align: center;
  line-height: 100px;
`;

const ErrorText = styled.p`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 20px;
  text-align: center;
`;

interface DefaultErrorPageTemplateParams {
  heading: string;
  description: string;
}

const DefaultErrorView = ({ heading, description }: DefaultErrorPageTemplateParams) => {
  const { t } = useTranslation('errors');
  return (
    <Content>
      <ErrorRoot>
        <ErrorHeader>{heading}</ErrorHeader>
        <ErrorText>{description}</ErrorText>
        <CloseButton type="transparent" size="small" onClick={window.close}>
          {t('cancelButton')}
        </CloseButton>
      </ErrorRoot>
    </Content>
  );
};

export default DefaultErrorView;
