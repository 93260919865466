import { createContext } from 'react';
import { User } from 'firebase/auth';

import PrivateProfileViewModel from '@/types/PrivateProfileViewModel';
import PublicProfileViewModel from '@/types/PublicProfileViewModel';

export interface SessionContextType {
  loggedIn: boolean;
  user?: User;
  privateProfile?: PrivateProfileViewModel;
  publicProfile?: PublicProfileViewModel;
}

const SessionContext = createContext<SessionContextType>({
  loggedIn: false,
});

export default SessionContext;
