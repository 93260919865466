import ExperienceMemberViewModel from '../ExperienceMemberViewModel';
import {
  FirestoreExperienceTemplate,
  FirestoreExperienceTemplateTeamSeat,
} from '../template/types';

import { SeatStatus, FirestoreSeat } from './types';

export default class TeamSeatViewModel {
  readonly id: string;

  readonly user?: ExperienceMemberViewModel;

  readonly status: SeatStatus;

  readonly invite: boolean;

  readonly contest?: string;

  readonly leader: boolean;

  readonly stats?: { [key: string]: number };

  constructor(props: {
    id: string;
    user?: ExperienceMemberViewModel;
    status: SeatStatus;
    invite: boolean;
    leader: boolean;
    contest?: string;
    stats?: { [key: string]: number };
  }) {
    this.id = props.id;
    this.status = props.status;
    this.user = props.user;
    this.invite = props.invite;
    this.leader = props.leader;
    this.contest = props.contest;
    this.stats = props.stats;
  }

  get isFree(): boolean {
    return this.status === SeatStatus.Free;
  }

  get isReserved(): boolean {
    return this.status === SeatStatus.Reserved;
  }

  get isTaken(): boolean {
    return this.status === SeatStatus.Taken;
  }

  get isInvitable(): boolean {
    return this.invite;
  }

  get isContestable(): boolean {
    return Boolean(this.contest);
  }

  get isLeaderReady(): boolean {
    return this.leader;
  }

  static fromFirestore(
    data: FirestoreSeat,
    templateSeat: FirestoreExperienceTemplateTeamSeat,
    entities: {
      members: Record<string, ExperienceMemberViewModel>;
      playerStats: Partial<Record<string, { [key: string]: number }>>;
    },
  ): TeamSeatViewModel {
    return new TeamSeatViewModel({
      id: data.id,
      status: data.status,
      user: data.member ? entities.members[data.member] : undefined,
      invite: Boolean(templateSeat.invite),
      leader: Boolean(templateSeat.leader),
      contest: templateSeat.contest,
      stats: data.member ? entities.playerStats[data.member] : undefined,
    });
  }
}
