import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import Filter from '../common/Filter';
import { ServiceWorkerContext } from '../serviceWorker/ServiceWorkerContext';
import { Col, Grid } from '@/components';

import {
  BREAKPOINT_MEDIUM,
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_REGULAR,
} from '@/common/ui';

import AmpliWrapper from '@/components/AmpliWrapper';
import SocialNetworks from '@/components/SocialNetworks';
import ampli from '@/services/ampli';

const Root = styled.footer`
  background: linear-gradient(180deg, rgba(11, 16, 34, 0) 0%, #0b1022 100%);
`;

const Column = styled.div`
  flex: 1;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled(Grid.Row)`
  padding: 20px 0;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    flex-direction: column;
  }
`;

const Row2Column2 = styled(Column)`
  align-items: center;
  display: flex;
  flex: initial;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin: 20px 0;
  }
`;

const Row2Column3 = styled(Column)`
  align-items: center;
  display: flex;
  flex: initial;
`;

const Version = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 15px;
  line-height: 20px;
  color: rgba(10, 16, 35, 0.8);
  border-radius: 11px;
  background: rgba(211, 206, 239, 0.35);
  height: 22px;
  margin-left: 10px;
  padding: 0 10px;
`;

const Row2Links = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    flex-direction: column;
  }
`;

const Row2LinkWrapper = styled.div`
  align-items: center;
  display: flex;

  &:not(:first-child)::before {
    color: rgba(211, 206, 239, 0.2);
    content: '•';
    font-family: ${FONT_FAMILY_ROBOTO};
    font-size: 14px;
    font-weight: ${FONT_WEIGHT_REGULAR};
    padding: 0 15px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    &:not(:first-child) {
      margin-top: 5px;
    }

    &::before {
      display: none;
    }
  }
`;

const Row2Link = styled.a`
  color: rgba(211, 206, 239, 0.65);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  text-align: center;
  text-decoration: none;

  &:focus:not(:focus-visible),
  &:hover {
    color: rgba(211, 206, 239, 0.85);
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1400px;
`;

const links = [
  {
    labelKey: 'footer.streamers',
    urlKey: 'footer.streamersUrl',
  },
  {
    labelKey: 'footer.brands',
    urlKey: 'footer.brandsUrl',
  },
  {
    labelKey: 'footer.about',
    urlKey: 'footer.aboutUrl',
  },
  {
    labelKey: 'footer.careers',
    urlKey: 'footer.careersUrl',
  },
  {
    labelKey: 'footer.legal',
    urlKey: 'footer.legalUrl',
  },
  {
    labelKey: 'footer.faq',
    urlKey: 'footer.faqUrl',
  },
  {
    labelKey: 'footer.blog',
    urlKey: 'footer.blogUrl',
  },
];

const Footer = () => {
  const { i18n, t } = useTranslation('footer');
  const { version } = useContext(ServiceWorkerContext);

  return (
    <Root>
      <Container>
        <Row justify="space-between" align="center" wrap>
          <Col>
            <LogoWrapper>
              <Version>v{version}</Version>
            </LogoWrapper>
          </Col>
          <Col>
            <Row2Column2>
              <Row2Links>
                {links.map(({ labelKey, urlKey }) => (
                  <AmpliWrapper key={labelKey} event="click_links_footer">
                    <Row2LinkWrapper>
                      <Row2Link href={t(urlKey)} target="_blank">
                        {t(labelKey)}
                      </Row2Link>
                    </Row2LinkWrapper>
                  </AmpliWrapper>
                ))}
              </Row2Links>
            </Row2Column2>
          </Col>
          <Col>
            <SocialNetworks />
          </Col>
        </Row>
      </Container>
    </Root>
  );
};

export default Footer;
