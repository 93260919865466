import { useEffect } from 'react';

const useEscape = (cb: () => void) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' || event.keyCode === 27) {
        cb();
      }
    };

    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, [cb]);
};

export default useEscape;
