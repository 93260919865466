enum CounterStrikeServerLocation {
  Amsterdam = 'amsterdam',
  Barcelona = 'barcelona',
  Bristol = 'bristol',
  Chicago = 'chicago',
  Dallas = 'dallas',
  Dusseldorf = 'dusseldorf',
  Istanbul = 'istanbul',
  LosAngeles = 'los_angeles',
  Moscow = 'moscow',
  NewYorkCity = 'new_york_city',
  Portland = 'portland',
  Singapore = 'singapore',
  Stockholm = 'stockholm',
  Strasbourg = 'strasbourg',
  Sydney = 'sydney',
  Warsaw = 'warsaw',
  SaoPaulo = 'sao_paulo',
}

export default CounterStrikeServerLocation;
