enum KickFromPartyErrorCode {
  PartyNotFound = 7278950,
  LeaderNotFound = 7278951,
  InvalidPartyStatus = 7278952,
  ReadyCheckNotFound = 7278953,
  FanNotFound = 7278954,
  ParticipantHasntFailedReadyCheck = 7278955,
}

export default KickFromPartyErrorCode;
