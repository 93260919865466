import React from 'react';
import styled from 'styled-components';

import { RoundCrop } from './RoundCrop';

type OnlineBadgeStatus = 'online' | 'inactive' | 'offline';

const StatusToColor = {
  online: '#1DB553',
  inactive: '#FBC02D',
  offline: '#7B8794',
};

interface BadgeProps {
  status?: OnlineBadgeStatus;
  children: React.ReactNode;
}

const Status = styled.rect<{ $status: OnlineBadgeStatus }>`
  fill: ${({ $status }) => StatusToColor[$status]};
  transform: scale(0.1852);
  transform-origin: calc(100% * 0.7955) calc(100% * 0.7955);
`;

export const OnlineBadge = ({ status = 'offline', children, ...props }: BadgeProps) => (
  <RoundCrop
    insertItem={
      <Status $status={status} width="100%" height="100%" x="0" y="0" rx="50%" ry="50%" />
    }
    {...props}
  >
    {children}
  </RoundCrop>
);
