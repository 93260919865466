enum CreateWolfPackChallengeCounterStrikeErrorCodes {
  InvalidParticipationMode = 100,
  InvalidServerLocation = 101,
  InvalidGameMode = 102,
  InvalidGameMap = 103,
  InvalidDate = 104,
  InvalidMinPrice = 105,
  InvalidMinStep = 106,
  InvalidPricePerSlot = 107,
  InvalidPricePerEntry = 108,
  InvalidGoal = 109,
  InvalidWinnerCoefficient = 110,
  InvalidRivalId = 111,
  InvalidMaxRounds = 112,
  ChallengerNotFound = 113,
  ChallengerIsNotAProUser = 114,
  ChallengerSteamRequired = 115,
  RivalNotFound = 116,
  LaunchDateTooEarly = 117,
  InvalidWolfPackChallengeRequestVisibility = 118,
}

export default CreateWolfPackChallengeCounterStrikeErrorCodes;
