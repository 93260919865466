import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from '../styleConstants';

const MessageWrapper = styled.p`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  margin-top: 4px;
  margin-bottom: 0;
  max-width: 500px;
  line-height: 22px;
  position: relative;
  color: white;
  text-align: left;
  opacity: 0.8;
  font-size: 16px;
`;

const User = styled.span`
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

interface ChatUserProps {
  user: React.ReactNode;
  children: any;
}

export const ChatMessage = ({ user, children, ...props }: ChatUserProps) => (
  <MessageWrapper {...props}>
    <User>{user}</User>
    &nbsp;&nbsp;
    {children}
  </MessageWrapper>
);
