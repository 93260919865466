import React from 'react';
import styled from 'styled-components';

import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from './ui';

export interface FormErrorType {
  heading: string;
  description: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h6`
  color: #f44336;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 16px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: 0.01em;
  line-height: 24px;
`;

const Paragraph = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin: 0;
`;

interface FormErrorProps {
  className?: string;
  error: FormErrorType;
}

const FormError = ({ className, error: { heading, description } }: FormErrorProps) => (
  <Container className={className}>
    <Heading>{heading}</Heading>
    <Paragraph>{description}</Paragraph>
  </Container>
);

export default FormError;
