import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &:not(:first-of-type) {
    margin-top: 15px;
  }
`;

type Layout = 'column' | 'row';

interface LayoutProps {
  $layout: Layout;
}

const CONTEXT_FLEX_DIRECTION = {
  column: 'column-reverse',
  row: 'row',
};

const CONTEXT_MARGIN_TOP = {
  column: '0',
  row: '5px',
};

const Content = styled.div<LayoutProps>`
  display: flex;
  flex-direction: ${({ $layout }) => CONTEXT_FLEX_DIRECTION[$layout]};
  margin-top: ${({ $layout }) => CONTEXT_MARGIN_TOP[$layout]};
`;

const LEFT_COLUMN_MARGIN_TOP = {
  column: '5px',
  row: '0',
};

const LeftColumn = styled.div<LayoutProps>`
  flex: 2;
  margin-top: ${({ $layout }) => LEFT_COLUMN_MARGIN_TOP[$layout]};
`;

const RIGHT_COLUMN_MARGIN_LEFT = {
  column: '0',
  row: '20px',
};

const RightColumn = styled.div<LayoutProps>`
  flex: 1;
  margin-left: ${({ $layout }) => RIGHT_COLUMN_MARGIN_LEFT[$layout]};
`;

interface FieldProps {
  className?: string;
  layout?: Layout;
  label?: ReactNode;
  hint?: ReactNode;
  children: ReactNode;
}

const Field = ({ className, layout = 'row', label, hint, children }: FieldProps) => (
  <Container className={className}>
    {label}
    <Content $layout={layout}>
      <LeftColumn $layout={layout}>{children}</LeftColumn>
      {hint && <RightColumn $layout={layout}>{hint}</RightColumn>}
    </Content>
  </Container>
);

export default Field;
