import { doc } from 'firebase/firestore';
import { useMemo } from 'react';

import CampaignDataConverter from '@/pages/Charity/temp/model/CampaignDataConverter';
import { Database } from '@/common/types';
import { firestore } from '@/common/firebaseClient';
import useFirestoreDoc from '@/common/useFirestoreDocSnapshot';

const useCampaign = (id: string) => {
  const ref = useMemo(() => doc(firestore, Database.Campaigns, id).withConverter(CampaignDataConverter), [id]);
  const { data, loadingState, error } = useFirestoreDoc(ref);

  return {
    campaign: data,
    loadingState,
    error,
  };
};

export default useCampaign;
