import isEqual from 'fast-deep-equal/es6';
import memoize from 'memoize-one';
import styled from 'styled-components';
import { onAuthStateChanged, User } from 'firebase/auth';
import React, { PureComponent, ReactNode } from 'react';

import { doc, DocumentSnapshot, onSnapshot, Timestamp, Unsubscribe, updateDoc } from 'firebase/firestore';

import ampli from '@/services/ampli';
import preparePublicProfileViewModel from './preparePublicProfileViewModel';
import ThreeDots from './ThreeDots';
import userTracking from './userTracking';
import { auth, firestore } from './firebaseClient';
import { Database, PrivateProfile, PublicProfile } from './types';
import SessionContext, { SessionContextType } from './SessionContext';

const PreAuthContainer = styled.div`
  align-items: center;
  background: #1c163b;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

enum Status {
  Initial,
  Logout,
  Login,
  Loading,
}

interface Props {
  children: ReactNode;
}

interface State {
  status: Status;
  user?: User;
  privateProfile?: PrivateProfile;
  publicProfile?: PublicProfile;
}

export default class SessionContextProvider extends PureComponent<Props, State> {
  private static readonly USER_PRESENCE_UPDATE_MINUTES = 1;

  private unsubscribeAuthStateChanged?: Unsubscribe;

  private unsubscribePrivateProfileChange?: Unsubscribe;

  private unsubscribePublicProfileChange?: Unsubscribe;

  private userPresenceTimer?: number;

  private memoizedContextValue: SessionContextType;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: Status.Initial,
    };
    this.memoizedContextValue = {
      loggedIn: false,
    };
  }

  componentDidMount() {
    this.unsubscribeAuthStateChanged = onAuthStateChanged(auth, this.onAuthStateChanged);
    window.addEventListener('focus', this.trackUserPresence);
    window.addEventListener('blur', this.stopPresenceTracking);
  }

  componentDidUpdate(_: any, prevState: State) {
    const { status, privateProfile, publicProfile, user } = this.state;

    if (prevState.status !== Status.Loading && status === Status.Loading) {
      this.unsubscribePrivateProfileChange = onSnapshot(
        doc(firestore, Database.PrivateProfiles, user!.uid),
        this.onPrivateProfileChange
      );
      this.unsubscribePublicProfileChange = onSnapshot(
        doc(firestore, Database.PublicProfiles, user!.uid),
        this.onPublicProfileChange
      );
      return;
    }

    if (status === Status.Loading && privateProfile && publicProfile && user) {
      this.setState({ status: Status.Login });
      userTracking.login(privateProfile!);
      this.trackUserPresence();
      return;
    }
    if (prevState.status === Status.Login && status === Status.Logout) {
      userTracking.logout();
      this.stopPresenceTracking();
      this.unsubscribePrivateProfileChange!();
      this.unsubscribePublicProfileChange!();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeAuthStateChanged) {
      this.unsubscribeAuthStateChanged();
    }
    if (this.unsubscribePrivateProfileChange) {
      this.unsubscribePrivateProfileChange();
    }
    if (this.unsubscribePublicProfileChange) {
      this.unsubscribePublicProfileChange();
    }
    window.removeEventListener('focus', this.trackUserPresence);
    window.removeEventListener('blur', this.stopPresenceTracking);
  }

  private onAuthStateChanged = (user: User | null) => {
    this.setState({
      status: user !== null ? Status.Loading : Status.Logout,
      user: user ?? undefined,
      privateProfile: undefined,
      publicProfile: undefined,
    });
  };

  private onPrivateProfileChange = (document: DocumentSnapshot) => {
    const privateProfile = document.data() as PrivateProfile | undefined;
    if (!privateProfile) {
      return this.setState({
        status: Status.Logout,
        user: undefined,
        privateProfile: undefined,
        publicProfile: undefined,
      });
    }
    if (Date.now() - privateProfile.createdAt.toMillis() < 15 * 1000) {
      ampli.log('signUp/success');
      userTracking.event('signUp/success');
    }
    this.setState({ privateProfile });
  };

  private onPublicProfileChange = (document: DocumentSnapshot) => {
    const publicProfile = document.data() as PublicProfile | undefined;
    if (!publicProfile) {
      return this.setState({
        status: Status.Logout,
        user: undefined,
        privateProfile: undefined,
        publicProfile: undefined,
      });
    }
    this.setState({ publicProfile });
  };

  private stopPresenceTracking = () => {
    if (typeof this.userPresenceTimer === 'undefined') {
      return;
    }
    clearInterval(this.userPresenceTimer);
  };

  private trackUserPresence = async () => {
    const { status, user } = this.state;
    if (status !== Status.Login) {
      return;
    }
    const publicProfile: Partial<PublicProfile> = {
      lastOnlineAt: Timestamp.now(),
    };
    await updateDoc(doc(firestore, Database.PublicProfiles, user!.uid), publicProfile);
    this.userPresenceTimer = window.setTimeout(
      this.trackUserPresence,
      1000 * 60 * SessionContextProvider.USER_PRESENCE_UPDATE_MINUTES
    );
  };

  private preparePublicProfile = memoize((publicProfile) =>
    publicProfile ? preparePublicProfileViewModel(publicProfile) : undefined
  );

  private getContextValue = () => {
    const { user, privateProfile, publicProfile, status } = this.state;
    const { memoizedContextValue } = this;

    const publicProfileViewModel = this.preparePublicProfile(publicProfile);
    const loggedIn = status === Status.Login;

    if (
      !(
        isEqual(memoizedContextValue.user, user) &&
        isEqual(memoizedContextValue.privateProfile, privateProfile) &&
        isEqual(memoizedContextValue.publicProfile, publicProfileViewModel) &&
        isEqual(memoizedContextValue.loggedIn, loggedIn)
      )
    ) {
      this.memoizedContextValue = {
        user,
        privateProfile,
        publicProfile: publicProfileViewModel,
        loggedIn,
      };
    }

    return this.memoizedContextValue;
  };

  render() {
    const { children } = this.props;
    const { status } = this.state;
    if (status === Status.Initial || status === Status.Loading) {
      return (
        <PreAuthContainer>
          <ThreeDots />
        </PreAuthContainer>
      );
    }
    return <SessionContext.Provider value={this.getContextValue()}>{children}</SessionContext.Provider>;
  }
}
