import { FsPackage, PaymentMethod } from '@/common/types';
import React, { ComponentType, useState } from 'react';

import WalletPackagesStep from './WalletPackagesStep';
import WalletPaymentMethodStep from './WalletPaymentMethodStep';
import WalletPaymentStep from './WalletPaymentStep';
import WalletStep from './WalletStep';
import WalletStepProps from './WalletStepProps';
import WalletWithdrawalStep from './WalletWithdrawalStep';

const Step: Record<WalletStep, ComponentType<WalletStepProps>> = {
  [WalletStep.Withdraw]: WalletWithdrawalStep,
  [WalletStep.Packages]: WalletPackagesStep,
  [WalletStep.PaymentMethod]: WalletPaymentMethodStep,
  [WalletStep.Payment]: WalletPaymentStep,
};

const Wallet = () => {
  const [step, setStep] = useState(WalletStep.Packages);
  const [pack, setPack] = useState<FsPackage>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentPopup, setPaymentPopup] = useState<Window | null>();

  const MyStep = Step[step];
  return (
    <MyStep
      step={step}
      setStep={setStep}
      pack={pack}
      setPack={setPack}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      paymentPopup={paymentPopup}
      setPaymentPopup={setPaymentPopup}
    />
  );
};

export default Wallet;
