import en from 'date-fns/locale/en-US';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { universalLanguageDetect } from '@unly/universal-language-detector';

import {
  addDays,
  addMinutes,
  addMonths,
  format as dateFormat,
  formatDistance,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  fromUnixTime,
  isAfter,
  isDate,
  isToday,
  isTomorrow,
} from 'date-fns';

import { Currency, Locale as ProjectLocale } from '@/common/types';

import PublicProfileViewModel from '@/types/PublicProfileViewModel';

import * as resources from './locales';

const formatCurrency = (value: number, lng: any, options: any) =>
  (value / 100).toLocaleString(lng, {
    style: 'currency',
    ...options,
    currency: options.currency || Currency.USD,
  });

const formatFs = (value: any) => `₪ ${value}`;

const formatProfile = (value: PublicProfileViewModel) => {
  if (typeof value === 'undefined' || value === null) {
    return '•••••';
  }
  if (value.title) {
    return `${value.username}`;
  }
  return `${value.username}#${value.tag}`;
};

const formatEnumeration = (enumeration: string[]) => {
  if (!enumeration) {
    return '';
  }
  if (enumeration.length === 1) {
    return enumeration[0];
  }
  const lastItem = enumeration.pop();
  return `${enumeration.join(i18n.t('formatting:enumeration.joinSeporator'))}${i18n.t(
    'formatting:enumeration.lastItemPrefix'
  )} ${lastItem}`;
};

const formatDateTime = (value: Date, lng: any, i18nKeyPattern: string) => {
  let date: Date;
  if (isDate(value)) {
    date = value as Date;
  } else if (Number.isInteger(value)) {
    date = fromUnixTime(Number(value));
  } else {
    return 'undefined';
  }
  const formatPattern = isAfter(date, addMonths(new Date(), -6))
    ? i18n.t(`${i18nKeyPattern}.future`)
    : i18n.t(`${i18nKeyPattern}.past`);

  const langDateFnsLocale: Partial<Record<string, Locale>> = { en };
  const options = { locale: langDateFnsLocale[lng] };
  return dateFormat(date, formatPattern, options);
};

const formatSmartDate = (value: Date | number, lng: any) => {
  let date: Date;
  if (isDate(value)) {
    date = value as Date;
  } else if (Number.isInteger(value)) {
    date = fromUnixTime(Number(value));
  } else {
    return 'undefined';
  }
  const langDateFnsLocale: Partial<Record<string, Locale>> = { en };
  const now = new Date();
  const options = { locale: langDateFnsLocale[lng] };
  let formattedDate = 'none';

  if (date < addDays(now, -7)) {
    formattedDate = dateFormat(date, i18n.t('dates:smart.past'), options);
  } else if (date < addMinutes(now, -1)) {
    formattedDate = formatDistance(date, now, { addSuffix: true, ...options });
  } else if (date < now) {
    formattedDate = i18n.t('dates:smart.isAboutToStart');
  } else if (date < addMinutes(now, 60)) {
    formattedDate = formatDistance(date, now, { addSuffix: true, ...options });
  } else if (isToday(date)) {
    formattedDate = dateFormat(date, i18n.t('dates:smart.today'), options);
  } else if (isTomorrow(date)) {
    formattedDate = dateFormat(date, i18n.t('dates:smart.tomorrow'), options);
  } else if (date < addDays(now, 6)) {
    formattedDate = dateFormat(date, i18n.t('dates:smart.lessThenWeek'), options);
  } else if (date > addDays(now, 6)) {
    formattedDate = dateFormat(date, i18n.t('dates:smart.moreThenWeek'), options);
  }
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

const formatTimeDistance = (value: Date, lng: any, options?: any) => {
  const formatterOptions = { ...options, ...(options.formatOptions ?? {}) };
  const formatter = formatterOptions.strict ? formatDistanceToNowStrict : formatDistanceToNow;
  const langDateFnsLocale: Record<string, Locale | undefined> = {
    en,
  };

  // @ts-ignore
  return formatter(value, {
    ...formatterOptions,
    locale: langDateFnsLocale[lng],
  });
};

const formatTime = (value: Date, lng: any) => {
  const langDateFnsLocale: Record<string, Locale | undefined> = {
    en,
  };

  return dateFormat(value, 'p', { locale: langDateFnsLocale[lng] });
};

const SUPPORTED_LANGUAGES = Object.values(ProjectLocale);

const lang = universalLanguageDetect({
  fallbackLanguage: ProjectLocale.English,
  supportedLanguages: SUPPORTED_LANGUAGES,
});

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: ProjectLocale.English,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  lng: lang,
  nonExplicitSupportedLngs: true,
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong'],
    transSupportBasicHtmlNodes: true,
  },
  resources,
  supportedLngs: SUPPORTED_LANGUAGES,
});

i18n.services.formatter?.add('currency', (value, lng, options) => formatCurrency(value, lng, options));
i18n.services.formatter?.add('usd', (value, lng, options) =>
  formatCurrency(value, lng, { ...options, currency: Currency.USD })
);
i18n.services.formatter?.add('fs', (value, lng, options) => formatFs(value));
i18n.services.formatter?.add('profile', (value, lng, options) => formatProfile(value));
i18n.services.formatter?.add('smart', (value, lng, options) => formatSmartDate(value, lng));
i18n.services.formatter?.add('short', (value, lng, options) => formatDateTime(value, lng, 'dates:short'));
i18n.services.formatter?.add('regular', (value, lng, options) => formatDateTime(value, lng, 'dates:regular'));
i18n.services.formatter?.add('full', (value, lng, options) => formatDateTime(value, lng, 'dates:full'));
i18n.services.formatter?.add('timedistance', (value, lng, options) => formatTimeDistance(value, lng, options));
i18n.services.formatter?.add('enumeration', (value, lng, options) => formatEnumeration(value));
i18n.services.formatter?.add('time', (value, lng, options) => formatTime(value, lng));

export default i18n;
