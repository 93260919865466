import { rgba } from 'polished';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SessionContext from '../../../SessionContext';

import {
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_REGULAR,
  Avatar,
  pulse,
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_WEIGHT_EXTRA_BOLD,
} from '../../../ui';
import NotificationViewModel from '../../model/NotificationViewModel';

const NotificationRoot = styled.div`
  align-items: stretch;
  display: flex;
`;

const NotificationTitle = styled.div`
  color: inherit;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 16px;
  text-align: left;
`;

const ExperienceTitle = styled.strong`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 16px;
  line-height: 125%;
`;

const AvatarWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  animation: ${pulse('#FBC02D')} 2s linear infinite;
  &::before {
    background: ${rgba('#FBC02D', 1)};
  }
`;

const CreatorAvatar = styled(Avatar)``;

const ContentWrapper = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Time = styled.div`
  color: currentColor;
  opacity: 0.5;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  text-align: left;
  margin-top: 5px;
`;

interface CreatedProps {
  notification: NotificationViewModel;
}

const Created = ({ notification }: CreatedProps) => {
  const { privateProfile } = useContext(SessionContext);
  const { t } = useTranslation('notifications');
  const experience = notification.experience!;
  const characters = Array.from(new Set([...experience.leaders, ...experience.owners]));
  const creator = privateProfile?.follows.reduce(
    (res, follow) => characters.find((character) => character.id === follow.id) || res,
    experience.owners[0],
  );

  return (
    <NotificationRoot>
      <AvatarWrapper>
        <CreatorAvatar src={creator?.avatarURL} size="40" />
      </AvatarWrapper>
      <ContentWrapper>
        <NotificationTitle>
          <Trans
            t={t}
            i18nKey={'experience.created'}
            components={{ truncate: <span />, strong: <ExperienceTitle /> }}
            values={{
              creator: creator?.username,
              title: `${experience.template.name}.title`,
              experience: experience.toPlain(),
            }}
          />
        </NotificationTitle>
        <Time>
          {t('createdAt', {
            createdAt: notification.createdAt,
            formatOptions: {
              addSuffix: true,
            },
          })}
        </Time>
      </ContentWrapper>
    </NotificationRoot>
  );
};

export default Created;
