import { ScrollMixin } from './ui';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';

import BrutalTypeExtraBoldEot from './brutal/eot/BrutalGG-ExtraBold.eot';
import BrutalTypeMediumEot from './brutal/eot/BrutalGG-Medium.eot';
import BrutalTypeExtraBoldTtf from './brutal/ttf/BrutalGG-ExtraBold.ttf';
import BrutalTypeMediumTtf from './brutal/ttf/BrutalGG-Medium.ttf';
import BrutalTypeExtraBoldWoff from './brutal/woff/BrutalGG-ExtraBold.woff';
import BrutalTypeMediumWoff from './brutal/woff/BrutalGG-Medium.woff';
import BrutalTypeExtraBoldWoff2 from './brutal/woff2/BrutalGG-ExtraBold.woff2';
import BrutalTypeMediumWoff2 from './brutal/woff2/BrutalGG-Medium.woff2';
import {
  FONT_FAMILY_ROBOTO,
  FONT_NAME_BRUTAL_TYPE,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
} from './ui';

const Style = createGlobalStyle`
  @font-face {
    font-family: ${FONT_NAME_BRUTAL_TYPE};
    font-style: normal;
    font-weight: ${FONT_WEIGHT_MEDIUM};
    font-display: block;
    src: url('${BrutalTypeMediumEot}');
    src: url('${BrutalTypeMediumEot}?#iefix') format('embedded-opentype'),
      url('${BrutalTypeMediumWoff2}') format('woff2'),
      url('${BrutalTypeMediumWoff}') format('woff'),
      url('${BrutalTypeMediumTtf}') format('truetype');
  }

  @font-face {
    font-family: ${FONT_NAME_BRUTAL_TYPE};
    font-style: normal;
    font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
    font-display: block;
    src: url('${BrutalTypeExtraBoldEot}');
    src: url('${BrutalTypeExtraBoldEot}?#iefix') format('embedded-opentype'),
      url('${BrutalTypeExtraBoldWoff2}') format('woff2'),
      url('${BrutalTypeExtraBoldWoff}') format('woff'),
      url('${BrutalTypeExtraBoldTtf}') format('truetype');
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    min-width: 0;
    scroll-behavior: smooth;
  }

  *:focus {
    outline: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  body {
    font-family: Roboto, ${FONT_FAMILY_ROBOTO}, sans-serif;
    background: #0f1528;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 0;
    }

    * {
      ${ScrollMixin}
    }
  }
`;

const GlobalStyle = () => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Normalize />
    <Style />
  </>
);

export default GlobalStyle;
