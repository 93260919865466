import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const SkeletonAnimation = keyframes`
  from { background-position:100% 0; }
  to { background-position:0 0; }
`;

const SkeletonEl = styled.div<{ $loading?: boolean }>`
  background: linear-gradient(90deg, #15192e 25%, #202237 50%, #15192e 75%);
  background-size: 400% 100%;
  border-radius: 4px;
  width: 100%;
  height: 16px;
  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${SkeletonAnimation} 1.4s ease infinite;
    `}
`;

interface Props {
  loading?: boolean;
}

const Skeleton: React.FC<Props> = (props) => {
  const { loading } = props;
  return <SkeletonEl $loading={loading} />;
};

export default Skeleton;
