import React from 'react';

export interface ModalLayerContextType {
  active: number;
  register(): number;
  unregister(): void;
}

const ModalLayerContext = React.createContext<ModalLayerContextType>({} as ModalLayerContextType);

export default ModalLayerContext;
