import { createContext } from 'react';

type NetworkStatusContextType = {
  online: boolean;
  offline: boolean;
};

const NetworkStatusContext = createContext<NetworkStatusContextType>({
  online: true,
  offline: false,
});

export default NetworkStatusContext;
