import { createElement } from 'react';
import UserExperienceParticipationComponent from './UserExperienceParticipationComponent';
import ExperienceViewModel from '../../pages/Experience/model/ExperienceViewModel';

export interface UserParticipationComponentProps {
  experience: ExperienceViewModel;
  onClick?: () => void;
}

const UserParticipationComponent = ({ ...props }: UserParticipationComponentProps) =>
  createElement(UserExperienceParticipationComponent, props);

export default UserParticipationComponent;
