import { FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';
import { SerializedUserTelemetry, UserTelemetry } from './types';

const UserTelemetryDataConverter: FirestoreDataConverter<UserTelemetry> = {
  fromFirestore(snapshot: QueryDocumentSnapshot<SerializedUserTelemetry>): UserTelemetry {
    const data = snapshot.data();

    return {
      user: data.user,
      last_finished_experience: data.last_finished_experience
        ? {
            experience: data.last_finished_experience.experience,
            timestamp: data.last_finished_experience.timestamp.toDate(),
          }
        : undefined,
      last_hosted_experience: data.last_hosted_experience
        ? {
            experience: data.last_hosted_experience.experience,
            timestamp: data.last_hosted_experience.timestamp.toDate(),
          }
        : undefined,
      sign_up: data.sign_up.toDate(),
    };
  },

  toFirestore(): {} {
    return {};
  },
};

export default UserTelemetryDataConverter;
