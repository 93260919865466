import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from '@/common/ui';

export interface Props {
  color?: string;
}

const Typography = styled.div<Props>`
  color: ${({ color = 'rgba(255, 255, 255, 0.7)' }) => color};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-family: ${FONT_FAMILY_ROBOTO};
  letter-spacing: -0.02em;
  line-height: 1.5;
`;

export default Typography;
