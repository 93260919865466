import { Database, UserParticipationStatus } from '../types';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';

import { firestore } from '../firebaseClient';
import useFirestoreCol from '../useFirestoreCollectionSnapshot';

import ExperienceDataConverter from '../../pages/Experience/model/ExperienceDataConverter';
import { FirestoreStatusGroup } from '../../pages/Experience/model/types';

interface QueryParams {
  finished?: boolean;
  privateGroup?: boolean;
  limit?: number;
}

const useUserParticipations = (userId: string = '', params: QueryParams = {}) => {
  const ref = useMemo(() => {
    let myQuery = query(
      collection(firestore, Database.Experiences2),
      where(params.privateGroup ? 'private_user_ids' : 'public_user_ids', 'array-contains', userId),
    ).withConverter(ExperienceDataConverter);

    if (params.finished) {
      myQuery = query(
        myQuery,
        where(
          params.privateGroup ? 'private_status_group' : 'public_status_group',
          '==',
          FirestoreStatusGroup.Past,
        ),
        orderBy('finished_at', 'desc'),
      );
    } else {
      myQuery = query(
        myQuery,
        where(
          params.privateGroup ? 'private_status_group' : 'public_status_group',
          '==',
          FirestoreStatusGroup.Upcoming,
        ),
        orderBy('start_at', 'asc'),
      );
    }

    return myQuery;
  }, [userId, params.finished, params.limit, params.privateGroup]);

  const { data, loadingState, error } = useFirestoreCol(ref);

  return {
    participations: data,
    loadingState,
    error,
  };
};

export default useUserParticipations;
