import React from 'react';
import styled, { keyframes } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from './styleConstants';

const ellipsis = keyframes`
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    100% {
      content: '...';
    }
  `;

const LoaderWrapper = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 30px;
  line-height: 46px;
  color: #fff;
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.5px;
  text-align: center;
`;

const DrawingLoader = styled.div`
  &::after {
    content: '...';
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} linear 1000ms infinite;
    width: 26px;
    text-align: left;
  }
`;

interface TextLoaderProps {
  children: React.ReactNode;
}

export const TextLoader = ({ children, ...props }: TextLoaderProps) => (
  <LoaderWrapper {...props}>
    <DrawingLoader>{children}</DrawingLoader>
  </LoaderWrapper>
);
