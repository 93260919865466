import React from 'react';
import styled from 'styled-components';

import { FrameProps } from '../types';

import weeklyLeader2Png from './weekly-leader-2.png';

const FrameContainer = styled.div<{ $size: number }>`
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  position: absolute;
  background: url(${weeklyLeader2Png});
  background-size: contain;
  transform: scale(1.5);
`;

const SilverWeeklyLeaderFrame = ({ size }: FrameProps) => <FrameContainer $size={size} />;

export default SilverWeeklyLeaderFrame;
