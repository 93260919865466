import React from 'react';
import styled, { keyframes } from 'styled-components';

type SpinnerSize = 'large' | 'small';
type SpinnerType = 'coloredOpaque' | 'opaque' | 'transparent' | 'text' | 'green';

const SpinnerAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const sizes = {
  large: '16px',
  small: '12px',
};

const colors: Record<SpinnerType, string> = {
  coloredOpaque: '103, 58, 183',
  green: '255, 255, 255',
  opaque: '103, 58, 183',
  text: '255, 255, 255',
  transparent: '211, 206, 239',
};

const SpinnerEl = styled.div<{ $size: SpinnerSize; $type: SpinnerType }>`
  animation: ${SpinnerAnimation} 1s ease infinite;
  border-bottom: 2px solid rgba(${({ $type }) => colors[$type]}, 0.6);
  border-left: 2px solid rgba(${({ $type }) => colors[$type]}, 0.6);
  border-right: 2px solid rgba(${({ $type }) => colors[$type]}, 0.6);
  border-top: 2px solid rgba(${({ $type }) => colors[$type]}, 1);
  border-radius: 50%;
  display: inline-flex;
  height: ${({ $size }) => sizes[$size]};
  width: ${({ $size }) => sizes[$size]};
`;

interface SpinnerProps {
  size?: SpinnerSize;
  type?: SpinnerType;
}

const Spinner: React.FC<SpinnerProps> = (props) => {
  const { size = 'small', type = 'transparent', ...rest } = props;
  return <SpinnerEl $size={size} $type={type} {...rest} />;
};

export default Spinner;
