import { Link, LinkGetProps, LinkProps } from '@reach/router';
import React from 'react';

interface NavLinkProps {
  exact?: boolean;
}

type LinkPropsType = React.PropsWithoutRef<LinkProps<any>> & React.RefAttributes<HTMLAnchorElement>;

const NavLink = React.forwardRef(
  (
    { exact = false, ...props }: NavLinkProps & LinkPropsType,
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    const isActive = ({ isCurrent, isPartiallyCurrent }: LinkGetProps) => {
      if (exact) {
        return isCurrent ? { 'data-active': true } : {};
      }
      return isPartiallyCurrent ? { 'data-active': true } : {};
    };

    return <Link ref={ref} getProps={isActive} {...props} />;
  },
);
export default NavLink;
