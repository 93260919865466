import {
  DocumentReference,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  FirestoreError,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import ClientErrorCode from './ClientErrorCode';
import ErrorWithCode from './ErrorWithCode';
import useLoadingState from './useLoadingState';

const useFirestoreDoc = <T = DocumentData>(docRef: DocumentReference<T>) => {
  const [data, setData] = useState<T>();
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();

  const onFirestoreSnapshot = (snapshot: DocumentSnapshot<T>) => {
    if (!snapshot.exists) {
      setLoadFailed();
      return;
    }

    const receivedData = snapshot.data();

    if (!receivedData) {
      setLoadFailed();
      return;
    }

    unstable_batchedUpdates(() => {
      setLoaded();
      setData(receivedData);
    });
  };

  const onFirestoreSnapshotError = (_error: FirestoreError) => {
    unstable_batchedUpdates(() => {
      setLoadFailed();
      setError(new ErrorWithCode(ClientErrorCode.UnknownError));
    });
  };

  useEffect(() => {
    setLoading();

    return onSnapshot(docRef, onFirestoreSnapshot, onFirestoreSnapshotError);
  }, [docRef]);

  return {
    data,
    loadingState,
    error,
  };
};

export default useFirestoreDoc;
