import React from 'react';

import AmpliWrapper from '../AmpliWrapper';
import { Row } from '@/components';

import Icon from './components/Icon';
import Link from './components/Link';

import DiscordSvg from '@/assets/icons/discord.svg';
import FacebookSvg from '@/assets/icons/facebook.svg';
import InstagramSvg from '@/assets/icons/instagram.svg';
import TwitterSvg from '@/assets/icons/twitter.svg';
import YoutubeSvg from '@/assets/icons/youtube.svg';

const links = [
  { icon: FacebookSvg, url: 'https://www.facebook.com/orgzGG' },
  { icon: InstagramSvg, url: 'https://www.instagram.com/orgzGG/' },
  { icon: TwitterSvg, url: 'https://twitter.com/orgzGG' },
  { icon: DiscordSvg, url: 'https://discord.gg/QYenWRf' },
  { icon: YoutubeSvg, url: 'https://www.youtube.com/channel/UC-z_-_dMOx9C1cqg-ItVF6A' },
];

interface Props {
  ampli?: boolean;
}

const SocialNetworks: React.FC<Props> = (props) => {
  const { ampli = true, ...rest } = props;
  return (
    <Row gutter={[8, 0]} {...rest}>
      {links.map(({ icon, url }) => {
        const child = (
          <Link href={url} key={url}>
            <Icon as={icon} />
          </Link>
        );
        return ampli ? (
          <AmpliWrapper key={url} event="click_social_media_footer">
            {child}
          </AmpliWrapper>
        ) : (
          child
        );
      })}
    </Row>
  );
};

export default SocialNetworks;
