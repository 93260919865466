import { tint } from 'polished';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import ampli from '@/services/ampli';
import { Button } from '@/common/ui';
import { FsPackage } from '@/common/types';
import LocaleAndCurrencyContext from '../common/LocaleAndCurrencyContext';
import sendWalletAnalyticsStep from './sendWalletAnalyticsStep';
import useFsPackages from './useFsPackages';
import WalletAnalyticsStep from './WalletAnalyticsStep';
import WalletStep from './WalletStep';
import WalletStepProps from './WalletStepProps';

import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from '@/common/ui';

import ArrowSvg from './arrow-right.svg';
import CardSvg from './card.svg';
import PPSvg from './pp.svg';
import SkinsSvg from './skins.svg';
import WolfpektPng from './wolfpekt.png';

const Container = styled.div`
  padding-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  padding: 0 20px;
`;

const HeadingColumn = styled.div`
  flex: 1;
  padding: 20px 0;
`;

const Heading = styled.h3`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 19px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  line-height: 24px;

  strong {
    color: #fbc02d;
  }
`;

const SubHeading = styled.div`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin-top: 3px;
`;

const WolfpektColumn = styled.div`
  align-items: flex-end;
  display: flex;
  width: 130px;
  margin-right: -20px;
`;

const WolfpektImage = styled.img`
  height: 120px;
`;

const Content = styled.div`
  background: #2b2660;
`;

const PackagesTable = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const PackRow = styled.tr`
  padding: 0 20px;

  &:hover {
    background: rgba(211, 206, 239, 0.05);
  }
`;

const PackCol = styled.td`
  border-bottom: 1px solid #3a3377;
  padding: 15px 10px;
`;

const PackSizeCol = styled(PackCol)`
  text-align: left;
  padding-left: 20px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 19px;
  letter-spacing: 0.01em;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
`;

const PackDiscountCol = styled(PackCol)`
  text-align: right;
`;

const Discount = styled.span<{ $discount: number }>`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 12px;
  line-height: 16px;

  color: ${({ $discount }) => {
    if ($discount <= 10) {
      return 'rgba(211, 206, 239, 0.5)';
    }
    if ($discount < 20) {
      return 'rgba(251, 192, 45, 0.65)';
    }
    return 'rgba(251, 192, 45, 1)';
  }};
`;

const PackPriceCol = styled(PackCol)`
  width: 100px;
  padding-right: 20px;
`;

const PriceButton = styled(Button).attrs({
  type: 'opaque',
})`
  width: 100%;
`;

const Hint = styled.p`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin: 0 20px;
  padding: 10px 0;
`;

const PaymentTypes = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const PaymentItem = styled.div`
  line-height: 30px;
  font-size: 15px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  color: rgba(211, 206, 239, 0.5);
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const PaymentIcon = styled.svg`
  height: 18px;
  margin-right: 8px;
  flex-shrink: 0;
`;

const WithdrawButton = styled.button`
  align-items: center;
  color: #fff;
  background: linear-gradient(90deg, #4c458e 0%, #00bcd4 100%);
  border: none;
  line-height: 30px;
  font-size: 19px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  display: flex;
  padding: 20px;
  width: 100%;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: default;
        `
      : css`
          cursor: pointer;
          opacity: 1;

          &:hover {
            background: linear-gradient(90deg, ${tint(0.1, '#4c458e')} 0%, ${tint(0.1, '#00bcd4')} 100%);
          }
        `};
`;

const Arrow = styled(ArrowSvg)`
  height: 24px;
  margin-left: auto;
`;

const WalletPackagesStep = ({ setPack, setStep }: WalletStepProps) => {
  const { t } = useTranslation('wallet');
  const { currency } = useContext(LocaleAndCurrencyContext);
  const { fsPackages } = useFsPackages(currency);
  const packages: FsPackage[] = [...fsPackages];
  packages.sort((a, b) => a.size - b.size);

  const createPackHandler = (pack: FsPackage) => () => {
    setPack(pack);
    setStep(WalletStep.PaymentMethod);
  };
  useEffect(() => {
    sendWalletAnalyticsStep(WalletAnalyticsStep.Bundles);
  }, []);

  return (
    <Container>
      <Header>
        <HeadingColumn>
          <Heading>
            <Trans t={t} i18nKey="bundlesStep.heading">
              Purchase <strong>F’s</strong> to Pay Respects
            </Trans>
          </Heading>
          <SubHeading>{t('bundlesStep.subHeading')}</SubHeading>
          <PaymentTypes>
            <PaymentItem>
              <PaymentIcon as={CardSvg} />
              {t('bundlesStep.payments.card')}
            </PaymentItem>
            <PaymentItem>
              <PaymentIcon as={SkinsSvg} />
              {t('bundlesStep.payments.skins')}
            </PaymentItem>
            <PaymentItem>
              <PaymentIcon as={PPSvg} />
              {t('bundlesStep.payments.pp')}
            </PaymentItem>
          </PaymentTypes>
        </HeadingColumn>
        <WolfpektColumn>
          <WolfpektImage src={WolfpektPng} alt="Wolfpekt" />
        </WolfpektColumn>
      </Header>
      <Content>
        <PackagesTable>
          <tbody>
            {packages.map((fsPack) => (
              <PackRow key={fsPack.price}>
                <PackSizeCol>{t('bundlesStep.size', { size: fsPack.size })}</PackSizeCol>
                <PackDiscountCol>
                  {!!fsPack.discount && (
                    <Discount $discount={fsPack.discount}>
                      {t('bundlesStep.discount', { discount: fsPack.discount })}
                    </Discount>
                  )}
                </PackDiscountCol>
                <PackPriceCol>
                  <PriceButton onClick={createPackHandler(fsPack)}>
                    {t('bundlesStep.price', { currency, price: fsPack.price })}
                  </PriceButton>
                </PackPriceCol>
              </PackRow>
            ))}
          </tbody>
        </PackagesTable>
        <Hint>{t('bundlesStep.hint', { currency })}</Hint>
        <WithdrawButton onClick={() => setStep(WalletStep.Withdraw)}>
          {t('bundlesStep.withdrawRequest')}
          <Arrow />
        </WithdrawButton>
      </Content>
    </Container>
  );
};

export default WalletPackagesStep;
