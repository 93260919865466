enum ShowmatchInviteCodeErrorCode {
  ShowmatchNotFound = 74270,
  LeaderNotFound = 74271,
  WrongStatus = 74272,
  MaxParticipantsReached = 74273,
  NoEmptySots = 74274,
  UserNotFound = 74275,
  CodeAlreadyUsed = 74276,
  CodeNotFound = 74277,
  AlreadyParticipating = 74278,
  MaxReservedSlotsReached = 74279,
  CantRevoke = 742710,
  FanNotFound = 742711,
  FanNotInvited = 742712,
  WrongFanTeam = 742713,
  NoReservedSlots = 742714,
  ExperienceInterferes = 742715,
}

export default ShowmatchInviteCodeErrorCode;
