import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';

import {
  HandleBattleNetConnectErrorCode,
  HandleDiscordAuthErrorCode,
  HandleDiscordConnectErrorCode,
  HandleSteamAuthErrorCode,
  HandleSteamConnectErrorCode,
  HandleTwitchAuthErrorCode,
  HandleTwitchConnectErrorCode,
} from '@/common/types';

import ErrorPageProps from './ErrorPageProps';

import BattlnetConnectErrorPage from './pages/BattlnetConnectErrorPage';
import DiscordAuthErrorPage from './pages/DiscordAuthErrorPage';
import DiscordConnectErrorPage from './pages/DiscordConnectErrorPage';
import SteamAuthErrorPage from './pages/SteamAuthErrorPage';
import SteamConnectErrorPage from './pages/SteamConnectErrorPage';
import TwitchAuthErrorPage from './pages/TwitchAuthErrorPage';
import TwitchConnectErrorPage from './pages/TwitchConnectErrorPage';
import UnknownErrorPage from './pages/UnknownErrorPage';

interface MasterErrorPageRoutingProps {
  code: number;
}

const ErrorPageByErrorCode: Record<number, ComponentType<ErrorPageProps>> = {
  [HandleBattleNetConnectErrorCode.ExternalAccountAlreadyInUse]: BattlnetConnectErrorPage,
  [HandleBattleNetConnectErrorCode.InvalidState]: BattlnetConnectErrorPage,
  [HandleBattleNetConnectErrorCode.InvalidCode]: BattlnetConnectErrorPage,
  [HandleBattleNetConnectErrorCode.ProfileNotFound]: BattlnetConnectErrorPage,
  [HandleSteamAuthErrorCode.SteamProfileIsNotPublic]: SteamAuthErrorPage,
  [HandleSteamAuthErrorCode.SteamAccountIsVacBanned]: SteamAuthErrorPage,
  [HandleSteamAuthErrorCode.CounterStrikeIsNotPurchased]: SteamAuthErrorPage,
  [HandleSteamAuthErrorCode.NotEnoughTimePlayed]: SteamAuthErrorPage,
  [HandleSteamAuthErrorCode.SteamAccountIsBannedByPlatform]: SteamAuthErrorPage,
  [HandleSteamConnectErrorCode.SteamProfileIsNotPublic]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.SteamAccountIsVacBanned]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.CounterStrikeIsNotPurchased]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.NotEnoughTimePlayed]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.ExternalAccountAlreadyInUse]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.PlayerNotFoundError]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.ProfileNotFound]: SteamConnectErrorPage,
  [HandleSteamConnectErrorCode.SteamAccountIsBannedByPlatform]: SteamConnectErrorPage,
  [HandleDiscordAuthErrorCode.InvalidCode]: DiscordAuthErrorPage,
  [HandleDiscordAuthErrorCode.InvalidState]: DiscordAuthErrorPage,
  [HandleDiscordConnectErrorCode.InvalidCode]: DiscordConnectErrorPage,
  [HandleDiscordConnectErrorCode.InvalidState]: DiscordConnectErrorPage,
  [HandleDiscordConnectErrorCode.ExternalAccountAlreadyInUse]: DiscordConnectErrorPage,
  [HandleDiscordConnectErrorCode.ProfileNotFound]: DiscordConnectErrorPage,
  [HandleTwitchAuthErrorCode.InvalidCode]: TwitchAuthErrorPage,
  [HandleTwitchAuthErrorCode.InvalidState]: TwitchAuthErrorPage,
  [HandleTwitchConnectErrorCode.InvalidCode]: TwitchConnectErrorPage,
  [HandleTwitchConnectErrorCode.InvalidState]: TwitchConnectErrorPage,
  [HandleTwitchConnectErrorCode.ExternalAccountAlreadyInUse]: TwitchConnectErrorPage,
  [HandleTwitchConnectErrorCode.ProfileNotFound]: TwitchConnectErrorPage,
};

const MasterErrorPage = ({ code }: RouteComponentProps<MasterErrorPageRoutingProps>) => {
  let ErrorPage: ComponentType<ErrorPageProps> | undefined;
  if (code) {
    ErrorPage = ErrorPageByErrorCode[code];
  }
  return ErrorPage ? <ErrorPage errorCode={Number(code!)} /> : <UnknownErrorPage />;
};

export default MasterErrorPage;
