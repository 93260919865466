enum UpdateProfileErrorCode {
  InvalidUserId = 1900,
  InvalidUsername = 1901,
  InvalidBio = 1902,
  InvalidLinks = 1903,
  ProfileNotFound = 1904,
  AllTagsAreInUse = 1905,
  InvalidLocale = 1906,
  InvalidCurrency = 1907,
  InvalidAvatarSize = 1908,
  InvalidAvatarExtension = 1909,
}

export default UpdateProfileErrorCode;
