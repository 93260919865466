enum DuelOfHonorStatus {
  WaitingForRival = 'waitingForRival',
  Accepted = 'accepted',
  Failed = 'failed',
  ReadyCheck = 'readyCheck',
  ReadyCheckFailed = 'readyCheckFailed',
  Launching = 'launching',
  Launched = 'launched',
  Finished = 'finished',
}

export default DuelOfHonorStatus;
