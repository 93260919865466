import { FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';
import { CampaignFundraiser } from './types';

const CampaignFundraiserDataConverter: FirestoreDataConverter<CampaignFundraiser> = {
  fromFirestore(snapshot: QueryDocumentSnapshot<CampaignFundraiser>): CampaignFundraiser {
    const data = snapshot.data();
    return data as CampaignFundraiser;
  },

  toFirestore(): {} {
    return {};
  },
};

export default CampaignFundraiserDataConverter;
