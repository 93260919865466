/* eslint-disable no-continue */
import { useCallback } from 'react';

const useCombinedRef = <T = any>(...refs: React.Ref<T>[]) =>
  useCallback((current: T) => {
    for (let i = 0; i < refs.length; i += 1) {
      const ref = refs[i];

      if (ref == null) continue;
      if (typeof ref === 'function') {
        ref(current);
      } else {
        // @ts-ignore
        ref.current = current;
      }
    }
  }, refs);

export default useCombinedRef;
