import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from '@/common/ui';

import WarningSvg from './warning.svg';

const RootElement = styled.div`
  background: #e91e63;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
`;

const Icon = styled(WarningSvg)`
  fill: #fff;
  height: 20px;
`;

const Description = styled.p`
  color: #fff;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 1.1;
  margin: 0 45px 0 15px;
`;

interface OfflineBannerProps {
  className?: string;
}

const OfflineBanner = ({ className }: OfflineBannerProps) => {
  const { t } = useTranslation('serviceWorker');
  return (
    <RootElement className={className}>
      <Content>
        <Icon />
        <Description>{t('network.offline')}</Description>
      </Content>
    </RootElement>
  );
};

export default OfflineBanner;
