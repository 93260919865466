import { navigate } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import getProfilePagePath from '@/paths/getProfilePagePath';
import MenuButton from './MenuButton';
import SessionContext from '../SessionContext';
import { AmpliWrapper, Col } from '@/components';
import {
  AvatarLegacy,
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  Tooltip,
  TruncateMixin,
} from '../ui';

const ProfileAvatar = styled(AvatarLegacy)`
  flex-shrink: 0;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  width: 120px;
  ${TruncateMixin};
`;

const Subtitle = styled.div`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 12px;
  line-height: 14px;
  color: rgba(210, 207, 236, 0.5);
`;

const StyledMenuButton = styled(MenuButton)`
  padding: 4px;
  text-align: start;
`;

const ProfileButton = () => {
  const { t } = useTranslation('header');
  const { publicProfile } = useContext(SessionContext);

  return (
    <AmpliWrapper event="click_profile_top" wrap>
      <Tooltip content={t('profile.tooltip')} strategy="fixed" offset="12">
        <StyledMenuButton type="button" onClick={() => navigate(getProfilePagePath(publicProfile?.id!))}>
          <ProfileAvatar profile={publicProfile} size="32" plain />
          <Text>
            <Title>{publicProfile?.username}</Title>
            <Subtitle>{t('mainPage.goToProfile')}</Subtitle>
          </Text>
        </StyledMenuButton>
      </Tooltip>
    </AmpliWrapper>
  );
};

export default ProfileButton;
