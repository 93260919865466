import { navigate } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';

import { ExperienceFailedNotificationView } from '../Views';
import getExperiencePagePath from '@/paths/getExperiencePagePath';
import { NotificationType } from '../../types';
import NotificationViewModel from '../model/NotificationViewModel';

import NotificationConsumer from './NotificationConsumer';

class ExperienceFailedNotificationConsumer implements NotificationConsumer {
  type = NotificationType.ExperienceFailed;

  onClick = (notification: NotificationViewModel) => {
    navigate(getExperiencePagePath(notification.experience!.id));
  };

  perform(notification: NotificationViewModel, read: () => void) {
    toast.info(this.render(notification), {
      onClick: () => {
        this.onClick(notification);
        read();
      },
      toastId: notification.id,
      hideProgressBar: true,
    });
  }

  render(notification: NotificationViewModel) {
    return <ExperienceFailedNotificationView notification={notification} />;
  }
}

export default ExperienceFailedNotificationConsumer;
