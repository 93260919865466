import { Currency, WithdrawalRequestStatus, WithdrawRequestErrorCode } from '@/common/types';
import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from '@/common/ui';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Button from '@/common/Button';
import ClientErrorCode from '../common/ClientErrorCode';
import LocaleAndCurrencyContext from '../common/LocaleAndCurrencyContext';
import SessionContext from '../common/SessionContext';
import TextArea from '../common/TextArea';
import { errorToast } from '../common/Toast';

import ArrowLeftSvg from './arrow-left.svg';
import useWithdrawalRequest from './useWithdrawalRequest';
import WalletStep from './WalletStep';
import WalletStepProps from './WalletStepProps';
import WithdrawTotalField from './WithdrawTotalField';

const MINIMAL_WITHDRAW = 5000;
const usdToEur = 0.85; // USD to EUR rate according out calculations

const Container = styled.div`
  padding-bottom: 10px;
`;

const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 14px;
  background: #4c458e;
`;

const Balance = styled.div`
  text-align: center;
  flex-basis: 50%;
`;

const BalanceHeading = styled.div`
  color: rgba(255, 255, 255, 0.65);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

const BalanceTotal = styled.span`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 25px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  line-height: 38px;
  margin-top: 15px;
  text-align: center;
`;

const BalanceTotalHighlighted = styled(BalanceTotal)<{ $balance: number }>`
  ${({ $balance }) => {
    if ($balance >= MINIMAL_WITHDRAW) {
      return css`
        color: #fbc02d;
      `;
    }
    return css`
      color: #e91e63;
    `;
  }}
`;

const Withdraw = styled.div`
  background: #2b2660;
  padding: 10px 20px;
`;

const WithdrawDescription = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin-top: 10px;

  strong {
    color: rgba(255, 255, 255, 1);
    font-weight: ${FONT_WEIGHT_MEDIUM};
  }
`;

const WithdrawButton = styled(Button)`
  margin-top: 20px;
`;

const HeaderText = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  flex-grow: 1;
  text-align: center;
`;

const SmallIconButton = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  color: inherit;
  position: absolute;
  transition: all 300ms ease-out;

  &::before {
    content: '';
    background: rgba(211, 206, 239, 0.15);
    bottom: 0;
    border-radius: 20px;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 300ms ease-out;
    right: 0;
    z-index: auto;
    opacity: 0;
  }

  svg {
    height: 18px;
    fill: currentColor;
    transition: all 300ms ease-out;
    width: 18px;
  }
`;

const Header = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: flex;
  height: 50px;
  padding: 0 14px;
  width: 100%;
  text-align: center;

  &:hover,
  &:focus {
    ${SmallIconButton} {
      &::before {
        opacity: 1;
      }
    }
  }
`;

const Field = styled.div`
  margin-top: 10px;
`;

const PendingRequests = styled.div`
  margin-top: 40px;
`;

const RequestsHeading = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  padding: 5px 0;
  border-bottom: 1px solid #3a3377;
`;

const RequestsTable = styled.table`
  border-spacing: 0 5px;
  margin-top: 10px;
  width: 100%;
`;

const Request = styled.tr`
  vertical-align: middle;
  height: 30px;
`;

const RequestCol = styled.td`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

const RequestTotal = styled(RequestCol)`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

const RequestDate = styled(RequestCol)`
  color: rgba(255, 255, 255, 0.5);
`;

const RequestStatus = styled(RequestCol)`
  text-align: right;
`;

const MONEY_STEP = 10;
const AMOUNT_INPUT_ID = 'total_inpt';

const WalletWithdrawStep: FC<WalletStepProps> = ({ setStep }: WalletStepProps) => {
  const { t } = useTranslation('wallet');
  const { currency } = useContext(LocaleAndCurrencyContext);
  const { user, privateProfile } = useContext(SessionContext);
  const { total, setTotal, note, setNote, withdraw, withdrawalRequests, loadingState, error } = useWithdrawalRequest(
    user!.uid
  );

  const formError: Record<number, string> = {
    [WithdrawRequestErrorCode.InsufficientFunds]: t('withdrawalStep.errorMessage.insufficientFunds'),
    [WithdrawRequestErrorCode.InvalidNote]: t('withdrawalStep.errorMessage.invalidNote'),
    [WithdrawRequestErrorCode.InvalidWithdrawalSum]: t('withdrawalStep.errorMessage.invalidWithdrawalSum'),
    [WithdrawRequestErrorCode.NotAllowed]: t('withdrawalStep.errorMessage.unknownError'),
    [WithdrawRequestErrorCode.ProfileNotFound]: t('withdrawalStep.errorMessage.unknownError'),
    [ClientErrorCode.NoInternetOrServerConnection]: t('withdrawalStep.errorMessage.noConnection'),
    [ClientErrorCode.UnknownError]: t('withdrawalStep.errorMessage.unknownError'),
  };

  useEffect(() => {
    if (error && loadingState.loadFailed) {
      errorToast(formError[error.code] || formError[ClientErrorCode.UnknownError]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loadingState.loadFailed, withdrawalRequests]);

  const currencyCoefficient = currency === Currency.EUR ? usdToEur : 1;

  return (
    <Container>
      <Header onClick={() => setStep(WalletStep.Packages)}>
        <SmallIconButton>
          <ArrowLeftSvg />
        </SmallIconButton>
        <HeaderText>{t('withdrawalStep.heading')}</HeaderText>
      </Header>
      <BalanceRow>
        <Balance>
          <BalanceHeading>{t('withdrawalStep.minWithdrawAmount')}</BalanceHeading>
          <BalanceTotal>
            {t('withdrawalStep.fs', {
              balance: 5000,
            })}
          </BalanceTotal>
        </Balance>
        <Balance>
          <BalanceHeading>{t('withdrawalStep.availableFunds')}</BalanceHeading>
          <BalanceTotalHighlighted $balance={privateProfile!.balance.f}>
            {t('withdrawalStep.fs', {
              balance: privateProfile!.balance.f,
            })}
          </BalanceTotalHighlighted>
        </Balance>
      </BalanceRow>
      <Withdraw>
        <WithdrawDescription>
          {t('withdrawalStep.description', { minAmount: MINIMAL_WITHDRAW })} {t('withdrawalStep.automatedWithdraw')}
        </WithdrawDescription>
        <Field>
          <WithdrawTotalField
            value={total}
            onChange={setTotal}
            step={MONEY_STEP}
            maxValue={privateProfile?.balance.f}
            id={AMOUNT_INPUT_ID}
            currency={t('withdrawalStep.approximateWithdrawalTotal', {
              balance: total * currencyCoefficient,
              currency,
            })}
          />
        </Field>
        <Field>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={t('withdrawalStep.optionalMessage')}
          />
        </Field>
        <WithdrawButton
          type="opaque"
          size="large"
          disabled={privateProfile!.balance.f < MINIMAL_WITHDRAW}
          loading={loadingState.loading}
          onClick={withdraw}
        >
          {t('withdrawalStep.withdrawButton')}
        </WithdrawButton>
        <PendingRequests>
          {Boolean(withdrawalRequests?.length) && (
            <>
              <RequestsHeading>{t('withdrawalStep.requests.heading')}</RequestsHeading>
              <RequestsTable>
                {withdrawalRequests!.map((request) => (
                  <Request>
                    <RequestTotal>{t('withdrawalStep.fs', { balance: request.total })}</RequestTotal>
                    <RequestDate>{t('withdrawalStep.requests.date', { date: request.createdAt })}</RequestDate>
                    <RequestStatus>
                      {(t('withdrawalStep.requests.status') as Record<WithdrawalRequestStatus, string>)[request.status]}
                    </RequestStatus>
                  </Request>
                ))}
              </RequestsTable>
            </>
          )}
        </PendingRequests>
      </Withdraw>
    </Container>
  );
};

export default WalletWithdrawStep;
