enum HandleSteamConnectErrorCode {
  SteamProfileIsNotPublic = 1200,
  SteamAccountIsVacBanned = 1201,
  CounterStrikeIsNotPurchased = 1202,
  NotEnoughTimePlayed = 1203,
  PlayerNotFoundError = 1204,
  ExternalAccountAlreadyInUse = 1205,
  ProfileNotFound = 1206,
  SteamAccountIsBannedByPlatform = 1207,
}

export default HandleSteamConnectErrorCode;
