import React from 'react';
import styled, { css } from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from '@/common/ui';

type ParagraphType = 'secondary' | 'subtitle';

const ParagraphEl = styled.p<{ $type?: ParagraphType }>`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 1.25em;
  margin-bottom: 1.25em;
  color: #fff;
  ${({ $type }) =>
    $type === 'subtitle' &&
    css`
      font-size: 24px;
      font-weight: ${FONT_WEIGHT_REGULAR};
      color: rgba(255, 255, 255, 0.8);
    `}
  ${({ $type }) =>
    $type === 'secondary' &&
    css`
      color: #d2cfec;
    `}
`;

interface Props {
  type?: ParagraphType;
}

const Paragraph: React.FC<Props> = (props) => {
  const { type, ...rest } = props;
  return <ParagraphEl $type={type} {...rest} />;
};

export default Paragraph;
