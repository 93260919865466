import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../styleConstants';

import StepsSvg from './steps.svg';

const PlaceholderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const PlaceholderIcon = styled(StepsSvg)`
  fill: rgba(211, 206, 239, 0.5);
  height: 96px;
`;

const PlaceholderTitle = styled.div`
  color: rgba(211, 206, 239, 0.35);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-top: 14px;
`;

export interface IStepsProps {
  title: string;
}

export const Steps = ({ title, ...props }: IStepsProps) => (
  <PlaceholderContainer {...props}>
    <PlaceholderIcon />
    <PlaceholderTitle>{title}</PlaceholderTitle>
  </PlaceholderContainer>
);
