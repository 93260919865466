import ampli from '@/services/ampli';
import userTracking from '../common/userTracking';
import WalletAnalyticsStep from './WalletAnalyticsStep';
import { FsPackage, PaymentMethod } from '@/common/types';

const Step: Record<WalletAnalyticsStep, string> = {
  [WalletAnalyticsStep.Bundles]: 'wallet/bundles',
  [WalletAnalyticsStep.PaymentMethod]: 'wallet/paymentMethod',
  [WalletAnalyticsStep.Success]: 'wallet/success',
};

const sendWalletAnalyticsStep = (
  step: WalletAnalyticsStep,
  details?: {
    checkoutSession?: string;
    pack?: FsPackage;
    paymentMethod?: PaymentMethod;
  }
) => {
  const eventName = Step[step];
  ampli.log(eventName);
  userTracking.event(
    eventName,
    details
      ? {
          walletCheckoutSession: details.checkoutSession,
          walletPaymentMethod: details.paymentMethod,
          walletPaymentTotal: details.pack ? details.pack.price / 100 : undefined,
        }
      : {}
  );
};

export default sendWalletAnalyticsStep;
