const configs: Record<string, Object> = {
  development: {
    apiKey: 'AIzaSyCa4tbDwEmdgfiKYclAtUhYa7cZgNlBZ9Q',
    authDomain: 'dev-298ad.firebaseapp.com',
    databaseURL: 'https://dev-298ad.firebaseio.com',
    projectId: 'dev-298ad',
    storageBucket: 'dev-298ad.appspot.com',
    messagingSenderId: '313352708556',
    appId: '1:313352708556:web:298168ddf90cfe83b93d69',
  },
  staging: {
    apiKey: 'AIzaSyBaq3Y42zedHBDaqF38XqkbRZ1gxX54LDE',
    authDomain: 'stg-32462.firebaseapp.com',
    databaseURL: 'https://stg-32462.firebaseio.com',
    projectId: 'stg-32462',
    storageBucket: 'stg-32462.appspot.com',
    messagingSenderId: '667531501996',
    appId: '1:667531501996:web:a389d63917fb69ea12da09',
  },
  production: {
    apiKey: 'AIzaSyAcHAeltAnfmjZ1IIAi4xIQFZ0L1OZJLl4',
    authDomain: 'prod-6dc34.firebaseapp.com',
    databaseURL: 'https://prod-6dc34.firebaseio.com',
    projectId: 'prod-6dc34',
    storageBucket: 'prod-6dc34.appspot.com',
    messagingSenderId: '214349098285',
    appId: '1:214349098285:web:28dd9b1b0cb632533af7a4',
    measurementId: 'G-PTXE0Q4HBK',
  },
};

let env = process.env.FIREBASE_ENV;
if (!env) {
  env = 'development';
}

export default configs[env];
