import { useEffect } from 'react';
import useQueryParams from '../common/useQueryParams';
import Cookies from 'js-cookie';

const ReferralCode = () => {
  const { referral_code: referralCode } = useQueryParams<{ referral_code?: string }>();
  useEffect(() => {
    if (referralCode) {
      Cookies.set('__session', referralCode, { domain: window.location.hostname });
    }
  }, [referralCode]);
  return null;
};

export default ReferralCode;
