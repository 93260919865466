import { HandleDiscordAuthErrorCode } from '@/common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultErrorView from '../DefaultErrorView';
import ErrorPageProps from '../ErrorPageProps';

const ErrorMessageKey: Record<number, string> = {
  [HandleDiscordAuthErrorCode.InvalidCode]: 'invalidCode',
  [HandleDiscordAuthErrorCode.InvalidState]: 'invalidState',
};

const DiscordAuthErrorPage = ({ errorCode }: ErrorPageProps) => {
  const { t } = useTranslation('errors');
  return (
    <DefaultErrorView
      heading={t(`discordAuthErrorPage.heading`)}
      description={t(`discordAuthErrorPage.errorCodes.${ErrorMessageKey[errorCode]}`)}
    />
  );
};

export default DiscordAuthErrorPage;
