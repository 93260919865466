import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from '@reach/router';

import {
  BREAKPOINT_MEDIUM,
  Button,
  Container,
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_REGULAR,
  Z_INDEX_7,
} from '../ui';
import React, { useContext, useEffect, useState } from 'react';

import Activities from './Activities/ActivitiesMenu';
import AmpliWrapper from '@/components/AmpliWrapper';
import HeaderMenu from './Menu';
import LanguagesDropdown from './LanguagesDropdown';
import NavLink from '../NavLink';
import Notifications from './Notifications';
import ProfileButton from './ProfileButton';
import SessionContext from '../SessionContext';
import Tooltip from '../Tooltip';
import useEscape from '../ui/helpers/useEscapeKey';
import WalletDropdown from './WalletDropdown';

import getCampaignPagePath from '@/paths/getCampaignPagePath';
import getCreateExperiencePagePath from '@/paths/getCreateExperiencePagePath';
import getExperiencesPagePath from '@/paths/getExperiencesPagePath';
import getHomePagePath from '@/paths/getHomePagePath';
import getSignInPath from '@/paths/getSignInPath';
import getSignUpPath from '@/paths/getSignUpPath';
import getTournamentsPath from '../../tournaments/getTournamentsPath';
import prosPagePath from '../../pros/prosPagePath';

import CharitySvg from '../../sidebar/Charity.svg';
import CloseSvg from '../close.svg';
import ExperiencesSvg from '../../sidebar/experiences.svg';
import GGSvg from '../GloryToUkraine/logo-glory-to-ukraine.svg';
import MenuSvg from './menu.svg';
import ModalBlurSJpg from '../JoinExperienceModal/modal-blur-s.jpg';
import ProsSvg from '../../sidebar/pros.svg';
import TournamentsSvg from '../../sidebar/tournaments.svg';

const MenuButton = styled(Button)`
  margin: 3px;
  height: 40px;
  border-radius: 50px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 17px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 24px;
`;

const RootComponent = styled.header<{ $bg?: boolean }>`
  background: transparent;
  background: linear-gradient(180deg, #0b1022 0%, rgba(11, 16, 34, 0) 100%);
  display: flex;
  height: 60px;
  max-width: inherit;
  width: 100%;
  z-index: ${Z_INDEX_7};
`;

const MyContainer = styled(Container)`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0 20px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ItemWrapper = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:first-of-type):not(:empty) {
    margin-left: 15px;
  }
`;

const DesktopItemWrapper = styled(ItemWrapper)`
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    display: none;
  }
`;

const LeftItems = styled.div`
  display: flex;
  flex-grow: 1;
`;

const RightItems = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const Menu = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${99999 + 1};
  background: linear-gradient(rgba(10, 16, 35, 0.5), rgba(10, 16, 35, 0.5)), url(${ModalBlurSJpg});
  background-size: cover;
  padding: 15px 20px;
`;

const CloseBtn = styled.button`
  border: none;
  color: white;
  background: none;
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;

  & > svg {
    width: 100%;
    color: white;
  }
`;

const MenuContent = styled.div`
  margin-top: 20px;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  text-decoration: none;

  & > svg {
    height: 100%;
  }

  & > div {
    color: white;
    text-decoration: none;
    margin-left: 10px;
    font-size: 20px;
    font-family: ${FONT_FAMILY_BRUTAL_TYPE};
    font-weight: ${FONT_WEIGHT_REGULAR};
    text-transform: uppercase;
  }
`;

const NavLinks = styled.div`
  padding: 30px 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 2px solid rgba(210, 207, 236, 5%);
  border-bottom: 2px solid rgba(210, 207, 236, 5%);
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;

  & > div {
    margin-left: 15px;
    font-size: 20px;
    font-family: ${FONT_FAMILY_BRUTAL_TYPE};
    font-weight: ${FONT_WEIGHT_REGULAR};
    text-transform: uppercase;
  }

  &:not(:first-child) {
    margin-top: 45px;
  }

  &[data-active='true'] {
    svg {
      fill: #fff;
    }
  }

  &[aria-current='page'] {
    pointer-events: none;
    cursor: default;
  }

  svg {
    fill: #8f909b;

    &:focus:not(:focus-visible),
    &:hover {
      fill: #fff;
    }
  }
`;

const Header: React.FC = (props) => {
  const { t } = useTranslation('header');
  const { loggedIn } = useContext(SessionContext);
  const [bg, setBg] = useState<boolean>(false);
  const [linksMenuOpen, setLinksMenuOpen] = useState(false);

  const scrollListener = () => {
    setBg(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEscape(() => {
    if (linksMenuOpen) {
      setLinksMenuOpen(false);
    }
  });

  return (
    <>
      {linksMenuOpen && (
        <Menu>
          <CloseBtn
            onClick={() =>
              setLinksMenuOpen((state) => {
                return !state;
              })
            }
          >
            <CloseSvg />
          </CloseBtn>
          <MenuContent>
            <LogoLink to={getHomePagePath()}>
              <GGSvg />
            </LogoLink>

            <NavLinks>
              <NavItem to={getExperiencesPagePath()}>
                <ExperiencesSvg />
                <div>{t('sidebar:experiences')}</div>
              </NavItem>
              <NavItem to={getCampaignPagePath('streamers-with-ukraine')}>
                <CharitySvg />
                <div>{t('sidebar:charity')}</div>
              </NavItem>
              <NavItem to={getTournamentsPath()}>
                <TournamentsSvg />
                <div>{t('sidebar:tournaments')}</div>
              </NavItem>
              <NavItem to={prosPagePath.getPath()}>
                <ProsSvg />
                <div>{t('sidebar:pros')}</div>
              </NavItem>
            </NavLinks>
          </MenuContent>
        </Menu>
      )}
      <RootComponent $bg={bg} {...props}>
        <MyContainer>
          <ItemsContainer>
            <MediaQuery maxWidth={BREAKPOINT_MEDIUM}>
              <div
                onClick={() =>
                  setLinksMenuOpen((state) => {
                    return !state;
                  })
                }
              >
                <MenuSvg />
              </div>
            </MediaQuery>
            {loggedIn ? (
              <>
                <LeftItems>
                  <DesktopItemWrapper>
                    <AmpliWrapper event="click_create_experiences_top" wrap>
                      <Tooltip content={t('header.createExperienceTooltip')} strategy="fixed" offset="12">
                        <MenuButton type="secondary" href={getCreateExperiencePagePath()} size="base">
                          {t('header.createExperience')}
                        </MenuButton>
                      </Tooltip>
                    </AmpliWrapper>
                  </DesktopItemWrapper>
                  <AmpliWrapper event="click_no_active_experience_top">
                    <DesktopItemWrapper>
                      <Activities />
                    </DesktopItemWrapper>
                  </AmpliWrapper>
                </LeftItems>
                <RightItems>
                  <DesktopItemWrapper>
                    <Notifications />
                  </DesktopItemWrapper>
                  <ItemWrapper>
                    <WalletDropdown />
                  </ItemWrapper>
                  <DesktopItemWrapper>
                    <ProfileButton />
                  </DesktopItemWrapper>
                  <ItemWrapper>
                    <HeaderMenu />
                  </ItemWrapper>
                </RightItems>
              </>
            ) : (
              <>
                <RightItems>
                  <ItemWrapper>
                    <LanguagesDropdown />
                  </ItemWrapper>
                  <ItemWrapper>
                    <Button type="transparent" size="large" href={getSignInPath()}>
                      {t('header.logIn')}
                    </Button>
                  </ItemWrapper>
                  <ItemWrapper>
                    <Button
                      type="opaque"
                      size="large"
                      onClick={() =>
                        navigate(getSignUpPath(), {
                          state: {
                            navigateTo: `${window.location.pathname}${window.location.search}`,
                          },
                        })
                      }
                    >
                      {t('header.signUp')}
                    </Button>
                  </ItemWrapper>
                </RightItems>
              </>
            )}
          </ItemsContainer>
        </MyContainer>
      </RootComponent>
    </>
  );
};

export default Header;
