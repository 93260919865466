import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR, Z_INDEX_1 } from '../styleConstants';

import InfoIcon from './info.svg';

const MessageInfoWrapper = styled.div`
  position: relative;
`;

const MessageInfo = styled.div`
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 12px;
  line-height: 16px;
  color: #d3ceef;
  font-family: ${FONT_FAMILY_ROBOTO};
  background: #33344c;
  border-radius: 5px 5px 0 0;
  padding: 10px 7px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -34px;
  left: 0;
  right: 0;
  z-index: ${Z_INDEX_1};
`;

const MessageInputInfoText = styled.div`
  margin-left: 5px;
`;

interface ChatInfoProps {
  info: string;
}

export const ChatInfo = ({ info }: ChatInfoProps) => (
  <MessageInfoWrapper>
    <MessageInfo>
      <InfoIcon />
      <MessageInputInfoText>{info}</MessageInputInfoText>
    </MessageInfo>
  </MessageInfoWrapper>
);
