import { rgba } from 'polished';
import { keyframes } from 'styled-components';

const pulse = ($color: string = 'white') => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${rgba($color, 0.2)}, 0 0 0 0 ${rgba($color, 0.2)}, 0 0 0 0 ${rgba(
  $color,
  0.2,
)};
  }
  ${(100 / 7) * 1}% {
    box-shadow: 0 0 0 4px ${rgba($color, 0.2)}, 0 0 0 0 ${rgba($color, 0.2)}, 0 0 0 0 ${rgba(
  $color,
  0.2,
)};
  }
  ${(100 / 7) * 2}% {
    box-shadow: 0 0 0 8px ${rgba($color, 0.1)}, 0 0 0 4px ${rgba($color, 0.2)}, 0 0 0 0 ${rgba(
  $color,
  0.2,
)};
  }
  ${(100 / 7) * 3}% {
    box-shadow: 0 0 0 12px ${rgba($color, 0)}, 0 0 0 8px ${rgba($color, 0.1)}, 0 0 0 4px ${rgba(
  $color,
  0.2,
)};
  }
  ${(100 / 7) * 4}% {
    box-shadow: 0 0 0 0 ${rgba($color, 0)}, 0 0 0 12px ${rgba($color, 0)}, 0 0 0 8px ${rgba(
  $color,
  0.1,
)};
  }
  100% {
    box-shadow: 0 0 0 0 ${rgba($color, 0)}, 0 0 0 0 ${rgba($color, 0)}, 0 0 0 12px ${rgba(
  $color,
  0,
)};
  }
`;

export default pulse;
