import React from 'react';
import styled from 'styled-components';

import { Spinner } from '@/components';
import Row from '@/components/Grid/Row';

type ButtonType = 'text';

const ButtonEl = styled.button<{ $shape?: string; $block?: boolean; $type?: ButtonType; $disabled?: boolean }>`
  position: relative;
  border: none;
  outline: none;
  background: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  padding: 10px 30px;
  margin: 0;
  span + span {
    margin-left: 14px;
  }
  ${({ $block }) => $block && 'width:100%;'}
  ${({ $type }) => $type === 'text' && 'padding: 10px 20px;'}
  ${({ $disabled }) =>
    $disabled &&
    `
    pointer-events: none;
    opacity:0.75;
  `}
  ${({ $shape }) => $shape && 'padding: 0;'}
  ${({ $shape }) =>
    $shape === 'round' &&
    `
    padding: 8px 8px 6px;
    border-radius:6px;
    border: 1px solid rgba(210, 207, 236, 0.35);
  `}
`;

const IconWrapper = styled.span`
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
`;

const LabelWrapper = styled.span``;

const SpinnerWrapper = styled(Row)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export interface BaseButtonProps {
  block?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  shape?: 'circle' | 'round';
  type?: ButtonType;
  onClick?: (e: React.MouseEvent) => void;
}

const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const { block, disabled, loading, shape, type, icon, children, ...rest } = props;
  return (
    <ButtonEl $block={block} $shape={shape} $type={type} $disabled={disabled || loading} {...rest}>
      {loading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {children && <LabelWrapper>{children}</LabelWrapper>}
        </>
      )}
    </ButtonEl>
  );
};

export default BaseButton;
