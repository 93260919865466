import { CheckoutSessionStatus } from '@/common/types';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LocaleAndCurrencyContext from '../common/LocaleAndCurrencyContext';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_FAMILY_ROBOTO, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from '@/common/ui';
import { errorToast } from '../common/Toast';

import FailedSvg from './failed.svg';
import InProgressSvg from './in-progress.svg';
import sendWalletAnalyticsStep from './sendWalletAnalyticsStep';
import SuccessSvg from './success.svg';
import useCheckoutSession from './useCheckoutSession';
import usePayment from './usePayment';
import WalletAnalyticsStep from './WalletAnalyticsStep';
import WalletPaymentMethodStep from './WalletPaymentMethodStep';
import WalletStepProps from './WalletStepProps';

const Container = styled.div`
  padding-bottom: 10px;
  position: relative;
`;

const Header = styled.div`
  background: #3a3377;
  display: flex;
  padding: 16px 20px;
`;

const HeaderHeading = styled.h3`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.01em;
  line-height: 24px;
  margin-left: 12px;
`;

const Content = styled.div`
  align-items: center;
  /* background: #2b2660; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 355px;
  padding: 85px 0 95px;
`;

const Icon = styled.svg`
  height: 96px;
`;

const Heading = styled.h4`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.01mm;
  line-height: 24px;
  margin: 14px 0 0;
`;

const Description = styled.p`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin-top: 6px;
  max-width: 260px;
  text-align: center;

  a {
    color: rgba(211, 206, 239, 0.5);

    &:focus:not(:focus-visible),
    &:hover {
      color: rgba(211, 206, 239, 0.6);
    }
  }
`;

const Fs = styled.span`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: inherit;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 10px;
  min-height: 540px;
`;

const WalletPaymentMethodStepWrapper = styled.div`
  position: absolute;
  z-index: 0;
`;

const Backdrop = styled.div`
  background: rgba(43, 38, 96, 0.65);
  backdrop-filter: blur(20px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @supports not ((backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(10px))) {
    background: rgba(10, 16, 35, 0.8);
  }
`;

const WalletPaymentStep = ({ pack, paymentMethod, paymentPopup, ...walletProps }: WalletStepProps) => {
  const { t } = useTranslation('wallet');
  const { currency } = useContext(LocaleAndCurrencyContext);
  const {
    checkoutSessionId,
    loadingState: createLoadingState,
    error: createError,
  } = usePayment(paymentMethod!, pack!.price, paymentPopup!);
  const { checkoutSession, loadingState: dataLoadingState, error: dataError } = useCheckoutSession(checkoutSessionId);
  const isSuccess = checkoutSession && checkoutSession.status === CheckoutSessionStatus.Accepted;
  const isFailed = checkoutSession && checkoutSession.status === CheckoutSessionStatus.Failed;
  useEffect(() => {
    if (isSuccess) {
      sendWalletAnalyticsStep(WalletAnalyticsStep.Success, {
        checkoutSession: checkoutSessionId,
        pack,
        paymentMethod,
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (createError && createLoadingState.loadFailed) {
      errorToast(t('paymentStep.createErrorMessage'));
    }
  }, [createError, createLoadingState.loadFailed]);
  useEffect(() => {
    if (dataError && dataLoadingState.loadFailed) {
      errorToast(t('paymentStep.loadErrorMessage'));
    }
  }, [dataError, dataLoadingState.loadFailed]);
  return (
    <Wrapper>
      <WalletPaymentMethodStepWrapper>
        <WalletPaymentMethodStep
          pack={pack}
          paymentMethod={paymentMethod}
          paymentPopup={paymentPopup}
          {...walletProps}
        />
        <Backdrop />
      </WalletPaymentMethodStepWrapper>
      <Container>
        <Header>
          <HeaderHeading>
            {isSuccess &&
              t('paymentStep.success.pageHeading', {
                bundle: pack!.size,
                price: pack!.price,
                currency,
              })}
            {isFailed &&
              t('paymentStep.failed.pageHeading', {
                bundle: pack!.size,
                price: pack!.price,
                currency,
              })}
            {!isSuccess &&
              !isFailed &&
              t('paymentStep.inProgress.pageHeading', {
                bundle: pack!.size,
                price: pack!.price,
                currency,
              })}
          </HeaderHeading>
        </Header>
        <Content>
          {isSuccess && <Icon as={SuccessSvg} />}
          {isFailed && <Icon as={FailedSvg} />}
          {!isSuccess && !isFailed && <Icon as={InProgressSvg} />}
          <Heading>
            {isSuccess && t('paymentStep.success.heading')}
            {isFailed && t('paymentStep.failed.heading')}
            {!isSuccess && !isFailed && t('paymentStep.inProgress.heading')}
          </Heading>
          <Description>
            {isSuccess && (
              <Trans
                t={t}
                i18nKey="paymentStep.success.description"
                components={{ fs: <Fs /> }}
                values={{ bundle: pack!.size }}
              />
            )}
            {isFailed && (
              <Trans t={t} i18nKey="paymentStep.inProgress.description">
                There was an issue with the payment. Try again later or check our{' '}
                <a href="https://help.organization.gg" target="_blank" rel="noreferrer">
                  Help Center
                </a>
                .
              </Trans>
            )}
            {!isSuccess && !isFailed && (
              <Trans t={t} i18nKey="paymentStep.inProgress.description">
                Continue in the new tab. If nothing happens, check our{' '}
                <a href="https://help.organization.gg" target="_blank" rel="noreferrer">
                  Help Center
                </a>
                .
              </Trans>
            )}
          </Description>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default WalletPaymentStep;
