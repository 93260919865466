import { ParsedUrlQuery, stringify } from 'querystring';

export interface ProfilePageQueryParams extends ParsedUrlQuery {}

const getProfilePagePath = (id: string, params?: ProfilePageQueryParams) => {
  let path = `/profiles/${id}`;

  if (params) {
    path = `${path}?${stringify(params)}`;
  }

  return path;
};

export default getProfilePagePath;
