import { getIdToken } from 'firebase/auth';
import axios, { AxiosError } from 'axios';

import { auth } from './firebaseClient';
import ClientErrorCode from './ClientErrorCode';
import ErrorWithCode from './ErrorWithCode';

export const axiosGatewayConfig: Record<string, { baseURL: string }> = {
  development: {
    baseURL: 'https://api.dev.orgz.gg',
  },
  staging: {
    baseURL: 'https://api.dev.orgz.gg',
  },
  production: {
    baseURL: 'https://api.orgz.gg',
  },
};

let env = process.env.FIREBASE_ENV;
if (!env) {
  env = 'development';
}

const axiosApiGatewayClient = axios.create(axiosGatewayConfig[env]);

axiosApiGatewayClient.interceptors.request.use(async (config) => {
  const { currentUser } = auth;
  if (!currentUser) {
    return config;
  }
  const token = await getIdToken(currentUser);
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
});

axiosApiGatewayClient.interceptors.response.use(undefined, (error) => {
  if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError<any>;

    if (!axiosError.response) {
      throw new ErrorWithCode(ClientErrorCode.NoInternetOrServerConnection);
    }

    if (typeof axiosError.response.data?.errorCode === 'number') {
      throw new ErrorWithCode(axiosError.response.data.errorCode);
    }

    if (axiosError.response.data && /rpc error/.test(axiosError.response.data)) {
      throw new Error(axiosError.response.data);
    }

    if (axiosError.response.data?.error?.error_code) {
      throw new ErrorWithCode(axiosError.response.data.error.error_code);
    }
  }
  throw new ErrorWithCode(ClientErrorCode.UnknownError);
});

export default axiosApiGatewayClient;
