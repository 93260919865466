import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shade, tint } from 'polished';

import getCreateExperiencePagePath from '@/paths/getCreateExperiencePagePath';
import { Button, ButtonOutline } from '@/common/ui';

const YellowButton = styled(Button)`
  color: rgba(10, 16, 35, 0.9);
  background: linear-gradient(180deg, #fdd835 0%, #fbc02d 100%);

  &:focus:not(:focus-visible),
  &:hover {
    background: linear-gradient(180deg, ${tint(0.2, '#fdd835')} 0%, ${tint(0.2, '#fbc02d')} 100%);
  }
  &:disabled {
    background: linear-gradient(180deg, ${shade(0.2, '#fdd835')} 0%, ${shade(0.2, '#fbc02d')} 100%);
  }
`;

const ButtonCreateExperience: React.FC = () => {
  const { t } = useTranslation('experiences');
  return (
    <ButtonOutline outlineLines={2} borderColor="#FBC02D" disabled={false}>
      <YellowButton size="large" type="transparent" href={getCreateExperiencePagePath()}>
        {t('experiences.create')}
      </YellowButton>
    </ButtonOutline>
  );
};

export default ButtonCreateExperience;
