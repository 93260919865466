import { DocumentData, onSnapshot, FirestoreError, Query, QuerySnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import ClientErrorCode from './ClientErrorCode';
import ErrorWithCode from './ErrorWithCode';
import useLoadingState from './useLoadingState';

const useFirestoreCol = <T = DocumentData>(collRef: Query<T>) => {
  const [data, setData] = useState<T[]>([]);
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();

  const onFirestoreSnapshot = (snapshot: QuerySnapshot<T>) => {
    const receivedData = snapshot.docs.map((doc) => doc.data());

    if (!receivedData) {
      setLoadFailed();
      return;
    }

    unstable_batchedUpdates(() => {
      setLoaded();
      setData(receivedData);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFirestoreSnapshotError = (_error: FirestoreError) => {
    console.error(_error);
    unstable_batchedUpdates(() => {
      setLoadFailed();
      setError(new ErrorWithCode(ClientErrorCode.UnknownError));
    });
  };

  useEffect(() => {
    setLoading();

    return onSnapshot(collRef, onFirestoreSnapshot, onFirestoreSnapshotError);
  }, [collRef]);

  return {
    data,
    loadingState,
    error,
  };
};

export default useFirestoreCol;
