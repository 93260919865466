import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '@/common/ui';

type LevelType = 1 | 2 | 3 | 4 | 5 | 6;

const titleStyle: Record<LevelType, FlattenSimpleInterpolation> = {
  1: css`
    font-size: 54px;
    line-height: 1.25em;
    margin-bottom: 24px;
  `,
  2: css`
    font-size: 48px;
    margin-bottom: 20px;
  `,
  3: css`
    font-size: 32px;
    margin-bottom: 20px;
  `,
  4: css`
    font-size: 24px;
    margin-bottom: 20px;
  `,
  5: css`
    font-size: 16px;
    margin-bottom: 20px;
  `,
  6: css`
    font-size: 14px;
    margin-bottom: 20px;
  `,
};

const TitleEl = styled.div<{ $level: LevelType }>`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  color: #fff;
  ${({ $level = 1 }) => titleStyle[$level]};
`;

interface Props {
  level?: LevelType;
  style?: React.CSSProperties;
}

const Title: React.FC<Props> = (props) => {
  const { level = 1, ...rest } = props;
  return <TitleEl as={`h${level}`} $level={level} {...rest} />;
};

export default Title;
