import { Database, SerializedWithdrawalRequest, WithdrawalRequestStatus } from '@/common/types';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import axiosClient from '../common/axiosClient';
import ClientErrorCode from '../common/ClientErrorCode';
import ErrorWithCode from '../common/ErrorWithCode';
import { firestore } from '../common/firebaseClient';
import useLoadingState from '../common/useLoadingState';

const MIN_WITHDRAWAL_REQUEST = 5000;

const useWithdrawalRequest = (userId: string) => {
  const [total, setTotal] = useState<number>(MIN_WITHDRAWAL_REQUEST);
  const [note, setNote] = useState<string>();
  const [error, setError] = useState<ErrorWithCode>();
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const {
    loadingState: requestsLoading,
    setLoading: setRequestsLoading,
    setLoaded: setRequestsLoaded,
  } = useLoadingState();
  const [withdrawalRequests, setWithdrawalRequests] = useState<SerializedWithdrawalRequest[]>([]);

  useEffect(() => {
    setRequestsLoading();
    const unsubscribe = onSnapshot(
      query(
        collection(firestore, Database.PrivateProfiles, userId, Database.WithdrawalRequests),
        where('user', '==', userId),
        where('status', 'in', [WithdrawalRequestStatus.Created, WithdrawalRequestStatus.InProgress])
      ),
      (snapshot) => {
        setWithdrawalRequests(snapshot.docs.map((doc) => doc.data() as SerializedWithdrawalRequest));
        setRequestsLoaded();
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const resetForm = () => {
    unstable_batchedUpdates(() => {
      setTotal(MIN_WITHDRAWAL_REQUEST);
      setNote(undefined);
    });
  };

  const withdraw = async () => {
    setLoading();
    try {
      await axiosClient.post(`/api/users/${userId}/withdrawal-requests`, {
        total: total.toString(),
        note,
      });
      setLoaded();
      resetForm();
    } catch (_error) {
      unstable_batchedUpdates(() => {
        setLoadFailed();
        setError(_error instanceof ErrorWithCode ? _error : new ErrorWithCode(ClientErrorCode.UnknownError));
      });
    }
  };

  return {
    total,
    setTotal,
    note,
    setNote,
    loadingState,
    withdraw,
    error,
    withdrawalRequests,
    requestsLoading,
  };
};

export default useWithdrawalRequest;
