import { AvatarFrame } from '../../types';
import React, { ComponentType } from 'react';
import styled, { StyledComponentProps } from 'styled-components';

import BronzeWeeklyLeaderFrame from './Frames/BronzeWeeklyLeaderFrame';
import GoldWeeklyLeaderFrame from './Frames/GoldWeeklyLeaderFrame';
import SilverWeeklyLeaderFrame from './Frames/SilverWeeklyLeaderFrame';
import avatarPlaceholder from './placeholder.png';
import { AvatarProfile, FrameProps } from './types';

interface AvatarSizeProps {
  $size: number;
}

const UserImage = styled.img<AvatarSizeProps>`
  object-fit: cover;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
`;

const ImageCropper = styled.div<AvatarSizeProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: ${({ $size }) => $size / 2}px;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;

const AvatarContainer = styled.div<AvatarSizeProps & { $hasImage?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${({ $hasImage }) => ($hasImage ? `transparent` : ` rgba(211, 206, 239, 0.2)`)};
  border-radius: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  position: relative;
  flex-shrink: 0;
`;

const FrameComponent: Record<AvatarFrame, ComponentType<FrameProps>> = {
  [AvatarFrame.None]: () => null,
  [AvatarFrame.WeeklyLeaderGold]: GoldWeeklyLeaderFrame,
  [AvatarFrame.WeeklyLeaderSilver]: SilverWeeklyLeaderFrame,
  [AvatarFrame.WeeklyLeaderBronze]: BronzeWeeklyLeaderFrame,
};

interface AvatarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    StyledComponentProps<'div', any, any, never> {
  size: string | number;
  profile?: AvatarProfile;
  plain?: boolean;
}

const Avatar = ({ size, profile, plain = false, ...props }: AvatarProps) => {
  const mySize = typeof size === 'string' ? parseInt(size, 10) : size;

  const src = profile?.avatarURL || profile?.avatarUrl;

  const Frame =
    FrameComponent[profile?.avatarFrame || AvatarFrame.None] || FrameComponent[AvatarFrame.None];

  return (
    <AvatarContainer $size={mySize} $hasImage={!!src} {...props}>
      <ImageCropper $size={mySize}>
        <UserImage
          src={src ?? avatarPlaceholder}
          alt={profile?.username || 'avatar'}
          $size={mySize}
        />
      </ImageCropper>
      {!plain && <Frame size={mySize} />}
    </AvatarContainer>
  );
};

export default Avatar;
