import { getIdToken } from 'firebase/auth';
import axios, { AxiosError } from 'axios';

import { auth } from './firebaseClient';
import ClientErrorCode from './ClientErrorCode';
import ErrorWithCode from './ErrorWithCode';

const axiosClient = axios.create();

axiosClient.interceptors.request.use(async (config) => {
  const { currentUser } = auth;
  if (!currentUser) {
    return config;
  }
  const token = await getIdToken(currentUser);
  return {
    ...config,
    headers: { Authorization: `Bearer ${token}` },
  };
});

axiosClient.interceptors.response.use(undefined, (error) => {
  if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError<any>;
    if (!axiosError.response) {
      throw new ErrorWithCode(ClientErrorCode.NoInternetOrServerConnection);
    }
    if (typeof axiosError.response.data?.errorCode === 'number') {
      throw new ErrorWithCode(axiosError.response.data.errorCode);
    }
  }
  throw new ErrorWithCode(ClientErrorCode.UnknownError);
});

export default axiosClient;
