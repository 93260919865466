import GameViewModel from '@/models/GameViewModel';
import TeamViewModel from './team/TeamViewModel';
import TeamSeatViewModel from './team/TeamSeatViewModel';
import {
  ExperienceInvitation,
  ExperienceStatus,
  ExperienceTournamentInfo,
  FailureCause,
  FirestoreStatusGroup,
  InvitationStatus,
  ServerConfig,
} from './types';
import CheckInViewModel from './checkIn/CheckInViewModel';
import { FirestoreExperience } from './types';
import ExperienceMemberViewModel from './ExperienceMemberViewModel';
import { FirestoreExperienceTemplate, FirestoreExperienceTemplateContest } from './template/types';
import { ExperienceStrategy } from './strategies/types';
import ExperienceContestantViewModel from './ExperienceContestantViewModel';
import ReadyCheckViewModel from './readyCheck/ReadyCheckViewModel';
import ExperienceServerViewModel from './server/ExperienceServerViewModel';
import ExperienceSweepstakeStrategy from './strategies/sweepstake/ExperienceSweepstakeStrategy';
import ExperienceContestPool from './pool/ExperienceContestPool';
import {
  ExperienceSweepstakeStrategyActionMethod,
  SerializedExperienceSweepstakeStrategy,
} from './strategies/sweepstake/types';
import ExperienceTemplateStrategyViewModel from './template/strategy/ExperienceTemplateStrategyViewModel';

export default class ExperienceViewModel {
  static PLATFORM_COMISSION = 0.1;

  readonly id: string;
  readonly title?: string;
  readonly template: FirestoreExperienceTemplate;
  readonly status: ExperienceStatus;
  readonly failureCause?: FailureCause;
  readonly game: GameViewModel;
  readonly checkIn: CheckInViewModel;
  readonly teams: TeamViewModel[];
  readonly members: ExperienceMemberViewModel[];
  readonly contestants: ExperienceContestantViewModel[];
  readonly readyChecks: ReadyCheckViewModel[];
  readonly invitations: ExperienceInvitation[];
  readonly strategy: ExperienceStrategy;
  readonly server?: ExperienceServerViewModel;
  readonly startAt: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly finishedAt?: Date;
  readonly failedAt?: Date;
  readonly chatId?: string;
  readonly privateStatusGroup: FirestoreStatusGroup;
  readonly publicStatusGroup: FirestoreStatusGroup;
  readonly serverConfig?: ServerConfig;
  readonly pools: Partial<Record<string, ExperienceContestPool>>;
  readonly replayURL?: string;
  readonly tournament?: ExperienceTournamentInfo;

  constructor(props: {
    id: string;
    title?: string;
    template: FirestoreExperienceTemplate;
    status: ExperienceStatus;
    failureCause?: FailureCause;
    game: GameViewModel;
    checkIn: CheckInViewModel;
    teams: TeamViewModel[];
    members: ExperienceMemberViewModel[];
    contestants: ExperienceContestantViewModel[];
    invitations: ExperienceInvitation[];
    readyChecks: ReadyCheckViewModel[];
    strategy: ExperienceStrategy;
    server?: ExperienceServerViewModel;
    startAt: Date;
    createdAt: Date;
    updatedAt: Date;
    finishedAt?: Date;
    failedAt?: Date;
    chatId?: string;
    privateStatusGroup: FirestoreStatusGroup;
    publicStatusGroup: FirestoreStatusGroup;
    serverConfig?: ServerConfig;
    pools: Partial<Record<string, ExperienceContestPool>>;
    replayURL?: string;
    tournament?: ExperienceTournamentInfo;
  }) {
    this.id = props.id;
    this.title = props.title;
    this.template = props.template;
    this.status = props.status;
    this.failureCause = props.failureCause;
    this.game = props.game;
    this.checkIn = props.checkIn;
    this.teams = props.teams;
    this.members = props.members;
    this.contestants = props.contestants;
    this.invitations = props.invitations;
    this.readyChecks = props.readyChecks;
    this.strategy = props.strategy;
    this.server = props.server;
    this.startAt = props?.startAt;
    this.createdAt = props?.createdAt;
    this.updatedAt = props?.updatedAt;
    this.finishedAt = props?.finishedAt;
    this.failedAt = props.failedAt;
    this.chatId = props.chatId;
    this.publicStatusGroup = props.publicStatusGroup;
    this.privateStatusGroup = props.privateStatusGroup;
    this.serverConfig = props.serverConfig;
    this.pools = props.pools;
    this.replayURL = props.replayURL;
    this.tournament = props.tournament;
  }

  static fromFirestore(data: FirestoreExperience): ExperienceViewModel {
    const membersMap = data.members.reduce<Record<string, ExperienceMemberViewModel>>((map, member) => {
      map[member.id] = ExperienceMemberViewModel.fromFirestore(member);
      return map;
    }, {});

    const playerStats = (data.player_stats || []).reduce<Record<string, { [key: string]: number }>>((map, entry) => {
      map[entry.user] = entry.stats;
      return map;
    }, {});

    const teamStats = (data.team_stats || []).reduce<Record<string, { [key: string]: number }>>((map, entry) => {
      map[entry.team] = entry.stats;
      return map;
    }, {});

    const entities = {
      members: membersMap,
      teamStats,
      playerStats,
    };

    const contestants = (data.contestants || []).map((c) => ExperienceContestantViewModel.fromFirestore(c));
    contestants.sort((a, b) => {
      const valueComparison = b.totalValues - a.totalValues;
      if (valueComparison !== 0) {
        return valueComparison;
      }
      return b.createdAt.getTime() - a.createdAt.getTime();
    });

    return new ExperienceViewModel({
      id: data.id,
      title: data.title,
      template: data.template,
      status: data.status,
      game: GameViewModel.fromFirestore(data.game),
      checkIn: CheckInViewModel.fromFirestore(data.check_in),
      teams: data.teams.map((team) => TeamViewModel.fromFirestore(team, data.template, entities)),
      members: Object.values(entities.members),
      contestants,
      invitations: data.invitations || [],
      readyChecks: (data.ready_checks || []).map((rc) => ReadyCheckViewModel.fromFirestore(rc)),
      strategy: ExperienceSweepstakeStrategy.fromFirestore(data.strategy as SerializedExperienceSweepstakeStrategy),
      server: data.server ? ExperienceServerViewModel.fromFirestore(data.server) : undefined,
      startAt: data?.start_at?.toDate(),
      createdAt: data.created_at.toDate(),
      updatedAt: data.updated_at.toDate(),
      finishedAt: data.finished_at ? data.finished_at.toDate() : undefined,
      failureCause: data.failure ? data.failure.type : undefined,
      failedAt: data.failure ? data.failure.failed_at.toDate() : undefined,
      chatId: data.chat,
      publicStatusGroup: data.public_status_group,
      privateStatusGroup: data.private_status_group,
      serverConfig: data.server_config,
      pools: data?.pools
        ? data.pools.reduce<Partial<Record<string, ExperienceContestPool>>>((pools, pool) => {
            pools[pool.contest] = ExperienceContestPool.fromFirestore(pool);
            return pools;
          }, {})
        : {},
      replayURL: data.replay_url,
      tournament: data.tournament
        ? {
            id: data.tournament.id,
            name: data.tournament.name,
            bannerURL: data.tournament.banner_url,
            color: data.tournament.color,
            finishAt: data.tournament.finish_at.toDate(),
            logoURL: data.tournament.logo_url,
            startAt: data.tournament.start_at.toDate(),
          }
        : undefined,
    });
  }

  get owners(): ExperienceMemberViewModel[] {
    return this.members?.filter((member) => member.isOwner);
  }

  get leaders(): ExperienceMemberViewModel[] {
    return this.members?.filter((member) => member.isLeader);
  }

  get beneficiaries(): ExperienceMemberViewModel[] {
    const b = this.members?.filter((member) => member.isBeneficiary);
    return b.length > 0 ? b : this.owners;
  }

  getUserPrivateRoomId(user?: string) {
    if (user) {
      const member = this.members?.find(m => m.id === user);
      if (member) {
        for (const contest in this.pools) {
          const pool = this.pools[contest];
          if (pool && pool.chatRoomId && (pool.isContestant(member.id) || member.isLeader || member.isOwner )) {
            return pool.chatRoomId;
          }
        }
      }
    }
    return undefined;
  }

  findMemberById(id?: string): ExperienceMemberViewModel | undefined {
    return this.members.find((member) => member.id === id);
  }

  findSeatByMemberId(id?: string): TeamSeatViewModel | undefined {
    return this.teams.map((team) => team.findSeatByMemberId(id)).find((seat) => seat);
  }

  findTeamByMemberId(id?: string): TeamViewModel | undefined {
    for (const team of this.teams) {
      if (team.findSeatByMemberId(id)) {
        return team;
      }
    }
  }

  findTeamById(id?: string): TeamViewModel | undefined {
    return this.teams.find((team) => team.id === id);
  }

  findTeamsByContestId(contest?: string): TeamViewModel[] {
    return this.teams.filter((team) => team.contests.includes(contest!));
  }

  findContestantById(id?: string): ExperienceContestantViewModel | undefined {
    return this.contestants.find((contestant) => contestant.id === id);
  }

  findReadyCheckByTeam(team?: string): ReadyCheckViewModel | undefined {
    return this.readyChecks
      .sort((a, b) => b.finishAt.getTime() - a.finishAt.getTime())
      .filter((rc) => rc.team === team)[0];
  }

  findReadyCheckByMember(id?: string): ReadyCheckViewModel | undefined {
    return this.readyChecks
      .sort((a, b) => b.finishAt.getTime() - a.finishAt.getTime())
      .filter((rc) => rc.findParticipantById(id))[0];
  }

  findActiveReadyCheckByMember(id?: string): ReadyCheckViewModel | undefined {
    return this.readyChecks
      .filter((rc) => rc.isActive)
      .sort((a, b) => b.finishAt.getTime() - a.finishAt.getTime())
      .filter((rc) => rc.findParticipantById(id))[0];
  }

  findInvitationsBySeatId(id?: string): ExperienceInvitation[] {
    return this.invitations.filter((inv) => inv.status === InvitationStatus.Active && inv.seat === id);
  }

  findInvitationById(id?: string): ExperienceInvitation | undefined {
    return this.invitations.find((inv) => inv.id === id);
  }

  get isSomeTeamReadyCheckFailed() {
    return this.teams.some((team) => Boolean(this.findReadyCheckByTeam(team.id)?.isFailed));
  }

  get seats(): TeamSeatViewModel[] {
    return this.teams.reduce<TeamSeatViewModel[]>((res, team) => res.concat(team.seats), []);
  }

  get freeSeats(): TeamSeatViewModel[] {
    return this.teams.reduce<TeamSeatViewModel[]>((res, team) => res.concat(team.freeSeats), []);
  }

  get isDraft(): boolean {
    return this.status === ExperienceStatus.Draft;
  }

  get isPublished(): boolean {
    return this.status === ExperienceStatus.Published;
  }

  get isLive(): boolean {
    return this.status === ExperienceStatus.Live;
  }

  get isBeforeGameplay(): boolean {
    return !this.isEnded && (!this.server || this.server.loading);
  }

  get isBeforeLobby(): boolean {
    return (!this.server || this.server?.loading) && !this.isEnded;
  }

  get isGameplay(): boolean {
    return Boolean(this.server && !this.server.loading);
  }

  get isEveryTeamReadyCheckSuccess(): boolean {
    return this.teams.every((team) => this.findReadyCheckByTeam(team.id)?.isSuccess);
  }

  get isBeforeLive(): boolean {
    return this.isDraft || this.isPublished;
  }

  get isDrawing(): boolean {
    return this.isLive && this.teams.some((team) => team.members.length != team.seats.length);
  }

  get isEnded(): boolean {
    return this.isFinished || this.isFailed;
  }

  get isFailed(): boolean {
    return this.status === ExperienceStatus.Failed;
  }

  get isFinished(): boolean {
    return this.status === ExperienceStatus.Finished;
  }

  get playerStats() {
    return this.teams.reduce<{ user: string; stats: { [key: string]: number } }[]>((stats, team) => {
      team.seats.forEach((seat) => {
        if (seat.user && seat.stats) {
          stats.push({
            stats: seat.stats,
            user: seat.user.id,
          });
        }
      });
      return stats;
    }, []);
  }

  get totalEarned(): number {
    const totalEarned = this.contestants.reduce<number>((sum, contestant) => {
      const contestantTotal = contestant.entries.reduce<number>((total, entry) => {
        let subTotal = total;
        switch (this.strategy.type) {
          case 'sweepstake': {
            const strategy = this.strategy as ExperienceSweepstakeStrategy;
            const method = strategy.data.methods.find((m) => m.id === entry.method);
            if (method) {
              subTotal = total + method!.price;
            }
          }
        }

        return subTotal;
      }, 0);

      return sum + contestantTotal;
    }, 0);

    return Math.floor(totalEarned * (1 - ExperienceViewModel.PLATFORM_COMISSION));
  }

  get hasCharity(): boolean {
    switch (this.strategy.type) {
      case 'sweepstake': {
        const strategy = this.strategy as ExperienceSweepstakeStrategy;
        const actionMethods = strategy?.data?.methods?.filter((m) => m.type === 'action');
        const charityMethods: ExperienceSweepstakeStrategyActionMethod<any>[] = actionMethods?.filter(
          (am: ExperienceSweepstakeStrategyActionMethod<any>) => am.data.type === 'campaign_contribution'
        );
        return Boolean(charityMethods?.length);
      }
      default:
        return false;
    }
  }

  get totalCharityPool(): number {
    let total = 0;
    switch (this.strategy.type) {
      case 'sweepstake': {
        const strategy = this.strategy as ExperienceSweepstakeStrategy;
        const actionMethods = strategy.data.methods.filter((m) => m.type === 'action');
        const charityMethods: ExperienceSweepstakeStrategyActionMethod<any>[] = actionMethods.filter(
          (am: ExperienceSweepstakeStrategyActionMethod<any>) => am.data.type === 'campaign_contribution'
        );

        total = this.contestants.reduce<number>((t, contestant) => {
          const contestantTotal = contestant.entries.reduce<number>((ct, entry) => {
            const method = charityMethods.find((m) => m.id === entry.method);
            if (!method) {
              return ct;
            }
            const usd = entry.value / method.value;
            return ct + usd * 100;
          }, 0);
          return t + contestantTotal;
        }, 0);
      }
    }

    return total;
  }

  get totalPrizePool(): number {
    return Math.min(...this.teams.map((team) => this.getTeamEarned(team))) * this.teams.length;
  }

  get strategyTemplate(): ExperienceTemplateStrategyViewModel {
    return this.template.strategies.find((strategy) => strategy.id === this.strategy.template)!;
  }

  get canPerformServerCommands() {
    return Boolean(this.server && !this.server.loading);
  }

  get hasKickFromServer() {
    return Boolean(this.template.server.kick_player_url);
  }

  getContestById(id?: string): FirestoreExperienceTemplateContest | undefined {
    return this.template.contests.find((contest) => contest.id === id);
  }

  getContestParticipants(contest?: string): ExperienceContestantViewModel[] {
    return this.contestants.filter((c) => c.contest === contest);
  }

  getCheckedInContestants(contest?: string): ExperienceContestantViewModel[] {
    return this.getContestParticipants(contest).filter((c) => this.checkIn.hasParticipant(c.id));
  }

  getTeamEarned(team: TeamViewModel): number {
    const contestShare = this.template.contests.reduce<Record<string, number>>((map, contest) => {
      map[contest.id] = this.teams.reduce<number>(
        (matches, team) => matches + Number(team.contests.includes(contest.id)),
        0
      );
      return map;
    }, {});
    const contestSum = this.template.contests.reduce<Record<string, number>>((map, contest) => {
      map[contest.id] = this.getContestParticipants(contest.id).reduce<number>((sum, contestant) => {
        const contestantTotal = contestant.entries.reduce<number>((total, entry) => {
          let subTotal = total;
          switch (this.strategy.type) {
            case 'sweepstake': {
              const strategy = this.strategy as ExperienceSweepstakeStrategy;
              const method = strategy.data.methods.find((m) => m.id === entry.method);
              if (method) {
                subTotal = total + method!.price;
              }
            }
          }
          return subTotal;
        }, 0);
        return sum + contestantTotal;
      }, 0);
      return map;
    }, {});

    const teamEarned = team.contests.reduce<number>((sum, contest) => {
      return sum + contestSum[contest] / contestShare[contest];
    }, 0);

    return Math.floor(teamEarned * (1 - ExperienceViewModel.PLATFORM_COMISSION));
  }

  get winnerTeam(): TeamViewModel | undefined {
    if (!this.teams.every((team) => team.stats)) return undefined;
    const scoreId = this.template.server.team_stats[0]?.id;
    return [...this.teams].sort((a, b) => b.stats![scoreId] - a.stats![scoreId])[0];
  }

  toPlain() {
    return {
      id: this.id,
      leaders: this.leaders,
      owners: this.owners,
      teams: this.teams.map((team) => ({
        id: team.id,
        leaders: team.leaders,
        members: team.members,
        seats: team.seats,
        team: team.name,
      })),
      totalPrizePool: this.totalPrizePool,
    };
  }
}
