import React from 'react';
import styled from 'styled-components';

import { Frame, Image } from './Avatar';

const SvgRoot = styled.svg`
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
  transform-origin: center center;
`;

const ImageCropper = styled.foreignObject`
  & > * {
    clip-path: url(#my-clip-path);
    position: absolute;
    display: flex;
  }

  ${Frame} {
    transform: scale(1);
  }

  ${Image} {
    position: absolute;
    transform: scale(0.666666);
  }
`;

interface RoundCropProps {
  insertItem: React.ReactNode;
  children: React.ReactNode;
}

export const RoundCrop = ({ insertItem, children, ...props }: RoundCropProps) => (
  <SvgRoot {...props}>
    <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
      <path d="M0,0 V1 H1 V0 M0.741,0.889 a0.148,0.148,0,1,1,0.148,-0.148 A0.148,0.148,0,0,1,0.741,0.889" />
    </clipPath>
    <ImageCropper y="0" x="0" width="100%" height="100%">
      {children}
    </ImageCropper>

    {insertItem}
  </SvgRoot>
);
