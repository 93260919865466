import React from 'react';
import styled, { css } from 'styled-components';

const BackgroundEl = styled.div<{ $imageUrl: string }>`
  position: absolute;
  top: 0px;
  left: 0;
  ${({ $imageUrl }) => `background-image: url(${$imageUrl});`}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  max-height: 1080px;
  width: 100%;
  height: 100%;
  :after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(10, 16, 35, 0) 0%, #15192c 81.67%);
  }
`;

interface Props {
  imageUrl: string;
}

const PageBackground: React.FC<Props> = (props) => {
  const { imageUrl, ...rest } = props;
  return <BackgroundEl $imageUrl={imageUrl} {...rest} />;
};

export default PageBackground;
