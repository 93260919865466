import ExperienceViewModel from './ExperienceViewModel';
import TeamViewModel from './team/TeamViewModel';
import { AvatarFrame, ProfileType } from '@/common/types';

import {
  ExperienceMemberGamingAccount,
  ExperienceMemberRole,
  FirestoreExperienceMember,
  MemberPermission,
  MemberRole,
} from './types';

export default class ExperienceMemberViewModel {
  readonly id: string;

  readonly type: ProfileType;

  readonly title?: string;

  readonly avatarURL?: string;

  readonly bannerURL?: string;

  readonly avatarFrame: AvatarFrame;

  readonly username: string;

  readonly tag: string;

  readonly bio?: string;

  readonly rank: number;

  readonly roles: ExperienceMemberRole[];

  readonly gamingAccount?: ExperienceMemberGamingAccount;

  constructor(props: {
    id: string;
    type: ProfileType;
    title?: string;
    avatarURL?: string;
    bannerURL?: string;
    avatarFrame: AvatarFrame;
    username: string;
    tag: string;
    bio?: string;
    rank: number;
    roles: ExperienceMemberRole[];
    gamingAccount?: ExperienceMemberGamingAccount;
  }) {
    this.id = props.id;
    this.type = props.type;
    this.title = props.title;
    this.avatarURL = props.avatarURL;
    this.bannerURL = props.bannerURL;
    this.avatarFrame = props.avatarFrame;
    this.username = props.username;
    this.tag = props.tag;
    this.bio = props.bio;
    this.rank = props.rank;
    this.roles = props.roles;
    this.gamingAccount = props.gamingAccount;
  }

  static fromFirestore(data: FirestoreExperienceMember): ExperienceMemberViewModel {
    return new ExperienceMemberViewModel({
      id: data.id,
      type: ProfileType.Pro,
      title: data.title ? data.title : undefined,
      avatarURL: data.avatar_url ? data.avatar_url : undefined,
      bannerURL: data.banner_url ? data.banner_url : undefined,
      avatarFrame: AvatarFrame.None,
      username: data.username,
      tag: data.tag,
      rank: 0,
      roles: data.roles,
      gamingAccount: data.gaming_account,
    });
  }

  get permissions() {
    return this.roles.reduce<number>((sum, role) => sum | role.permissions, 0);
  }

  get isOwner(): boolean {
    return this.hasRole(MemberRole.Owner);
  }

  get isLeader(): boolean {
    return this.hasRole(MemberRole.Leader);
  }

  get isBeneficiary(): boolean {
    return this.hasRole(MemberRole.Beneficiary);
  }

  hasPermission(permission: MemberPermission): boolean {
    return Boolean(this.permissions & permission);
  }

  hasRole(rl: MemberRole): boolean {
    return this.roles.some((role) => role.id === rl);
  }

  getTeam(experience: ExperienceViewModel): TeamViewModel | undefined {
    return experience.findTeamByMemberId(this.id);
  }
}
