import ReadyCheckParticipantViewModel from './ReadyCheckParticipant';
import { ReadyCheckStatus, FirestoreReadyCheck } from './types';

export default class ReadyCheckViewModel {
  readonly id: string;

  readonly status: ReadyCheckStatus;

  readonly finishAt: Date;

  readonly team: string;

  readonly participants: ReadyCheckParticipantViewModel[];

  constructor(props: {
    id: string;
    status: ReadyCheckStatus;
    finishAt: Date;
    team: string;
    participants: ReadyCheckParticipantViewModel[];
  }) {
    this.id = props.id;
    this.status = props.status;
    this.finishAt = props.finishAt;
    this.participants = props.participants;
    this.team = props.team;
  }

  static fromFirestore(data: FirestoreReadyCheck): ReadyCheckViewModel {
    return new ReadyCheckViewModel({
      id: data.id,
      status: data.status,
      finishAt: data.finish_at.toDate(),
      team: data.team,
      participants: data.participants.map(
        (p) =>
          new ReadyCheckParticipantViewModel({
            member: p.member,
            status: p.status,
            updatedAt: p.updated_at.toDate(),
          }),
      ),
    });
  }

  get isSuccess() {
    return this.status === ReadyCheckStatus.Success;
  }

  get isActive() {
    return this.status === ReadyCheckStatus.Active;
  }

  get isFailed() {
    return this.status === ReadyCheckStatus.Failure;
  }

  findParticipantById(id?: string) {
    return this.participants.find((p) => p.member === id);
  }
}
