import { navigate } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';

import getExperiencePagePath from '@/paths/getExperiencePagePath';
import { NotificationType } from '../../types';
import NotificationViewModel from '../model/NotificationViewModel';
import { SlotWinNotificationView } from '../Views';

import NotificationConsumer from './NotificationConsumer';

class SlotWinNotificationConsumer implements NotificationConsumer {
  type = NotificationType.SlotWin;

  onClick = (notification: NotificationViewModel) => {
    navigate(getExperiencePagePath(notification.experience!.id));
  };

  perform(notification: NotificationViewModel, read: () => void) {
    toast.info(this.render(notification), {
      onClick: () => {
        this.onClick(notification);
        read();
      },
      toastId: notification.id,
      hideProgressBar: true,
    });
  }

  render(notification: NotificationViewModel) {
    return <SlotWinNotificationView notification={notification} />;
  }
}

export default SlotWinNotificationConsumer;
