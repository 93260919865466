import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import useElementSize from '../helpers/useElementSize';
import { Z_INDEX_1 } from '../styleConstants';

const avatarPlaceholder = new URL('./placeholder.png', import.meta.url).href;

const Blurs = [
  new URL('./blurs/Blur-1.jpg', import.meta.url).href,
  new URL('./blurs/Blur-2.jpg', import.meta.url).href,
  new URL('./blurs/Blur-3.jpg', import.meta.url).href,
  new URL('./blurs/Blur-4.jpg', import.meta.url).href,
  new URL('./blurs/Blur-5.jpg', import.meta.url).href,
  new URL('./blurs/Blur-6.jpg', import.meta.url).href,
];

const ASPECT_RATIO = 9 / 16;

interface PartyCardProps {
  background?: string;
  avatar?: string;
  inactive?: boolean;
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.1);
`;

const CardRatio = styled.div`
  position: relative;
  padding-bottom: ${ASPECT_RATIO * 100}%;
  height: 0;
  width: 100%;
`;

const CardRoot = styled.div<{ $background: string }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ $background }) => `url(${$background})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  box-shadow: 0px 1px 5px #0a1023;
`;

const AvatarsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Avatar = styled.img<{ $withBackground: boolean; $opacity?: number }>`
  position: absolute;
  object-fit: cover;
  background-color: ${({ $withBackground }) => ($withBackground ? 'black' : null)};
  border-radius: 50%;
  opacity: ${({ $opacity }) => $opacity || 1};

  &:nth-child(1) {
    height: calc(100% * 1.25);
  }
  &:nth-child(2) {
    height: calc(100% * 1.1);
  }
  &:nth-child(3) {
    height: calc(100% * 0.95);
  }
`;

const InactiveCover = styled.div`
  background: rgba(53, 62, 92, 50%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const PartyCard = React.memo(
  ({ avatar, background, inactive, ...props }: PartyCardProps) => {
    const playerAvatar = avatar || avatarPlaceholder;
    const playerBackground = useMemo(
      () => background || Blurs[Math.floor(Math.random() * Blurs.length)],
      [],
    );

    const firstAvatar = useRef(null);
    const secondAvatar = useRef(null);
    const thirdAvatar = useRef(null);

    const { height: firstHeight } = useElementSize(firstAvatar);
    const { height: secondHeight } = useElementSize(secondAvatar);
    const { height: thirdHeight } = useElementSize(thirdAvatar);

    return (
      <>
        <Container {...props}>
          <CardRatio>
            <CardRoot $background={playerBackground}>
              <AvatarsWrapper>
                <Avatar
                  ref={thirdAvatar}
                  style={{ width: thirdHeight }}
                  src={playerAvatar}
                  $opacity={0.05}
                  $withBackground={!avatar}
                />
                <Avatar
                  ref={secondAvatar}
                  style={{ width: secondHeight }}
                  src={playerAvatar}
                  $opacity={0.2}
                  $withBackground={!avatar}
                />
                <Avatar
                  ref={firstAvatar}
                  style={{ width: firstHeight }}
                  src={playerAvatar}
                  $withBackground={!avatar}
                />
              </AvatarsWrapper>
            </CardRoot>
          </CardRatio>
        </Container>
        {inactive && <InactiveCover />}
      </>
    );
  },
);
