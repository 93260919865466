import styled from 'styled-components';

import Typography from '@/components/Typography';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '@/common/ui';

const Link = styled(Typography)`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 14px;
  color: #30adf9;
`;

export default Link;
