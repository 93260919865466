import { rgba } from 'polished';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Z_INDEX_1 } from './styleConstants';

const ButtonWrapper = styled.div<
  Partial<{
    $radius: number;
    $height: number;
    $outlineLines: number;
    $disabled: boolean;
    $borderColor: string;
  }>
>`
  width: max-content;
  display: flex;
  position: relative;
  height: ${({ $height }) => ($height ? `${$height}px` : '40px')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'initial')};

  & button,
  a {
    z-index: ${Z_INDEX_1};
    height: ${({ $height }) => ($height ? `${$height}px` : '40px')};
    border-radius: ${({ $radius }) => ($radius ? `${$radius}px` : '20px')};
  }

  & span {
    box-sizing: border-box;
    opacity: ${({ $disabled }) => ($disabled ? '20%' : 'inherit')};

    &:nth-child(1) {
      border-radius: ${({ $radius }) => ($radius ? `${$radius + 5}px` : '25px')};
    }

    ${({ $outlineLines, $radius, $height }) => {
      if ($outlineLines === 2) {
        return css`
          top: -5px;
          left: -5px;
          bottom: -5px;
          right: -5px;

          &:nth-child(1) {
            height: ${$height ? `${$height + 10}px` : '50px'};
          }

          &:nth-child(2) {
            border-radius: ${$radius ? `${$radius + 10}px` : '30px'};
            height: ${$height ? `${$height + 10}px` : '50px'};
          }
        `;
      }
      return null;
    }}
  }

  &:hover {
    & span {
      &:nth-child(1) {
        border-color: ${({ $borderColor }) =>
          $borderColor ? `${rgba(`${$borderColor}`, 0.8)}` : 'rgba(240, 244, 248, 80%)'};
        height: ${({ $height }) => ($height ? `${$height + 10}px` : '50px')};
        top: -5px;
        left: -5px;
        bottom: -5px;
        right: -5px;
      }

      &:nth-child(2) {
        border-color: ${({ $borderColor }) =>
          $borderColor ? `${rgba(`${$borderColor}`, 0.35)}` : 'rgba(240, 244, 248, 35%)'};
        height: ${({ $height }) => ($height ? `${$height + 20}px` : '60px')};
        top: -10px;
        left: -10px;
        bottom: -10px;
        right: -10px;
      }
    }
  }
`;

const FirstButtonBorder = styled.span<Partial<{ $borderColor: string }>>`
  border: ${({ $borderColor }) =>
    $borderColor
      ? `2px solid ${rgba(`${$borderColor}`, 0.65)}`
      : '2px solid rgba(240, 244, 248, 65%)'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: inherit;
  transition: all 200ms ease-in-out;
`;

const SecondButtonBorder = styled(FirstButtonBorder)`
  border-color: ${({ $borderColor }) =>
    $borderColor ? `${rgba(`${$borderColor}`, 0.2)}` : 'rgba(240, 244, 248, 20%)'};
`;

export interface IButtonOutlineProps {
  radius?: number;
  height?: number;
  outlineLines?: number;
  disabled?: boolean;
  borderColor?: string;
  children: ReactNode;
}

export const ButtonOutline = ({
  radius,
  height,
  outlineLines = 0,
  disabled,
  children,
  borderColor,
  ...props
}: IButtonOutlineProps) => (
  <ButtonWrapper
    $radius={radius}
    $height={height}
    $outlineLines={outlineLines}
    $disabled={disabled}
    $borderColor={borderColor}
    {...props}
  >
    {outlineLines >= 1 && <FirstButtonBorder $borderColor={borderColor} />}
    {outlineLines > 1 && <SecondButtonBorder $borderColor={borderColor} />}
    {children}
  </ButtonWrapper>
);
