import styled from 'styled-components';
import { unstable_batchedUpdates } from 'react-dom';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LocaleAndCurrencyContext from '../common/LocaleAndCurrencyContext';
import { PaymentMethod } from '@/common/types';
import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from '@/common/ui';

import PrivacyPolicyPdf from '@/assets/pdf/privacy-policy.pdf';
import TermsOfServicePdf from '@/assets/pdf/terms-of-service.pdf';

import BackSvg from './back.svg';
import getRedirectToPaymentProviderPagePath from './getRedirectToPaymentProviderPagePath';
import PaymentMethodComponent from './PaymentMethod';
import sendWalletAnalyticsStep from './sendWalletAnalyticsStep';
import WalletAnalyticsStep from './WalletAnalyticsStep';
import WalletStep from './WalletStep';
import WalletStepProps from './WalletStepProps';

const Container = styled.div`
  padding-bottom: 10px;
`;

const Header = styled.div``;

const BackButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 16px 20px;
`;

const BackIcon = styled(BackSvg)`
  fill: #fff;
  height: 18px;
`;

const BackButtonText = styled.span`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.01em;
  line-height: 24px;
  margin-left: 12px;
`;

const Content = styled.div`
  background: #2b2660;
  padding: 15px 0;
`;

const Summary = styled.div`
  padding: 0 20px;
`;

const SummaryHeading = styled.div`
  padding-bottom: 15px;
  color: rgba(211, 206, 239, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 20px;
  border-bottom: 1px dotted rgba(211, 206, 239, 0.8);
`;

const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const SummaryItem = styled.div``;

const SummaryItemTitle = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.01em;
  line-height: 22px;
`;
const SummaryItemDesc = styled.div`
  color: rgba(211, 206, 239, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

const SummaryTotal = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 31px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.01em;
  line-height: 22px;
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

const Heading = styled.h3`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin: 40px 20px 0;
`;

const PaymentMethodItems = styled.div`
  padding: 10px 20px;
`;

const PaymentMethodWrapper = styled.div`
  margin-bottom: 10px;
`;

const Hint = styled.p`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  margin: 0 20px;

  a {
    color: rgba(211, 206, 239, 0.5);

    &:focus:not(:focus-visible),
    &:hover {
      color: rgba(211, 206, 239, 0.6);
    }
  }
`;

const WalletPaymentMethodStep = ({ setStep, pack, setPaymentMethod, setPaymentPopup }: WalletStepProps) => {
  const { t } = useTranslation('wallet');
  const { currency } = useContext(LocaleAndCurrencyContext);
  const createPaymentMethodHandler = (paymentMethod: PaymentMethod) => () => {
    unstable_batchedUpdates(() => {
      setPaymentMethod(paymentMethod);
      setStep(WalletStep.Payment);
      setPaymentPopup(window.open(getRedirectToPaymentProviderPagePath(), '_blank'));
    });
  };
  useEffect(() => {
    sendWalletAnalyticsStep(WalletAnalyticsStep.PaymentMethod, { pack });
  }, []);
  return (
    <Container>
      <Header>
        <BackButton
          type="button"
          onClick={() => {
            setStep(WalletStep.Packages);
          }}
        >
          <BackIcon />
          <BackButtonText>
            {t('paymentMethodStep.backButtonText', {
              bundle: pack!.size,
              currency,
              price: pack!.price,
            })}
          </BackButtonText>
        </BackButton>
      </Header>
      <Content>
        <Summary>
          <SummaryHeading>{t('paymentMethodStep.summary.heading')}</SummaryHeading>
          <SummaryRow>
            <SummaryItem>
              <SummaryItemTitle>{t('paymentMethodStep.summary.item', { size: pack!.size })}</SummaryItemTitle>
              <SummaryItemDesc>{t('paymentMethodStep.summary.desc', { date: new Date() })}</SummaryItemDesc>
            </SummaryItem>
            <SummaryTotal>
              {t('bundlesStep.price', {
                currency,
                price: pack?.price,
              })}
            </SummaryTotal>
          </SummaryRow>
        </Summary>
        <Heading>{t('paymentMethodStep.heading')}</Heading>
        <PaymentMethodItems>
          <PaymentMethodWrapper>
            <PaymentMethodComponent
              paymentMethod={PaymentMethod.Stripe}
              onClick={createPaymentMethodHandler(PaymentMethod.Stripe)}
            />
          </PaymentMethodWrapper>
          <PaymentMethodWrapper>
            <PaymentMethodComponent
              paymentMethod={PaymentMethod.PayPal}
              onClick={createPaymentMethodHandler(PaymentMethod.PayPal)}
            />
          </PaymentMethodWrapper>
          <PaymentMethodWrapper>
            <PaymentMethodComponent
              paymentMethod={PaymentMethod.SkinsCash}
              onClick={createPaymentMethodHandler(PaymentMethod.SkinsCash)}
            />
          </PaymentMethodWrapper>
        </PaymentMethodItems>
        <Hint>
          <Trans t={t} i18nKey="paymentMethodStep.hint">
            By clicking on a payment method, you agree to our{' '}
            <a href={TermsOfServicePdf} target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            and acknowledge our{' '}
            <a href={PrivacyPolicyPdf} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            applies. If you purchase F’s, you agree that we may start to deliver the order and cannot cancel your order
            once delivery has begun.
          </Trans>
        </Hint>
      </Content>
    </Container>
  );
};

export default WalletPaymentMethodStep;
