import { getPlainLogInPath } from './getSignInPath';
import { getPlainSignUpPath } from './getSignUpPath';

export const getPlainForgotPasswordPath = () => '/forgot-password';

const getForgotPasswordPath = (): string => {
  const { hash, pathname, search } = window.location;
  const redirectURL = `${pathname}${search}${hash}`;
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('redirectURL')) {
    return `${getPlainForgotPasswordPath()}?${searchParams.toString()}`;
  }

  if (!['/', getPlainLogInPath(), getPlainSignUpPath(), getPlainForgotPasswordPath()].includes(pathname)) {
    searchParams.append('redirectURL', redirectURL);
  }
  return `${getPlainForgotPasswordPath()}${
    searchParams.toString() ? `?${searchParams.toString()}` : searchParams.toString()
  }`;
};

export default getForgotPasswordPath;
