import React from 'react';
import styled, { css } from 'styled-components';

import FailedSvg from './failed.svg';
import SuccessSvg from './success.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 140px;
`;

const LeftTeamWrapper = styled(TeamWrapper)`
  justify-content: flex-end;
`;

const Status = css`
  border-radius: 50%;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  margin-left: 10px;

  &:nth-child(${5}n + 1) {
    margin-left: 0;
  }

  &:nth-child(n + 6) {
    margin-top: 5px;
  }
`;

const FailedStatus = styled.div`
  ${Status}
  background-color: #e91e63;

  svg {
    fill: #0a1023;
  }
`;

const AcceptedStatus = styled.div`
  ${Status}
  background-color: transparent;
  fill: rgba(211, 206, 239, 20%);
`;

const Failed = styled.div`
  border-radius: 50px;
  width: 100px;
  height: 100px;
  border: 4px solid #e91e63;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
`;

const FailedIcon = styled(FailedSvg)`
  fill: #e91e63;
  width: 70px;
  height: 70px;
`;

interface Team {
  [key: string]: boolean | undefined;
}

interface ReadyCheckFailedProps {
  leftTeam: Team;
  rightTeam: Team;
}

export const ReadyCheckFailed = ({ leftTeam, rightTeam, ...props }: ReadyCheckFailedProps) => (
  <Wrapper {...props}>
    <LeftTeamWrapper>
      {Object.keys(leftTeam).map((playerId) =>
        leftTeam[playerId] ? (
          <AcceptedStatus key={playerId}>
            <SuccessSvg />
          </AcceptedStatus>
        ) : (
          <FailedStatus key={playerId}>
            <FailedSvg />
          </FailedStatus>
        ),
      )}
    </LeftTeamWrapper>

    <Failed>
      <FailedIcon />
    </Failed>

    <TeamWrapper>
      {Object.keys(rightTeam).map((playerId) =>
        rightTeam[playerId] ? (
          <AcceptedStatus key={playerId}>
            <SuccessSvg />
          </AcceptedStatus>
        ) : (
          <FailedStatus key={playerId}>
            <FailedSvg />
          </FailedStatus>
        ),
      )}
    </TeamWrapper>
  </Wrapper>
);
