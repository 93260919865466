import { createContext } from 'react';

export type ServiceWorkerContextType = {
  isUpdateAvailable: boolean;
  isUpdating: boolean;
  update: () => void;
  checkForUpdate: () => void;
  version: string;
  newVersion?: string;
  isInstalled: boolean;
};

export const ServiceWorkerContext = createContext<ServiceWorkerContextType>({
  isUpdateAvailable: false,
  isUpdating: false,
  update: () => {},
  checkForUpdate: () => {},
  version: '0.0.0',
  isInstalled: false,
});
