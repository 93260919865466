import React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { CardBackground } from './components';
import CollectionViewModel from '@/models/CollectionViewModel';
import { Col, Row, Typography } from '@/components';
import { Icon, IconNew, IconSoon } from '@/components/icons';

const Container = styled.div`
  padding: 20px;
  width: 310px;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  ${(props) => {
    let background = '#15192d';
    switch (props?.id) {
      case 'csgo-showmatch':
        background = 'linear-gradient(100deg, #3959C8 0%, #BF2FF1 120%);';
        break;
      case 'csgo-casual':
        background = 'linear-gradient(100deg, #F47B67 0%, #BA3A3A 120%);';
        break;
      case 'csgo-wingman':
        background = 'linear-gradient(100deg, #0858A2 0%, #05B9C8 120%);';
        break;
      case 'csgo-duel':
        background = 'linear-gradient(100deg, #4340C6 0%, #404395 120%);';
        break;
      case 'csgo-treasure-hunt':
        background = 'linear-gradient(100deg, #4143A3 0%, #35B899 120%);';
        break;
      case 'warzone-party':
        background = 'linear-gradient(100deg, #2A163E 0%, #175B99 120%);';
        break;
      case 'apex-legends-party':
        background = 'linear-gradient(100deg, #15192E 0%, #15192E 120%);';
        break;
    }

    return css`
      background: ${background};
    `;
  }}
`;

type Props = CollectionViewModel;

const CollectionCard: React.FC<Props> = (props) => {
  const { t } = useTranslation('collection');
  const { id, name, new: isNew, soon: isSoon } = props;

  return (
    <Container id={id}>
      <Row gutter={10} style={{ marginBottom: 10 }}>
        <CardBackground src={t(`${name}.logo.white`)} />
        <Icon src={t(`${name}.game`)} />
        {isNew && <IconNew />}
        {isSoon && <IconSoon />}
      </Row>
      <Col>
        <Typography.Title level={4} >
          {t(`${name}.heading`)}
        </Typography.Title>
        <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }}>{t(`${name}.description`)}</Typography>
      </Col>
    </Container>
  );
};

export default CollectionCard;
