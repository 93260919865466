import { Timestamp } from 'firebase/firestore';

export enum ReadyCheckStatus {
  Active = 'active',
  Success = 'success',
  Failure = 'failure',
}

export enum ReadyCheckParticipantStatus {
  Pending = 'pending',
  Ready = 'ready',
  NotReady = 'not_ready',
}

export interface FirestoreReadyCheck {
  id: string;
  status: ReadyCheckStatus;
  team: string;
  finish_at: Timestamp;
  participants: {
    status: ReadyCheckParticipantStatus;
    member: string;
    updated_at: Timestamp;
  }[];
}
