import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultErrorView from '../DefaultErrorView';

const UnknownErrorPage = () => {
  const { t } = useTranslation('errors');
  return (
    <DefaultErrorView
      heading={t(`unknownErrorPage.heading`)}
      description={t('unknownErrorPage.errorCodes.unknownError')}
    />
  );
};

export default UnknownErrorPage;
