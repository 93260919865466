import styled, { css } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from './styleConstants';

export const Filters = styled.div<{ $disabled: boolean }>`
  align-items: center;
  background: rgba(10, 16, 35, 0.5);
  border-radius: 15px;
  display: flex;
  height: 30px;
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;

interface FilterButtonProps {
  $active: boolean;
}

export const FilterButton = styled.button<FilterButtonProps>`
  align-items: center;
  background: ${({ $active }) => ($active ? 'rgba(211, 206, 239, 0.8)' : 'none')};
  border: none;
  border-radius: 15px;
  color: ${({ $active }) => ($active ? 'rgba(10, 16, 35, 1)' : 'rgba(211, 206, 239, 0.5)')};
  cursor: pointer;
  display: inline-flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 15px;
  height: 100%;
  justify-content: center;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0 20px;
  text-decoration: none;

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        color: rgba(211, 206, 239, 0.8);
      }
    `}
`;
