import React, { ReactNode } from 'react';
import styled from 'styled-components';

import UserPlaceholder from './user-placeholder.png';

const ASPECT_RATIO = 9 / 16;

const Container = styled.div`
  overflow: hidden;
  border-radius: 5px;
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.1);
`;

const BannerRatio = styled.div`
  position: relative;
  padding-bottom: ${ASPECT_RATIO * 100}%;
  height: 0;
  width: 100%;
`;

const BannerRoot = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const AvatarContainer = styled.div`
  display: flex;
  width: 55%;
  flex-shrink: 0;
`;

const FullAvatar = styled(AvatarContainer)`
  width: 100%;
`;

const AvatarImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ChallengerAvatar = styled(AvatarContainer)``;

const RivalAvatar = styled(AvatarContainer)`
  width: 56%;
  margin-left: -10%;
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    rgba(0, 0, 0, 0.2) 4.5%,
    rgba(0, 0, 0, 0.8) 13.5%,
    rgba(0, 0, 0, 1) 18%
  );
`;

const BannerCover = styled.div`
  background: rgba(53, 62, 92, 50%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

interface ExperienceBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  leftImage?: string;
  rightImage?: string | null;
  leftChild?: ReactNode;
  rightChild?: ReactNode;
  inactive?: boolean;
}

const ExperienceBanner = ({
  leftImage = UserPlaceholder,
  rightImage,
  leftChild,
  rightChild,
  inactive,
  ...props
}: ExperienceBannerProps) => {
  return (
    <>
      <Container {...props}>
        <BannerRatio>
          <BannerRoot>
            {rightChild || rightImage !== null ? (
              <>
                <ChallengerAvatar>{leftChild || <AvatarImg src={leftImage} />}</ChallengerAvatar>
                <RivalAvatar>
                  {rightChild || <AvatarImg src={rightImage || UserPlaceholder} />}
                </RivalAvatar>
              </>
            ) : (
              <FullAvatar>{leftChild || <AvatarImg src={leftImage} />}</FullAvatar>
            )}
          </BannerRoot>
        </BannerRatio>
      </Container>
      {inactive && <BannerCover />}
    </>
  );
};

export default ExperienceBanner;
