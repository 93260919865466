import { FirestoreDataConverter, QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import NotificationViewModel from './NotificationViewModel';
import NotificationExperienceViewModel, {
  NotificationExperience,
} from './NotificationExperienceViewModel';
import { NotificationStatus, NotificationType } from '../../types';

interface SerializedNotificationModel {
  id: string;
  recipient: string;
  status: string;
  type: string;
  experience?: NotificationExperience;
  entries?: number;
  created_at: Timestamp;
  read_at?: Timestamp;
}

const NotificationDataConverter: FirestoreDataConverter<NotificationViewModel> = {
  fromFirestore(
    snapshot: QueryDocumentSnapshot<SerializedNotificationModel>,
  ): NotificationViewModel {
    const data = snapshot.data();

    return {
      id: data.id,
      recipient: data.recipient,
      status: data.status as NotificationStatus,
      type: data.type as NotificationType,
      experience: data.experience
        ? NotificationExperienceViewModel.fromFirestore(data.experience)
        : undefined,
      entries: data.entries,
      createdAt: data.created_at.toDate(),
      readAt: data.read_at ? data.read_at.toDate() : undefined,
    };
  },

  toFirestore(): {} {
    return {};
  },
};

export default NotificationDataConverter;
