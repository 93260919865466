enum WalletTransactionType {
  StripePayment = 'stripePayment',
  PayPalPayment = 'payPalPayment',
  SkinsCashPayment = 'skinsCashPayment',
  WolfredGenerosity = 'wolfredGenerosity',
  ExperienceRefund = 'experienceRefund',
  ExperiencePayment = 'experiencePayment',
  ExperienceReward = 'experienceReward',
  EarlyBirdReward = 'earlyBirdReward',
  WithdrawalPreAuthorization = 'withdrawalPreAuthorization',
  WithdrawalCapture = 'withdrawalCapture',
  WithdrawalVoid = 'withdrawalVoid',
}

export default WalletTransactionType;
