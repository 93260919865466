import { BalanceType, PrivateProfile } from './types';
import * as Sentry from '@sentry/react';
import { UserTelemetry } from '../telemetry/types';

interface Hotjar {
  (...args: any[]): void;
  q: any[];
}

interface Intercom {
  (...args: any[]): void;
  q: any[];
}

declare global {
  interface Window {
    dataLayer: any[];
    hj: Hotjar;
    intercomSettings: any;
    Intercom: Intercom;
  }
}

window.dataLayer = window.dataLayer || [];
window.hj =
  window.hj ||
  function hotjar(...args: any[]) {
    window.hj.q = window.hj.q || [];
    window.hj.q.push(args);
  };
window.Intercom =
  window.Intercom ||
  function intercom(...args: any[]) {
    window.Intercom.q = window.Intercom.q || [];
    window.Intercom.q.push(args);
  };
window.intercomSettings = window.intercomSettings || {
  horizontal_padding: 20,
  vertical_padding: 125,
};

class UserTracking {
  private user?: PrivateProfile;

  login(user: PrivateProfile) {
    this.user = user;

    window.hj('identify', user.id, {
      email: this.user.email || '',
      balance: this.user.balance[BalanceType.F] || 0,
      locale: this.user.locale,
    });

    window.intercomSettings.user_id = this.user.id;
    window.intercomSettings.email = this.user.email || '';
    window.intercomSettings.username = this.user.username;
    window.intercomSettings.profile_type = this.user.type;
    window.intercomSettings.locale = this.user.locale;
    window.intercomSettings.f_balance = this.user.balance[BalanceType.F] || 0;
    window.intercomSettings.discord = this.user.discord !== null;
    window.intercomSettings.joined_discord = this.user.discord !== null && this.user.discord.joinedServer;
    window.intercomSettings.steam = this.user.steam !== null;
    window.intercomSettings.twitch = this.user.twitch !== null;
    window.Intercom('update', window.intercomSettings);

    Sentry.setUser({
      id: this.user.id,
      username: this.user.username,
      email: this.user.email,
      locale: this.user.locale,
    });

    this.event('login', { user: this.user.id });
  }

  updateTelemetry(telemetry: UserTelemetry) {
    if (!this.user) return;

    const data: any = {
      email: this.user.email || '',
      balance: this.user.balance[BalanceType.F] || 0,
      locale: this.user.locale,
    };

    if (telemetry.last_finished_experience) {
      data.last_finished_experience = telemetry.last_finished_experience.timestamp.toISOString();
    }

    if (telemetry.last_hosted_experience) {
      data.last_hosted_experience = telemetry.last_hosted_experience.timestamp.toISOString();
    }
    window.hj('identify', this.user.id, data);
  }

  logout() {
    if (!this.user) {
      throw new Error();
    }
    this.event('logout', { user: this.user.id });

    this.user = undefined;

    window.hj('identify', null);

    delete window.intercomSettings.user_id;
    delete window.intercomSettings.email;
    delete window.intercomSettings.username;
    delete window.intercomSettings.profile_type;
    delete window.intercomSettings.locale;
    delete window.intercomSettings.f_balance;
    delete window.intercomSettings.discord;
    delete window.intercomSettings.joined_discord;
    delete window.intercomSettings.steam;
    delete window.intercomSettings.twitch;
    window.Intercom('update', window.intercomSettings);

    Sentry.configureScope((scope) => scope.setUser(null));
  }

  event(name: string, details?: object) {
    window.dataLayer.push({
      event: name,
      _userId: this.user?.id,
      _userCurrency: this.user?.currency,
      _userLocale: this.user?.locale,
      ...details,
    });
    window.hj('event', name, {
      _userId: this.user?.id,
      _userCurrency: this.user?.currency,
      _userLocale: this.user?.locale,
      ...details,
    });
    window.Intercom('trackEvent', name, {
      _userId: this.user?.id,
      _userCurrency: this.user?.currency,
      _userLocale: this.user?.locale,
      ...details,
    });
  }
}

export default new UserTracking();
