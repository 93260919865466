enum GameServerStatus {
  ToBeAllocated = 'toBeAllocated',
  Allocated = 'allocated',
  AllocationFailed = 'allocationFailed',
  ToBeDeallocated = 'toBeDeallocated',
  Deallocated = 'deallocated',
  DeallocationFailed = 'deallocationFailed',
}

export default GameServerStatus;
