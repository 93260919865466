import { Currency, Locale } from '../types';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LocaleAndCurrencyContext from '../LocaleAndCurrencyContext';
import Tooltip from '../Tooltip';

import CheckSvg from './check.svg';
import Dropdown, { DropdownGroup, DropdownItem, ItemIcon, ItemText } from './Dropdown';
import GlobeSvg from './globe.svg';
import MenuButton from './MenuButton';
import useDropdown from './useDropdown';

const DarkDropdownGroup = styled(DropdownGroup)`
  background: rgba(43, 38, 96, 1);
`;

const ItemIconToggled = styled(ItemIcon)<{ $active?: boolean }>`
  opacity: ${({ $active }) => ($active ? '1' : '0')};
`;

const LangDropdown = styled(Dropdown)`
  left: 0;
  width: 220px;
`;

const HeadingText = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  flex-grow: 1;
  text-align: center;
`;

const Heading = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: flex;
  height: 50px;
  padding: 0 14px;
  width: 100%;
  text-align: center;
`;

const LanguagesDropdown = () => {
  const { t } = useTranslation('header');
  const { triggerRef, dropdownRef, isShown, toggleShown } = useDropdown();
  const { locale, setLocale, currency, setCurrency } = useContext(LocaleAndCurrencyContext);

  const onLanguageSwitch = (lcl: Locale) => () => {
    setLocale(lcl);
  };

  const onCurrencySwitch = (crr: Currency) => () => {
    setCurrency(crr);
  };

  return (
    <>
      <Tooltip
        content={t('languagesDropdown.tooltip')}
        strategy="fixed"
        offset="12"
        ref={triggerRef}
        disabled={isShown}
      >
        <MenuButton type="button" active={isShown} onClick={toggleShown}>
          <GlobeSvg />
        </MenuButton>
      </Tooltip>
      {isShown && (
        <LangDropdown ref={dropdownRef}>
          <Heading>
            <HeadingText>{t('languagesDropdown.language')}</HeadingText>
          </Heading>
          <DarkDropdownGroup>
            <DropdownItem onClick={onLanguageSwitch(Locale.English)}>
              <ItemIconToggled as={CheckSvg} $active={locale === Locale.English} />
              <ItemText>English</ItemText>
            </DropdownItem>
          </DarkDropdownGroup>
          <Heading>
            <HeadingText>{t('languagesDropdown.currency')}</HeadingText>
          </Heading>
          <DarkDropdownGroup>
            <DropdownItem onClick={onCurrencySwitch(Currency.USD)}>
              <ItemIconToggled as={CheckSvg} $active={currency === Currency.USD} />
              <ItemText>USD</ItemText>
            </DropdownItem>
            <DropdownItem onClick={onCurrencySwitch(Currency.EUR)}>
              <ItemIconToggled as={CheckSvg} $active={currency === Currency.EUR} />
              <ItemText>EUR</ItemText>
            </DropdownItem>
          </DarkDropdownGroup>
        </LangDropdown>
      )}
    </>
  );
};

export default LanguagesDropdown;
