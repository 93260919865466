import styled from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from './ui';

const PrimaryHeading = styled.h1`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 28px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  line-height: 36px;
`;

export default PrimaryHeading;
