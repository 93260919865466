import { FirestoreExperienceServer } from './types';

export default class ExperienceServerViewModel {
  readonly loading: boolean;
  readonly serverHost?: string;
  readonly gameLink?: string;
  readonly gameEndsAt?: Date;
  readonly waitForPlayersUntil?: Date;
  readonly metadata?: Object;

  constructor(props: {
    loading: boolean;
    serverHost?: string;
    gameLink?: string;
    gameEndsAt?: Date;
    waitForPlayersUntil?: Date;
    metadata?: Object;
  }) {
    this.loading = props.loading;
    this.serverHost = props.serverHost;
    this.gameLink = props.gameLink;
    this.gameEndsAt = props.gameEndsAt;
    this.waitForPlayersUntil = props.waitForPlayersUntil;
    this.metadata = props.metadata;
  }

  static fromFirestore(data: FirestoreExperienceServer): ExperienceServerViewModel {
    return new ExperienceServerViewModel({
      loading: data.loading,
      serverHost: data.server_host,
      gameLink: data.game_link,
      gameEndsAt: data.game_ends_at?.toDate(),
      waitForPlayersUntil: data.wait_for_players_until?.toDate(),
      metadata: data.metadata,
    });
  }
}
