import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { firestore } from '../common/firebaseClient';
import useFirestoreDoc from '../common/useFirestoreDocSnapshot';
import UserTelemetryDataConverter from './UserTelemetryDataConverter';

const useUserTelemetry = (user: string = '_') => {
  const ref = useMemo(
    () => doc(firestore, 'UserTelemetries', user).withConverter(UserTelemetryDataConverter),
    [user],
  );
  const { data, loadingState, error } = useFirestoreDoc(ref);

  return {
    telemetry: data,
    loadingState,
    error,
  };
};

export default useUserTelemetry;
