import React from 'react';
import styled from 'styled-components';

import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from './styleConstants';

interface TextareaProps {
  title?: string;
  value?: string;
}

const TextareaWrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 14px;
  color: rgba(211, 206, 239, 0.35);
  margin-bottom: 7px;
`;

const StyledTextarea = styled.textarea`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  background: rgba(10, 16, 35, 0.5);
  border: none;
  padding: 9px 15px;
  outline: none;
  resize: none;

  &:focus:not(:focus-visible),
  &:hover {
    box-shadow: 0px 0px 0px 2px rgba(211, 206, 239, 0.2);
  }
`;

export const TextArea = React.forwardRef(
  ({ title, ...props }: TextareaProps, ref?: React.Ref<any>) => (
    <TextareaWrapper htmlFor={title} ref={ref}>
      <Title>{title}</Title>
      <StyledTextarea id={title} {...props} />
    </TextareaWrapper>
  ),
);
