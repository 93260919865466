import { Currency, Locale } from './types';
import pDebounce from 'p-debounce';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';

import axiosClient from './axiosClient';
import LocaleAndCurrencyContext from './LocaleAndCurrencyContext';
import SessionContext from './SessionContext';
import usePrevious from './usePrevious';
import useUpdateLocaleAndCurrency from './useUpdateLocaleAndCurrency';

interface LocaleAndCurrencyContextProviderProps {
  children: ReactNode;
}

const updateLocaleInStorage = (locale: Locale) => {
  localStorage.setItem('gg_locale', locale);
};

const updateCurrencyInStorage = (currency: Currency) => {
  localStorage.setItem('gg_currency', currency);
};

const LocaleAndCurrencyContextProvider = ({ children }: LocaleAndCurrencyContextProviderProps) => {
  const { i18n } = useTranslation();

  const getCurrency = (): Currency => Currency.USD;

  const getLocale = (): Locale => i18n.language as Locale;

  const getLocaleFromStorage = (): Locale => {
    const storedLocale = localStorage.getItem('gg_locale');
    if (storedLocale) {
      const locale = storedLocale as Locale;

      if (locale !== getLocale()) {
        i18n.changeLanguage(locale);
      }

      return locale;
    }

    const locale = getLocale();
    localStorage.setItem('gg_locale', locale);

    return locale;
  };

  const getCurrencyFromStorage = (): Currency => {
    const storedCurrency = localStorage.getItem('gg_currency');
    if (storedCurrency) {
      return storedCurrency as Currency;
    }

    const currency = getCurrency();
    localStorage.setItem('gg_currency', currency);
    return currency;
  };

  const { user, privateProfile } = useContext(SessionContext);
  const [locale, setLocaleState] = useState<Locale>(getLocaleFromStorage);
  const [currency, setCurrencyState] = useState<Currency>(getCurrencyFromStorage);
  const prevLocale = usePrevious(locale);
  const prevCurrency = usePrevious(currency);
  const { updateLocaleAndCurrency, loadingState } = useUpdateLocaleAndCurrency(user?.uid);

  const setCurrency = useCallback(
    async (newCurrency: Currency) => {
      updateCurrencyInStorage(newCurrency);
      setCurrencyState(newCurrency);
      updateLocaleAndCurrency(locale, newCurrency);
    },
    [setCurrencyState, locale],
  );

  const setLocale = useCallback(
    async (newLocale: Locale) => {
      await i18n.changeLanguage(newLocale);
      updateLocaleInStorage(newLocale);
      setLocaleState(newLocale);
      updateLocaleAndCurrency(newLocale, currency);
    },
    [setLocaleState, currency],
  );

  useEffect(() => {
    if (privateProfile?.currency && privateProfile.currency !== currency) {
      updateCurrencyInStorage(privateProfile.currency);
      setCurrencyState(privateProfile.currency);
    }
  }, [privateProfile?.currency]);

  const contextValue = useMemo(
    () => ({
      currency,
      locale,
      setCurrency,
      setLocale,
    }),
    [currency, locale, setCurrency, setLocale],
  );

  return (
    <LocaleAndCurrencyContext.Provider value={contextValue}>
      {children}
    </LocaleAndCurrencyContext.Provider>
  );
};

export default LocaleAndCurrencyContextProvider;
