import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import Dropdown from './Dropdown';
import MenuButton from './MenuButton';
import SessionContext from '../SessionContext';
import Tooltip from '../Tooltip';
import useDropdown from './useDropdown';
import Wallet from '../../wallet/Wallet';
import { AmpliWrapper, Col } from '@/components';
import { BREAKPOINT_MEDIUM, Z_INDEX_1 } from '../ui';

const Balance = styled.span`
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.75px;
  white-space: pre;
`;

const StyledDropdown = styled(Dropdown)`
  width: 420px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: 420px;
    width: 100%;
    z-index: ${Z_INDEX_1};
  }
`;

const WalletDropdown = () => {
  const { t } = useTranslation('header');
  const { triggerRef, dropdownRef, isShown, toggleShown } = useDropdown();
  const { privateProfile } = useContext(SessionContext);
  if (!privateProfile) {
    return null;
  }
  return (
    <>
      <AmpliWrapper event="click_wallet_top" wrap>
        <Tooltip content={t('walletDropdown.tooltip')} strategy="fixed" offset="12" ref={triggerRef} disabled={isShown}>
          <MenuButton type="button" active={isShown} onClick={toggleShown}>
            <Balance>{`₪  ${privateProfile.balance.f} `}</Balance>
          </MenuButton>
        </Tooltip>
      </AmpliWrapper>
      {isShown && (
        <StyledDropdown ref={dropdownRef}>
          <Wallet />
        </StyledDropdown>
      )}
    </>
  );
};

export default WalletDropdown;
