enum ErrorCode {
  UserNotFound,
  InvalidEmail,
  WrongPassword,
  EmailAlreadyInUse,
  WeakPassword,
  PasswordsDontMatch,
  InvalidNickname,
  InvalidBio,
  InvalidMessage,
  ProfileNotFound,
  AlreadyHasProAccessRequest,
  AlreadyAProUser,
  InvalidExternalAuthenticationState,
  UserExistsWithDifferentCredential,
  UserAccountDisabled,
  MissingFields,
  InvalidExperienceType,
  UnknownExperienceType,
  NotAProUser,
  ExperienceNotFound,
  NotAnOwnerOfTheExperience,
  CantAcceptTheChallenge,
  InvalidWolfPackChallengeStatus,
  InvalidPricePerSlot,
  InvalidWinnerCoefficient,
  InvalidMap,
  InvalidServer,
  InvalidWolfPackChallengeStep2Id,
  InvalidWolfPackChallengeStep2Action,
  InvalidLimit,
  InvalidOffset,
  InvalidExperienceId,
  UserAlreadyParticipatesInTheExperience,
  NotEnoughMoney,
  InvalidTeam,
  TeamIsFull,
}

export default ErrorCode;
