import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../Tooltip';

type MessageAuthorColor = 'CurrentUser' | 'Pro' | 'Default' | 'Yellow';

export const ChatUserColor: Record<MessageAuthorColor, string> = {
  CurrentUser: '#1db553',
  Pro: '#00BCD4',
  Default: '#4c458e',
  Yellow: '#FBC02D',
};

const Author = styled.span`
  font-size: 16px;
`;

interface ChatUserProps {
  name: string;
  tooltipContent: React.ReactNode;
  interactiveTooltip: boolean;
}

export const ChatUser = ({ name, tooltipContent, interactiveTooltip, ...props }: ChatUserProps) => (
  <Tooltip
    content={tooltipContent}
    triggeredBy="click"
    offset={[10, 10]}
    timeout={100000}
    disabled={!interactiveTooltip}
  >
    <Author {...props}>{name}:</Author>
  </Tooltip>
);
