import styled from 'styled-components';
import { tint } from 'polished';
import React, { ComponentType } from 'react';

import { ExternalPlatformType } from '@/types/ExternalPlatform';

import BattleNetSvg from './assets/battleNet.svg';
import DiscordSvg from './assets/discord.svg';
import SteamSvg from './assets/steam.svg';
import TwitchSvg from './assets/twitch.svg';

const Button = styled.button`
  align-items: center;
  border: none;
  border-radius: 20px;
  box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.1), 0 2px 2px rgba(16, 9, 48, 0.25);
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  padding: 0;
  width: 50px;
`;

const Icon = styled.svg`
  fill: currentColor;
  height: 36px;
  width: 36px;
`;

const DISCORD_BACKGROUND = '#687cc9';
const DiscordButton = styled(Button)`
  background: ${DISCORD_BACKGROUND};

  &:focus:not(:focus-visible),
  &:hover {
    background: ${tint(0.1, DISCORD_BACKGROUND)};
  }
`;

const TWITCH_BACKGROUND = '#6441a5';
const TwitchButton = styled(Button)`
  background: ${TWITCH_BACKGROUND};

  &:focus:not(:focus-visible),
  &:hover {
    background: ${tint(0.1, TWITCH_BACKGROUND)};
  }
`;

const STEAM_BACKGROUND = '#444444';
const SteamButton = styled(Button)`
  background: ${STEAM_BACKGROUND};

  &:focus:not(:focus-visible),
  &:hover {
    background: ${tint(0.1, STEAM_BACKGROUND)};
  }
`;

const BATTLE_NET_BACKGROUND = '#148EFF';
const BattleNetButton = styled(Button)`
  background: ${BATTLE_NET_BACKGROUND};

  &:focus:not(:focus-visible),
  &:hover {
    background: ${tint(0.1, BATTLE_NET_BACKGROUND)};
  }
`;

const ButtonComponent: Record<ExternalPlatformType, typeof Button> = {
  [ExternalPlatformType.BattleNet]: BattleNetButton,
  [ExternalPlatformType.Discord]: DiscordButton,
  [ExternalPlatformType.Steam]: SteamButton,
  [ExternalPlatformType.Twitch]: TwitchButton,
};

const IconComponent: Record<ExternalPlatformType, ComponentType> = {
  [ExternalPlatformType.BattleNet]: BattleNetSvg,
  [ExternalPlatformType.Discord]: DiscordSvg,
  [ExternalPlatformType.Steam]: SteamSvg,
  [ExternalPlatformType.Twitch]: TwitchSvg,
};

interface SocialButtonProps {
  className?: string;
  type: ExternalPlatformType;
  onClick?: (type: ExternalPlatformType) => void;
}

const SocialButton = ({ className, type, onClick }: SocialButtonProps) => {
  const MyButton = ButtonComponent[type];
  const MyIcon = IconComponent[type];
  const handleClick = () => {
    onClick?.(type);
  };
  return (
    <MyButton className={className} type="button" onClick={handleClick}>
      <Icon as={MyIcon} />
    </MyButton>
  );
};

export default SocialButton;
