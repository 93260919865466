enum KickFromWolfPackChallengeErrorCode {
  ExperienceNotFound = 3000,
  InvalidExperienceType = 3001,
  InvalidWolfPackChallengeStatus = 3002,
  NotChallengerOrRival = 3003,
  ParticipantNotFound = 3004,
  ReadyCheckNotFound = 3005,
  ParticipantHasntFailedReadyCheck = 3006,
  ExperienceParticipationNotFound = 3007,
  InvalidExperienceParticipationType = 3008,
}

export default KickFromWolfPackChallengeErrorCode;
