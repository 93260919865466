import React from 'react';
import styled from 'styled-components';

import {
  FONT_NAME_BRUTAL_TYPE,
  FONT_NAME_ROBOTO,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
} from './styleConstants';

const UserStatus = styled.div`
  display: inline-flex;
  align-items: center;
`;

const UserStatusText = styled.span`
  height: 100%;
  width: 100%;
  font-family: ${FONT_NAME_ROBOTO};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: 0px;
  color: rgba(211, 206, 239, 0.5);
  font-size: 12px;
`;

const UserStatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1db553;
  border-radius: 5px;
  color: #fff;
  font-family: ${FONT_NAME_BRUTAL_TYPE};
  font-size: 10px;
  font-weight: ${FONT_WEIGHT_BOLD};
  height: 100%;
  width: 100%;
  padding: 0 5px;
`;

const Online = styled.span`
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IOnlineStatusProps {
  status: {
    isOnline: boolean;
    lastOnlineAt: Date | null;
  };
  label: string;
}

export const OnlineStatus = ({ status, label, ...props }: IOnlineStatusProps) => (
  <UserStatus {...props}>
    {status && status.isOnline ? (
      <UserStatusBadge>
        <Online>ONLINE</Online>
      </UserStatusBadge>
    ) : (
      <UserStatusText>{label}</UserStatusText>
    )}
  </UserStatus>
);
