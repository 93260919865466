import { NotificationType } from '../../types';
import { navigate } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';

import getExperiencePagePath from '@/paths/getExperiencePagePath';
import NotificationViewModel from '../model/NotificationViewModel';
import { CheckInStartNotificationView } from '../Views';

import NotificationConsumer from './NotificationConsumer';

class ExperienceCheckInStartNotificationConsumer implements NotificationConsumer {
  type = NotificationType.ExperienceCheckInStart;

  onClick = (notification: NotificationViewModel) => {
    navigate(getExperiencePagePath(notification.experience!.id));
  };

  perform(notification: NotificationViewModel, read: () => void) {
    toast.info(this.render(notification), {
      onClick: () => {
        this.onClick(notification);
        read();
      },
      toastId: notification.id,
      hideProgressBar: true,
    });
  }

  render(notification: NotificationViewModel) {
    return <CheckInStartNotificationView notification={notification} />;
  }
}

export default ExperienceCheckInStartNotificationConsumer;
