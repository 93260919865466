import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ProfileDefaultBgPng = new URL('./profile-default-bg.png', import.meta.url).href;

interface BannerProps {
  $bg: string | null;
}

const Container = styled.div<BannerProps>`
  background: url(${({ $bg }) => $bg || ProfileDefaultBgPng});
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 320px;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 320px;
  }

  &::before {
    background: linear-gradient(-90deg, rgba(10, 16, 35, 0) 0%, #0a1023 100%);
    left: 0;
  }

  &::after {
    background: linear-gradient(90deg, rgba(10, 16, 35, 0) 0%, #0a1023 100%);
    right: 0;
  }
`;

const Overlay = styled.div`
  background: linear-gradient(rgba(10, 16, 35, 0) 0%, #0a1023 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export interface IImageCoverProps {
  bannerURL: string | null;
  className?: string;
  children?: ReactNode;
}

export const ImageCover = ({ className, children, bannerURL }: IImageCoverProps) => (
  <Container $bg={bannerURL} className={className}>
    <Overlay />
    {children}
  </Container>
);
