enum AcceptWolfPackChallengeErrorCode {
  ExperienceNotFound = 200,
  InvalidExperienceType = 201,
  InvalidWolfPackChallengeStatus = 202,
  UserIsNotARival = 203,
  RivalIsSameUserAsChallenger = 204,
  RivalNotFound = 205,
  SteamRequired = 206,
  UserIsNotCandidateForChallenge = 207,
  CandidateIsAlreadyAcceptedChallenge = 208,
  RivalIsNotAPro = 209,
}

export default AcceptWolfPackChallengeErrorCode;
