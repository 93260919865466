enum WolfPackChallengeCancellationReason {
  ByRival = 'byRival',
  ByChallenger = 'byChallenger',
  Timeout = 'timeout',
  PlayersNotJoined = 'playersNotJoined',
  InsufficientParticipants = 'insufficientParticipants',
  TechnicalIssues = 'technicalIssues',
  GoalNotReached = 'goalNotReached',
  ChallengerWalkover = 'challengerWalkover',
  RivalWalkover = 'rivalWalkover',
  DathostIssues = 'dathostIssues',
  SteamIssues = 'steamIssues',
}

export default WolfPackChallengeCancellationReason;
