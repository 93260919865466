import { FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import ExperienceViewModel from './ExperienceViewModel';
import { FirestoreExperience } from './types';

const ExperienceDataConverter: FirestoreDataConverter<ExperienceViewModel> = {
  fromFirestore(snapshot: QueryDocumentSnapshot<FirestoreExperience>): ExperienceViewModel {
    const data = snapshot.data();

    return ExperienceViewModel.fromFirestore(data);
  },

  toFirestore(): {} {
    return {};
  },
};

export default ExperienceDataConverter;
