import { navigate, RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect } from 'react';

import getProfilePagePath from '@/paths/getProfilePagePath';
import SessionContext from '@/common/SessionContext';
import useQueryParams from '@/common/useQueryParams';

import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const { user, loggedIn } = useContext(SessionContext);
  const { redirectURL } = useQueryParams<{ redirectURL?: string }>();

  useEffect(() => {
    if (loggedIn) {
      if (redirectURL) {
        navigate(redirectURL);
      } else {
        navigate(getProfilePagePath(user!.uid));
      }
    }
  }, [loggedIn, redirectURL]);

  return <ForgotPasswordForm />;
};

export default ForgotPassword;
