import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const Animation = keyframes`
  from {
    background: rgba(255, 255, 255, 0.8);
  }

  to {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const DOT_SIZE = 10;

interface DotProps {
  $delay: number;
}

const Dot = styled.div<DotProps>`
  animation: ${Animation} 0.7s ease ${({ $delay }) => $delay}s infinite;
  border-radius: ${DOT_SIZE / 2}px;
  height: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;

  &:not(:first-of-type) {
    margin-left: ${DOT_SIZE / 2}px;
  }
`;

export const ThreeDots = ({ ...props }) => (
  <Container {...props}>
    <Dot $delay={0} />
    <Dot $delay={0.1} />
    <Dot $delay={0.2} />
  </Container>
);
