import { Placement, PositioningStrategy, VirtualElement } from '@popperjs/core';
import React, { useRef } from 'react';
import { Popper } from 'react-popper';

import { ClickOutside } from './ClickOutside';
import Portal from './Portal';

// @flow
import type { StrictModifiers } from '@popperjs/core';

interface PopoverProps {
  onClose?: () => void;
  reference?: HTMLElement | VirtualElement;
  placement?: Placement;
  strategy?: PositioningStrategy;
  modifiers?: StrictModifiers[];
  children: React.ReactNode;
}

export const Popover = ({
  onClose,
  reference,
  placement,
  modifiers,
  strategy,
  children,
  ...props
}: PopoverProps) => {
  const popperRef = useRef();

  return (
    <Portal>
      <ClickOutside reference={popperRef} onClickOutside={onClose!}>
        <Popper
          innerRef={popperRef}
          referenceElement={reference}
          placement={placement}
          modifiers={modifiers}
          strategy={strategy}
          {...props}
        >
          {({ ref, style }) => (
            <div ref={ref} style={style}>
              {children}
            </div>
          )}
        </Popper>
      </ClickOutside>
    </Portal>
  );
};
