import { stringify, ParsedUrlQuery } from 'querystring';
import PagePath from './PagePath';
import { SortType } from './types';

interface QueryParamsType {
  search?: string;
  sort?: SortType;
}

enum QuerySortType {
  Featured = 'featured',
  Recent = 'recent',
}

const SortTypeToQuery: Partial<Record<SortType, string>> = {
  [SortType.Featured]: QuerySortType.Featured,
  [SortType.Recent]: QuerySortType.Recent,
};

const QueryToSortType: Partial<Record<string, SortType>> = {
  [QuerySortType.Featured]: SortType.Featured,
  [QuerySortType.Recent]: SortType.Recent,
};

const prosPagePath: PagePath<QueryParamsType> = {
  getPath(params?: QueryParamsType): string {
    if (!params) {
      return '/pros';
    }
    return `/pros?${stringify({
      search: params.search,
      sort: params.sort ? SortTypeToQuery[params.sort] : undefined,
    })}`;
  },

  getQueryParams(params: ParsedUrlQuery, defaults?: Partial<QueryParamsType>): QueryParamsType {
    const result: Partial<QueryParamsType> = defaults || {};
    if (params.sort && !Array.isArray(params.sort)) {
      result.sort = QueryToSortType[params.sort];
    }
    if (params.search && !Array.isArray(params.search)) {
      result.search = params.search;
    }
    return result;
  },
};

export default prosPagePath;
