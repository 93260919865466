import { Currency, Database, SerializedFsPackagesConfiguration } from '@/common/types';
import { doc, DocumentSnapshot, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import ClientErrorCode from '../common/ClientErrorCode';
import ErrorWithCode from '../common/ErrorWithCode';
import { firestore } from '../common/firebaseClient';
import useLoadingState from '../common/useLoadingState';

const useFsPackages = (currency: Currency) => {
  const [fsPackages, setFsPackages] = useState<SerializedFsPackagesConfiguration>();
  const { loadingState, setLoading, setLoaded, setLoadFailed, reset } = useLoadingState();
  const [error, setError] = useState<ErrorWithCode>();

  const onFirestoreSnapshot = (snapshot: DocumentSnapshot) => {
    if (!snapshot.exists) {
      unstable_batchedUpdates(() => {
        setLoadFailed();
        setFsPackages(undefined);
        setError(new ErrorWithCode(ClientErrorCode.UnknownError));
      });
      return;
    }
    const data = snapshot.data() as SerializedFsPackagesConfiguration;
    unstable_batchedUpdates(() => {
      setLoaded();
      setError(undefined);
      setFsPackages(data);
    });
  };

  const onFirestoreSnapshotError = (_error: FirestoreError) => {
    unstable_batchedUpdates(() => {
      setLoadFailed();
      setFsPackages(undefined);
      setError(new ErrorWithCode(ClientErrorCode.UnknownError));
    });
  };

  useEffect(() => {
    setLoading();
    const unsubscribe = onSnapshot(
      doc(firestore, Database.Configs, Database.FsPackages),
      onFirestoreSnapshot,
      onFirestoreSnapshotError
    );
    return () => {
      unstable_batchedUpdates(() => {
        reset();
        setError(undefined);
        setFsPackages(undefined);
      });
      unsubscribe();
    };
  }, []);

  return {
    fsPackages: fsPackages ? fsPackages[currency] : [],
    loadingState,
    error,
  };
};

export default useFsPackages;
