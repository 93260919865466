import { ParsedUrlQuery, stringify } from 'querystring';

import { CSGOTemplateTypes } from '../pages/Experience/games/ExperienceTemplates';
import { ExperienceStatusType, ServerLocationType } from '@/experiences/ExperiencesFilterTypes';

export interface ExperiencesQueryParams extends ParsedUrlQuery {
  status?: ExperienceStatusType;
  template?: CSGOTemplateTypes;
  server?: ServerLocationType | 'all';
}

const getExperiencesPagePath = (params?: ExperiencesQueryParams) => {
  let path = '/experiences';

  if (params) {
    const sanitizedParams: ExperiencesQueryParams = {};
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        sanitizedParams[key] = value;
      }
    });

    path = `${path}?${stringify(sanitizedParams)}`;
  }

  return path;
};

export default getExperiencesPagePath;
