import { FONT_FAMILY_BRUTAL_TYPE } from '@/common/ui';
import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { FONT_WEIGHT_MEDIUM } from '@/common/ui';

import CircleMinusSvg from './circle-minus.svg';
import CirclePlusSvg from './circle-plus.svg';

const Container = styled.label`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: rgba(10, 16, 35, 0.5);
  padding: 8px;

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(211, 206, 239, 0.2);
    &:hover {
      box-shadow: 0 0 0 2px rgba(211, 206, 239, 0.35);
    }
  }
`;

const Fs = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-left: 5px;
`;

const Input = styled.input.attrs({ type: 'text' })`
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-left: 6px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  border: none;
  width: ${({ value }) => (`${value}` ? `${`${value}`.length}ch` : 'auto')};
`;

const Currency = styled.div`
  color: rgba(211, 206, 239, 0.35);
  font-size: 17px;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-left: 8px;
  white-space: pre;
`;

const ButtonIcon = styled.svg`
  fill: currentColor;
  height: 100%;
  width: 100%;
`;

const ControlButton = styled.button`
  align-items: center;
  border: none;
  color: rgba(211, 206, 239, 0.65);
  background: none;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 24px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(211, 206, 239, 0.2);
      cursor: initial;
      pointer-events: none;

      &:hover,
      &:focus {
        color: rgba(211, 206, 239, 0.2);
      }
    `}

  &:hover, &:focus {
    color: rgba(211, 206, 239, 0.8);
  }
`;

const ControlButtons = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  ${ControlButton}:not(:first-of-type) {
    margin-left: 8px;
  }
`;

interface WithdrawTotalFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  allowNegative?: boolean;
  step?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
  currency: string;
}

const trimStartZeros = (value: string, allowNegative: boolean) => {
  const arr = value.split(/^-?0*/);
  if (arr.length === 2) {
    if (!arr[0].length && !arr[1].length) {
      return '0';
    }
    if (allowNegative && value.charAt(0) === '-') {
      return `-${arr[1]}`;
    }
    return arr[1];
  }
  return value;
};

const WithdrawTotalField = ({
  className,
  id,
  name,
  value,
  onChange,
  allowNegative,
  minValue,
  maxValue,
  step = 1,
  currency,
}: WithdrawTotalFieldProps) => {
  const num = parseInt(value as string, 10);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (allowNegative && /^0?-$/.test(event.target.value)) {
      return onChange(0);
    }
    if (/^-?\d*$/.test(event.target.value)) {
      if (!event.target.value.length) {
        return onChange(minValue || 0);
      }
      let newValue = Number(trimStartZeros(event.target.value, !!allowNegative));
      if (maxValue && newValue > maxValue) {
        newValue = maxValue;
      }
      if (minValue && newValue < minValue) {
        newValue = minValue;
      }
      return onChange(newValue);
    }
    return onChange(!Number.isNaN(Number(event.target.value)) ? Number(event.target.value) : 0);
  };

  const onMinusClick = () => {
    if (Number.isNaN(num)) {
      return onChange(minValue || 0);
    }
    let newNum = num - step;
    if (!allowNegative && newNum < 0) {
      return onChange(minValue || 0);
    }
    if (minValue && newNum < minValue) {
      newNum = minValue;
    }
    return onChange(newNum);
  };

  const onPlusClick = () => {
    if (Number.isNaN(num)) {
      return onChange(minValue || 0);
    }
    let nextValue = num + step;
    if (maxValue && nextValue > maxValue) {
      nextValue = maxValue;
    }

    return onChange(nextValue);
  };

  return (
    <Container htmlFor={id} className={className}>
      <Fs>₪</Fs>
      <Input id={id} name={name} value={value} onChange={onInputChange} />
      <Currency>{currency}</Currency>
      <ControlButtons>
        <ControlButton type="button" onClick={onMinusClick}>
          <ButtonIcon as={CircleMinusSvg} />
        </ControlButton>
        <ControlButton type="button" onClick={onPlusClick}>
          <ButtonIcon as={CirclePlusSvg} />
        </ControlButton>
      </ControlButtons>
    </Container>
  );
};

export default WithdrawTotalField;
