export enum SeatStatus {
  Free = 'free',
  Reserved = 'reserved',
  Taken = 'taken',
}

export interface FirestoreSeat {
  id: string;
  status: SeatStatus;
  member?: string | null;
}

export interface FirestoreTeam {
  id: string;
  seats: FirestoreSeat[];
}
