import React from 'react';
import styled from 'styled-components';

import useOnClickOutside from './helpers/useOnClickOutside';
import { Z_INDEX_5 } from './styleConstants';

interface ClickOutsideProps {
  reference: React.RefObject<any>;
  onClickOutside: () => void;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  & > * {
    z-index: ${Z_INDEX_5};
  }
`;

export const ClickOutside = ({ reference, onClickOutside, children }: ClickOutsideProps) => {
  useOnClickOutside(reference, onClickOutside);

  return <Wrapper ref={reference}>{children}</Wrapper>;
};
