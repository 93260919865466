import { PublicProfile, UserLinkType, UserChannelType, ProfileType, AvatarFrame } from './types';

import PublicProfileViewModel from '@/types/PublicProfileViewModel';

const preparePublicProfileViewModel = (publicProfile: PublicProfile): PublicProfileViewModel => {
  const link = publicProfile.links
    ? publicProfile.links.reduce<Record<UserLinkType, string | null>>(
        (result, item) => ({
          ...result,
          [item.type]: item.link,
        }),
        {
          [UserLinkType.Facebook]: null,
          [UserLinkType.Steam]: null,
          [UserLinkType.Twitch]: null,
          [UserLinkType.Twitter]: null,
          [UserLinkType.YouTube]: null,
        },
      )
    : {
        [UserLinkType.Facebook]: null,
        [UserLinkType.Steam]: null,
        [UserLinkType.Twitch]: null,
        [UserLinkType.Twitter]: null,
        [UserLinkType.YouTube]: null,
      };
  const stream = publicProfile.streams
    ? publicProfile.streams.reduce<Record<UserChannelType, any | null>>(
        (result, item) => {
          result[item.type] = item.data;
          return result;
        },
        { [UserChannelType.Twitch]: null },
      )
    : { [UserChannelType.Twitch]: null };

  let { title } = publicProfile;
  if (publicProfile.showOnProsPage) {
    title = publicProfile.title || 'Pro';
  }

  const result: PublicProfileViewModel = {
    id: publicProfile.id,
    type: ProfileType.Pro,
    title,
    username: publicProfile.username,
    tag: publicProfile.tag,
    bio: publicProfile.bio,
    avatarURL: publicProfile.avatarUrl,
    bannerURL: publicProfile.bannerUrl,
    // stats: {
    //   duelOfHonor: publicProfile.stats.duelOfHonor,
    //   wolfPackChallenge: publicProfile.stats.showmatch,
    // },
    avatarFrame: AvatarFrame.None,
    link: {
      facebook: link[UserLinkType.Facebook],
      steam: link[UserLinkType.Steam],
      twitch: link[UserLinkType.Twitch],
      twitter: link[UserLinkType.Twitter],
      youTube: link[UserLinkType.YouTube],
    },
    stream: {
      twitch: stream[UserChannelType.Twitch],
    },
    rank: publicProfile.rating,
    tier: publicProfile.tier,
    shortURL: publicProfile.shortURL,
    totalFollowers: publicProfile.totalFollowers,
    // experienceSettings: publicProfile.experienceSettings,
  };

  return result;
};

export default preparePublicProfileViewModel;
