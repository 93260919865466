import Col from './Col';
import Row from './Row';

export type Props = {
  Col: typeof Col;
  Row: typeof Row;
};

const Grid = {} as Props;
Grid.Col = Col;
Grid.Row = Row;

export default Grid;
