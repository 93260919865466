import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from '../styleConstants';

const DonateMessageText = styled.div<Partial<{ $donate: number }>>`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-top: 4px;
  color: ${({ $donate }) => {
    if (!$donate) return 'rgba(211, 206, 239, 0.35)';
    if ($donate >= 1000) {
      return 'rgba(251, 192, 45, 0.65)';
    }
    if ($donate >= 100) {
      return 'rgba(240, 244, 248, 0.5)';
    }

    return 'rgba(211, 206, 239, 0.35)';
  }};
`;

const DonateUser = styled.span<Partial<{ $donate: number }>>`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: ${({ $donate }) => {
    if (!$donate) return 'rgba(211, 206, 239, 0.5)';
    if ($donate >= 1000) {
      return 'rgba(251, 192, 45, 0.8)';
    }
    if ($donate >= 100) {
      return 'rgba(240, 244, 248, 0.65)';
    }

    return 'rgba(211, 206, 239, 0.5)';
  }};
`;

interface DonateMessageProps {
  user: string;
  donate?: number;
  arrow?: string;
  children: React.ReactNode;
}

export const DonateMessage = ({ user, donate, arrow, children, ...props }: DonateMessageProps) => (
  <DonateMessageText $donate={donate} {...props}>
    {arrow ? `${arrow} ` : null}
    <DonateUser $donate={donate}>{user}</DonateUser> {children}
  </DonateMessageText>
);
