
import OriginalTypography from './Typography';
import Paragraph from './Paragraph';
import Title from './Title';

export type TypographyProps = typeof OriginalTypography & {
  Paragraph: typeof Paragraph;
  Title: typeof Title;
};

const Typography = OriginalTypography as TypographyProps;
Typography.Paragraph = Paragraph;
Typography.Title = Title;

export default Typography;