import React from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerRoot = styled.svg``;

const bounce = keyframes`
	0%{
		transform:scale(1);
	}
	100%{
		transform:scale(.3);
	}
`;

const Dot = styled.circle`
  fill: rgb(255, 255, 255);
  transform-origin: center center;
  transform-box: fill-box;
  animation: ${bounce} 1.1s linear infinite;
`;

const Dot1 = styled(Dot)`
  animation-delay: 0.41s;
`;

const Dot2 = styled(Dot)`
  animation-delay: 0.55s;
`;

const Dot3 = styled(Dot)`
  animation-delay: 0.69s;
`;

const Dot4 = styled(Dot)`
  animation-delay: 0.83s;
`;

const Dot5 = styled(Dot)`
  animation-delay: 0.97s;
`;

const Dot6 = styled(Dot)`
  animation-delay: 1.1s;
`;

const Dot7 = styled(Dot)`
  animation-delay: 1.24s;
`;

const Dot8 = styled(Dot)`
  animation-delay: 1.38s;
`;

export const DotsSpinner = ({ ...props }) => (
  <SpinnerRoot viewBox="0 0 60 60" {...props}>
    <Dot1 cx="53" cy="30" r="6" />
    <Dot2 cx="46.263" cy="46.263" r="6" />
    <Dot3 cx="30" cy="53" r="6" />
    <Dot4 cx="13.737" cy="46.263" r="6" />
    <Dot5 cx="7" cy="30" r="6" />
    <Dot6 cx="13.737" cy="13.737" r="6" />
    <Dot7 cx="30" cy="7" r="6" />
    <Dot8 cx="46.263" cy="13.737" r="6" />
  </SpinnerRoot>
);
