import { rgba } from 'polished';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SessionContext from '../../../SessionContext';

import {
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_REGULAR,
  AvatarLegacy,
  pulse,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_FAMILY_BRUTAL_TYPE,
} from '../../../ui';
import NotificationViewModel from '../../model/NotificationViewModel';
import FailedSvg from './failed-icon.svg';

const NotificationRoot = styled.div`
  align-items: stretch;
  display: flex;
`;

const NotificationTitle = styled.div`
  color: inherit;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 16px;
  text-align: left;
`;

const ExperienceTitle = styled.strong`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 16px;
  line-height: 125%;
`;

const IconWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: #d73964;
  animation: ${pulse('#D73964')} 2s linear infinite;
  &::before {
    background: ${rgba('#D73964', 1)};
  }
`;

const FailedIcon = styled(FailedSvg)`
  width: 40px;
  height: 40px;
`;

const ContentWrapper = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Time = styled.div`
  color: currentColor;
  opacity: 0.5;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  text-align: left;
  margin-top: 5px;
`;

interface FailedProps {
  notification: NotificationViewModel;
}

const Failed = ({ notification }: FailedProps) => {
  const { t } = useTranslation(['notifications', 'exp2']);
  const experience = notification.experience!;

  return (
    <NotificationRoot>
      <IconWrapper>
        <FailedIcon />
      </IconWrapper>
      <ContentWrapper>
        <NotificationTitle>
          <Trans
            t={t}
            i18nKey={'experience.failed'}
            components={{ truncate: <span />, strong: <ExperienceTitle /> }}
            values={{
              title: `${experience.template.name}.title`,
              experience: experience.toPlain(),
            }}
          />
        </NotificationTitle>
        <Time>
          {t('createdAt', {
            createdAt: notification.createdAt,
            formatOptions: {
              addSuffix: true,
            },
          })}
        </Time>
      </ContentWrapper>
    </NotificationRoot>
  );
};

export default Failed;
