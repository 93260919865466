import { SerializedUserTag } from '../types';
import React from 'react';
import styled, { css } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_NAME_ROBOTO } from './styleConstants';

export enum UserTitleSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

interface UserTitleProps {
  profile: { title?: string; tag: SerializedUserTag };
  className?: string;
  size?: UserTitleSize;
}

const TEXT_SIZE: Record<UserTitleSize, string> = {
  large: '17px',
  medium: '13px',
  small: '11px',
};

const FONT_WEIGHT: Record<UserTitleSize, number> = {
  large: 500,
  medium: 800,
  small: 800,
};

const FONT_FAMILY: Record<UserTitleSize, string> = {
  large: FONT_NAME_ROBOTO,
  medium: FONT_FAMILY_BRUTAL_TYPE,
  small: FONT_FAMILY_BRUTAL_TYPE,
};

const TitleWrapper = styled.span<{ $isPro: boolean; $size: UserTitleSize }>`
  ${({ $size }) => css`
    line-height: ${TEXT_SIZE[$size]};
    font-size: ${TEXT_SIZE[$size]};
    font-family: ${FONT_FAMILY[$size]};
    font-weight: ${FONT_WEIGHT[$size]};
  `}
  letter-spacing: 0px;
  color: ${({ $isPro }) => ($isPro ? '#00bcd4' : 'rgba(211, 206, 239, 0.5)')};
  text-transform: uppercase;
  letter-spacing: 0.35px;
`;

export const UserTitle = ({ profile, size = UserTitleSize.Medium, className }: UserTitleProps) => {
  if (!profile.title) return null;
  return (
    <TitleWrapper $isPro={!!profile.title} $size={size} className={className}>
      {profile.title}
    </TitleWrapper>
  );
};

UserTitle.defaultProps = {
  className: undefined,
  size: undefined,
};
