import React from 'react';

export interface StreamerModeContextProps {
  isStreamerMode: boolean;
  setStreamerMode: () => void;
  defaultAvatar: string;
  getWolfName: (id: string) => string;
}

const StreamerModeContext = React.createContext<StreamerModeContextProps>({
  isStreamerMode: true,
  setStreamerMode: () => {},
  defaultAvatar: '',
  getWolfName: (id: string) => '',
});

export default StreamerModeContext;
