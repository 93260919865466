import React from 'react';
import { useTranslation } from 'react-i18next';

import { HandleBattleNetConnectErrorCode } from '@/common/types';

import DefaultErrorView from '../DefaultErrorView';
import ErrorPageProps from '../ErrorPageProps';

const ErrorMessageKey: Record<number, string> = {
  [HandleBattleNetConnectErrorCode.InvalidCode]: 'invalidCode',
  [HandleBattleNetConnectErrorCode.InvalidState]: 'invalidState',
  [HandleBattleNetConnectErrorCode.ExternalAccountAlreadyInUse]: 'externalAccountAlreadyInUse',
  [HandleBattleNetConnectErrorCode.ProfileNotFound]: 'profileNotFound',
};

const BattlnetConnectErrorPage = ({ errorCode }: ErrorPageProps) => {
  const { t } = useTranslation('errors');
  return (
    <DefaultErrorView
      heading={t('battlnetConnectErrorPage.heading')}
      description={t(`battlnetConnectErrorPage.errorCodes.${ErrorMessageKey[errorCode]}`)}
    />
  );
};

export default BattlnetConnectErrorPage;
