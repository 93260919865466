enum ShowmatchExternalActionRewardErrorCode {
  InvalidExternalActionSession = 7327321,
  ShowmatchNotFound = 7327322,
  InvalidExternalAction = 7327323,
  UserNotFound = 7327324,
  InvalidWolfPackChallengeStatus = 7327325,
  TeamIsFull = 7327326,
  ExperienceInterferes = 7327327,
  SteamRequired = 7327328,
  Banned = 7327329,
  ActionAlreadyUsed = 73273210,
  StatsMissing = 73273211,
  WrongParticipationStatus = 73273212,
}

export default ShowmatchExternalActionRewardErrorCode;
