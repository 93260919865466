import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import styled from 'styled-components';
import { tint } from 'polished';

import getProfilePagePath from '../../../paths/getProfilePagePath';
import getSettingsPagePath from '../../../settings/getSettingsPagePath';
import MenuPageProps from './MenuPageProps';
import { MenuPages } from './MenuPages';
import SessionContext from '@/common/SessionContext';
import SettingsCategory from '../../../settings/SettingsCategory';
import useLogOut from '../../useLogOut';
import { AvatarLegacy, FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../../ui';
import { DropdownGroup, DropdownGroupSeparator, DropdownItem, ItemIcon, ItemStatus, ItemText } from '../Dropdown';

import ArrowRightSvg from './arrow-right.svg';
import BlockSvg from './block.svg';
import CoinsSvg from './coins.svg';
import DiscordSvg from './discord.svg';
import GlobeSvg from '../globe.svg';
import SettingsSvg from './settings.svg';
import SignOutSvg from './sign-out.svg';

const ProfileAvatar = styled(AvatarLegacy)`
  flex-shrink: 0;
`;

const MenuContainer = styled.div`
  width: 310px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 15px;
`;

const Username = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 19px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tag = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-top: 5px;
`;

const ProStatus = styled.div`
  color: rgba(0, 188, 212, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

const ToProfileText = styled.div`
  color: rgba(255, 255, 255, 0.35);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-top: auto;
`;

const ProfileItem = styled(DropdownItem)`
  align-items: stretch;
  display: flex;
`;

const ReferralItem = styled(DropdownItem)`
  background: rgba(251, 192, 45, 1);
  color: rgba(11, 16, 34, 0.8);

  &:hover,
  &:focus {
    background: ${tint(0.1, 'rgba(251, 192, 45, 1)')};
  }
`;

const MainPage = ({ setPage, toggleDropdown }: MenuPageProps) => {
  const { t } = useTranslation('header');
  const logOut = useLogOut();
  const { user, publicProfile, privateProfile } = useContext(SessionContext);

  return (
    <MenuContainer>
      <DropdownGroup>
        <ProfileItem as={Link} to={getProfilePagePath(user!.uid)} onClick={toggleDropdown}>
          <ProfileAvatar profile={publicProfile} size="70" />
          <ProfileInfo>
            <Username>{publicProfile?.username}</Username>
            {publicProfile?.title ? <ProStatus>{publicProfile.title}</ProStatus> : <Tag>#{publicProfile?.tag}</Tag>}
            <ToProfileText>{t('mainPage.goToProfile')}</ToProfileText>
          </ProfileInfo>
        </ProfileItem>
      </DropdownGroup>
      <DropdownGroupSeparator />
      <DropdownGroup>
        <DropdownItem as={Link} to={getSettingsPagePath(SettingsCategory.Profile)}>
          <ItemIcon as={SettingsSvg} />
          <ItemText>{t('mainPage.settings')}</ItemText>
        </DropdownItem>
        <DropdownItem as={Link} to={getSettingsPagePath(SettingsCategory.Security)}>
          <ItemIcon as={BlockSvg} />
          <ItemText>{t('mainPage.bans')}</ItemText>
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setPage(MenuPages.Languages);
          }}
        >
          <ItemIcon as={GlobeSvg} />
          <ItemText>{t('mainPage.currency')}</ItemText>
          <ItemStatus>
            <ItemIcon as={ArrowRightSvg} />
          </ItemStatus>
        </DropdownItem>
        {!privateProfile?.discord && (
          <DropdownItem as={Link} to={getSettingsPagePath(SettingsCategory.Connections)}>
            <ItemIcon as={DiscordSvg} />
            <ItemText>{t('mainPage.joinCommunity')}</ItemText>
          </DropdownItem>
        )}
        <ReferralItem as={Link} to={getSettingsPagePath(SettingsCategory.Referral)}>
          <ItemIcon as={CoinsSvg} />
          <ItemText>{t('mainPage.refer')}</ItemText>
        </ReferralItem>
      </DropdownGroup>
      <DropdownGroupSeparator />
      <DropdownGroup>
        <DropdownItem
          onClick={() => {
            toggleDropdown();
            logOut();
          }}
        >
          <ItemIcon as={SignOutSvg} />
          <ItemText>{t('mainPage.logOut')}</ItemText>
        </DropdownItem>
      </DropdownGroup>
    </MenuContainer>
  );
};

export default MainPage;
