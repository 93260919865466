import { ReadyCheckParticipantStatus } from './types';

export default class ReadyCheckParticipantViewModel {
  readonly member: string;

  readonly status: ReadyCheckParticipantStatus;

  readonly updatedAt: Date;

  constructor(props: { member: string; status: ReadyCheckParticipantStatus; updatedAt: Date }) {
    this.member = props.member;
    this.status = props.status;
    this.updatedAt = props.updatedAt;
  }

  get isReady() {
    return this.status === ReadyCheckParticipantStatus.Ready;
  }

  get isNotReady() {
    return this.status === ReadyCheckParticipantStatus.NotReady;
  }

  get isPending() {
    return this.status === ReadyCheckParticipantStatus.Pending;
  }
}
