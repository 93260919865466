import { RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import SessionContext from '@/common/SessionContext';
import { ThreeDots } from '@/common/ui';
import CampaignContext from '@/contexts/campaign/CampaignContext';
import useCampaign from '@/hooks/useCampaign';
import useCampaignFundraiser from '@/hooks/useCampaignFundraiser';
import useCampaignReferrer from '@/hooks/useCampaignReferrer';

const PreAuthContainer = styled.div`
  align-items: center;
  background: #1c163b;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

interface CampaignContextProviderProps {
  campaignId: string;
}

const CampaignContextProvider: React.FC<RouteComponentProps<CampaignContextProviderProps>> = ({
  children,
  campaignId,
}) => {
  const { campaign, loadingState } = useCampaign(campaignId!);
  const { user } = useContext(SessionContext);
  const { fundraiser } = useCampaignFundraiser(campaignId!, user?.uid || '_');
  useCampaignReferrer(campaignId!);

  if (!loadingState.loaded || !campaign) {
    return (
      <PreAuthContainer>
        <ThreeDots />
      </PreAuthContainer>
    );
  }

  return <CampaignContext.Provider value={{ campaign, fundraiser }}>{children}</CampaignContext.Provider>;
};

export default CampaignContextProvider;
