import { useCallback, useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  element: T | null,
  cb: (event: MouseEvent | TouchEvent) => void,
) => {
  const eventHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!element || element.contains(event.target as Node)) {
        return;
      }
      cb(event);
    },
    [element, cb],
  );

  useEffect(() => {
    document.addEventListener(`mousedown`, eventHandler);
    document.addEventListener(`touchstart`, eventHandler);

    return () => {
      document.removeEventListener(`mousedown`, eventHandler);
      document.removeEventListener(`touchstart`, eventHandler);
    };
  }, [eventHandler]);
};

export default useOutsideClick;
