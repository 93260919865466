import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from './ui';

type PrimaryParagraphSize = 'base' | 'small';

const FONT_SIZE: Record<PrimaryParagraphSize, string> = {
  base: '16px',
  small: '14px',
};

const LINE_HEIGHT: Record<PrimaryParagraphSize, string> = {
  base: '22px',
  small: '20px',
};

interface PrimaryParagraphProps {
  $size?: PrimaryParagraphSize;
}

const PrimaryParagraph = styled.p<PrimaryParagraphProps>`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: ${({ $size }) => FONT_SIZE[$size!]};
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: ${({ $size }) => LINE_HEIGHT[$size!]};
`;

PrimaryParagraph.defaultProps = {
  $size: 'base',
};

export default PrimaryParagraph;
