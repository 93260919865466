import ExperienceContestantViewModel from '../ExperienceContestantViewModel';
import { FirestoreExperienceContestPool } from './types';

export default class ExperienceContestPool {
  readonly chatRoomId: string;
  readonly contest: string;
  readonly contestants: string[];
  readonly next: number;

  constructor(props: { chatRoomId: string, contest: string; contestants: string[]; next: number }) {
    this.chatRoomId = props.chatRoomId;
    this.contest = props.contest;
    this.contestants = [...props.contestants];
    this.next = props.next;
  }

  static fromFirestore(data: FirestoreExperienceContestPool) {
    return new ExperienceContestPool({
      chatRoomId: data.chat_id,
      contest: data.contest,
      contestants: data.contestants || [],
      next: data.next,
    });
  }

  findContestantIndex(contestant: ExperienceContestantViewModel): number {
    return this.contestants.findIndex((c) => c === contestant.id);
  }

  findContestantIndexById(id: string): number {
    return this.contestants.indexOf(id);
  }

  isContestant(id: string): boolean {
    return this.contestants.includes(id);
  }

  isWaiting(contestant: ExperienceContestantViewModel): boolean {
    const index = this.findContestantIndex(contestant);
    if (index === -1) {
      return false;
    }
    return index >= this.next;
  }

  isPassed(contestant: ExperienceContestantViewModel): boolean {
    const index = this.findContestantIndex(contestant);
    if (index === -1) {
      return false;
    }

    return index <= this.next;
  }
}
