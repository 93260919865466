import React from 'react';
import styled from 'styled-components';

const flareD1Png = new URL('./flare-d1.png', import.meta.url).href;
const flareD2Png = new URL('./flare-d2.png', import.meta.url).href;
const flareD3Png = new URL('./flare-d3.png', import.meta.url).href;
const flareD4Png = new URL('./flare-d4.png', import.meta.url).href;
const flareD5Png = new URL('./flare-d5.png', import.meta.url).href;

const CrownWrapper = styled.div<{ $size: number }>`
  position: absolute;
  height: ${({ $size }) => 1.4 * $size}px;
  width: ${({ $size }) => 1.4 * $size}px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const CrownLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
`;

const CrownLayer1 = styled(CrownLayer)`
  background-image: url(${flareD1Png});
`;
const CrownLayer2 = styled(CrownLayer)`
  background-image: url(${flareD2Png});
  animation: spin 360s linear infinite, pulse 4s ease-in-out infinite alternate;
`;
const CrownLayer3 = styled(CrownLayer)`
  background-image: url(${flareD3Png});
  animation: spin 300s linear infinite, pulse 4s ease-in-out infinite alternate-reverse;
`;
const CrownLayer4 = styled(CrownLayer)`
  background-image: url(${flareD4Png});
  animation: spin 240s linear infinite reverse, pulse 3s ease-in-out infinite alternate;
`;
const CrownLayer5 = styled(CrownLayer)`
  background-image: url(${flareD5Png});
  animation: spin 180s linear infinite reverse, pulse 3s ease-in-out infinite alternate-reverse;
`;

export interface ICrownProps {
  size: number;
}

export const Crown = ({ size, ...props }: ICrownProps) => (
  <CrownWrapper $size={size} {...props}>
    <CrownLayer1 />
    <CrownLayer2 />
    <CrownLayer3 />
    <CrownLayer4 />
    <CrownLayer5 />
  </CrownWrapper>
);
