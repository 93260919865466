import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import { BREAKPOINT_MEDIUM } from '../../ui';
import MenuButton from '../MenuButton';
import NotificationModel from '../../Notifications/model/NotificationViewModel';
import NotificationsContext from '../../Notifications/NotificationsContext';
import Tooltip from '../../Tooltip';
import useDropdown from '../useDropdown';
import { AmpliWrapper, Col } from '@/components';
import Dropdown, { DropdownGroupSeparator, DropdownItem } from '../Dropdown';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../../ui';

import BellSvg from './bell.svg';
import CloseSvg from './close.svg';

const StyledDropdown = styled(Dropdown)`
  width: 420px;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    max-width: 420px;
  }
`;

const Dot = styled.div`
  background: rgba(233, 30, 99, 1);
  border-radius: 5px;
  height: 10px;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 10px;
`;

const NotificationButton = styled(MenuButton)`
  &:active {
    ${Dot} {
      filter: drop-shadow(0px 0px 2px #0a1023);
    }
  }
`;

const HeadingText = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
`;

const ClearAllButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 13px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  padding: 0;
  margin: 0;
  text-decoration: underline;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const NotificationsContainer = styled.div`
  background: rgba(43, 38, 96, 1);
  margin-bottom: 10px;
  max-height: 600px;
  overflow-y: auto;
`;

const NotificationItem = styled(DropdownItem)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.35);
  cursor: pointer;
  padding: 0;
  margin: 0;
  flex-shrink: 0;

  svg {
    fill: currentColor;
    height: 18px;
    width: 18px;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const Nothing = styled.div`
  color: rgba(255, 255, 255, 0.35);
  padding: 20px;
  font-size: 20px;
  text-align: center;
`;

const HeaderNotifications = () => {
  const { t } = useTranslation('header');
  const { triggerRef, dropdownRef, isShown, toggleShown } = useDropdown();
  const { consumers, readAll, read, notifications } = useContext(NotificationsContext);

  return (
    <>
      <AmpliWrapper event="click_notification_top" wrap>
        <Tooltip content={t('notifications.tooltip')} strategy="fixed" offset="12" ref={triggerRef} disabled={isShown}>
          <NotificationButton onClick={toggleShown} active={isShown}>
            <BellSvg />
            {Boolean(notifications?.length) && <Dot />}
          </NotificationButton>
        </Tooltip>
      </AmpliWrapper>

      {isShown && (
        <StyledDropdown ref={dropdownRef}>
          <Heading>
            <HeadingText>{t('notifications.heading')}</HeadingText>
            <ClearAllButton onClick={readAll}>{t('notifications.clearAllButton')}</ClearAllButton>
          </Heading>
          <NotificationsContainer>
            {notifications.map((notification: NotificationModel) => {
              if (!consumers.has(notification.type)) return null;

              const consumer = consumers.get(notification.type)!;
              const notificationContent = consumer.render(notification);

              const onRead = (e: React.MouseEvent<any, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                read(notification.id);
              };

              const onItemClick = () => {
                consumer.onClick(notification);
                read(notification.id);
              };

              return (
                <NotificationItem onClick={onItemClick} key={`ntf_${notification.id}`}>
                  {notificationContent}
                  <CloseButton onClick={onRead}>
                    <CloseSvg />
                  </CloseButton>
                </NotificationItem>
              );
            })}
            {!notifications.length && <Nothing>{t('notifications.notFound')}</Nothing>}
          </NotificationsContainer>
        </StyledDropdown>
      )}
    </>
  );
};

export default HeaderNotifications;
