export default class ErrorWithCode extends Error {
  readonly code: number;

  constructor(code: number) {
    super(`Error with code '${code}'.`);
    this.code = code;
    this.name = 'ErrorWithCode';
    Object.setPrototypeOf(this, ErrorWithCode.prototype);
  }
}
