enum SweepstakeEntriesBundle {
  Free = 'free',
  XSmall = 'xs',
  Small = 's',
  Medium = 'm',
  Large = 'l',
  ManyLarge = 'xl',
}

export default SweepstakeEntriesBundle;
