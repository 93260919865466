import semverLt from 'semver/functions/lt';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ServiceWorkerContext } from './ServiceWorkerContext';
import { SocialNetworks } from '@/components';
import { SolidSpinner } from '../common/ui/SolidSpinner';
import useAppConfig from './useAppConfig';
import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  PageLoader,
  Z_INDEX_9,
} from '@/common/ui';

import thumbUpGif from './thumb-up.gif';

const RootComponent = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${Z_INDEX_9};
  overflow: auto;
`;

const Backdrop = styled.div`
  background: rgba(10, 16, 35, 0.8);
  backdrop-filter: blur(20px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  @supports not ((backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(10px))) {
    background: rgba(10, 16, 35, 0.95);
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px;
  position: relative;
  height: 100%;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 49px;
  letter-spacing: -0.01em;
  line-height: 48px;
`;

const Subheading = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  max-width: 600px;
  text-align: center;
`;

const PulseAnimation = keyframes`
  0% {
    border-color: rgba(100, 46, 209, 0.2);
  }
  100% {
    border-color: rgba(100, 46, 209, 1);
  }
`;

const SpinningAnimation = css`
  animation: ${PulseAnimation} 0.5s ease infinite alternate-reverse;
  border-width: 2px;
  border-style: solid;
`;

const SocialNetworksWrapped = styled(SocialNetworks)`
  margin-top: 20px;
`;

const UpdateButton = styled.button.attrs({ type: 'button' })<{ $loading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #5e35b1 0%, #2f73d4 50%, #03a9f4 100%);
  border: none;
  border-radius: 25px;
  box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(10, 16, 35, 0.25);
  color: #fff;
  cursor: pointer;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 25px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  height: 50px;
  letter-spacing: -0.02em;
  margin-top: 30px;
  padding: 0 10px;
  position: relative;

  &:focus:not(:focus-visible),
  &:hover {
    background: linear-gradient(0deg, #6a2ed1 0%, #277cf2 50%, #12b4ff 100%);

    &::before {
      border-color: rgba(100, 46, 209, 0.65);
    }

    &::after {
      border-color: rgba(100, 46, 209, 0.2);
    }
  }

  &::before {
    content: '';
    border: 2px solid rgba(103, 58, 183, 0.65);
    border-radius: 30px;
    bottom: -5px;
    left: -5px;
    position: absolute;
    right: -5px;
    top: -5px;
    transition: all 500ms ease-in-out;
    ${({ $loading }) => $loading && SpinningAnimation};
  }

  &::after {
    content: '';
    border: 2px solid rgba(103, 58, 183, 0.2);
    border-radius: 35px;
    bottom: -10px;
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
    transition: all 500ms ease-in-out;
    ${({ $loading }) => $loading && SpinningAnimation};
  }
`;

const Img = styled.img`
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 60px;
`;

const Loader = styled(PageLoader)`
  height: 100%;
`;

const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

interface AppStatusProps {
  children: React.ReactNode;
}

const AppStatus: React.FC<AppStatusProps> = ({ children }) => {
  const { version, isInstalled, checkForUpdate } = useContext(ServiceWorkerContext);
  const { config, loadingState } = useAppConfig();
  const { t } = useTranslation(['serviceWorker', 'footer']);
  const [int, setInt] = useState<any>();
  const isUpdateAvailable =
    isInstalled && version !== '0.0.0' && config?.minVersion && semverLt(version, config.minVersion);

  useEffect(() => {
    if (isUpdateAvailable) {
      if (isSafari) {
        navigator?.serviceWorker
          .getRegistrations()
          .then((registrations) => Promise.allSettled(registrations.map((registration) => registration.unregister())))
          .then(() => window.location.reload());
      }
      setInt(setInterval(() => checkForUpdate, 3000));
    }
    return () => {
      clearInterval(int);
    };
  }, [int, isUpdateAvailable]);

  if (config?.maintenance) {
    return (
      <RootComponent>
        <Backdrop />
        <Content>
          <Img src={thumbUpGif} />
          <Heading>{t('maintenance.title')}</Heading>
          <Subheading>{t('maintenance.description')}</Subheading>
          <SocialNetworksWrapped ampli={false} />
        </Content>
      </RootComponent>
    );
  }

  if (isUpdateAvailable) {
    return (
      <RootComponent>
        <Backdrop />
        <Content>
          <Heading>{t('update.title')}</Heading>
          <Subheading>{t('update.description')}</Subheading>
          <UpdateButton disabled $loading>
            <SolidSpinner $size="30" />
          </UpdateButton>
        </Content>
      </RootComponent>
    );
  }

  if (!loadingState.loaded) {
    return (
      <RootComponent>
        <Loader />
      </RootComponent>
    );
  }

  return <>{children}</>;
};

export default AppStatus;
