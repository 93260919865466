import React, { ReactNode, useRef } from 'react';
import styled from 'styled-components';

import { FONT_NAME_ROBOTO, FONT_WEIGHT_MEDIUM } from '../styleConstants';

import CheckSvg from './check.svg';

const Label = styled.span`
  color: currentColor;
  font-family: ${FONT_NAME_ROBOTO};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-left: 0.5em;
`;

const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const RadioElementWrapper = styled.div<{ $checked: boolean }>`
  background: rgba(10, 16, 35, 0.5);
  border: 2px solid;
  border-color: ${($checked) => ($checked ? 'transparent' : 'rgba(10, 16, 35, 0.2)')};
  border-radius: 50%;
  height: 20px;
  position: relative;
  width: 20px;

  &:hover {
    border-color: rgba(211, 206, 239, 0.15);
  }
`;

const RadioElementIcon = styled(CheckSvg)`
  color: rgba(211, 206, 239, 0.5);
  height: 20px;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 20px;
`;

const WrappingLabel = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;

  &:focus-within {
    ${RadioElementWrapper} {
      border-color: rgba(211, 206, 239, 0.15);
    }
  }
`;

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  wrapperProps?: React.HTMLAttributes<HTMLLabelElement>;
}

const getID = (): string => `inpt_${Math.random().toString(36).substr(2, 9)}`;

const Radio = ({
  label,
  className,
  checked: controlledChecked,
  defaultChecked,
  disabled,
  id,
  wrapperProps,
  ...props
}: RadioProps) => {
  const uniqueID = useRef<string>(getID());
  const checkboxId = id || uniqueID.current;
  const checked = controlledChecked || defaultChecked;

  return (
    <WrappingLabel htmlFor={checkboxId} className={className} {...wrapperProps}>
      <RadioInput type="radio" id={checkboxId} disabled={disabled} checked={checked} {...props} />
      <RadioElementWrapper $checked={!!checked}>
        {checked && <RadioElementIcon />}
      </RadioElementWrapper>
      {label && <Label>{label}</Label>}
    </WrappingLabel>
  );
};

export { Radio };
