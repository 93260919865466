/* eslint-disable react/jsx-props-no-spreading */
import { Link } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import getExperiencePagePath from '@/paths/getExperiencePagePath';

import ExperienceBanner from '../../explore/ExperienceBanner';
import SessionContext from '../SessionContext';
import { UserParticipationComponentProps } from './UserParticipationComponent';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_FAMILY_ROBOTO, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR, PartyCard } from '../ui';

const Root = styled(Link)`
  display: flex;
  text-decoration: none;
  padding: 20px;
`;

const CardWrapper = styled.div`
  flex-shrink: 0;
  width: 105px;
`;

const Content = styled.div`
  margin-left: 16px;
`;

const Subtitle = styled.div`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 12px;
  line-height: 16px;
  color: rgba(211, 206, 239, 0.5);
`;

const SubtitleItem = styled.div`
  display: inline-block;
  &:not(:last-of-type) {
    &::after {
      display: inline-block;
      content: '•';
      padding: 0 0.5em;
      opacity: 0.5;
    }
  }
`;

const Title = styled.div`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;

const Meta = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 13px;
  letter-spacing: -0.02em;
  margin-top: 6px;
  margin-bottom: 2px;
`;

const GreenMeta = styled(Meta)`
  color: #7ed754;
`;

const YellowMeta = styled(Meta)`
  color: #fbc02d;
`;

const UserExperienceParticipationComponent = ({ experience, ...props }: UserParticipationComponentProps) => {
  const { t } = useTranslation(['userParticipations', 'exp2']);
  const { user } = useContext(SessionContext);

  return (
    <Root to={getExperiencePagePath(experience.id)} {...props}>
      <CardWrapper>
        {experience.leaders.length === 0 && (
          <PartyCard avatar={experience.owners[0].avatarURL} background={experience.template.banner_url} />
        )}
        {experience.leaders.length === 1 && (
          <PartyCard avatar={experience.leaders[0].avatarURL} background={experience.template.banner_url} />
        )}
        {experience.leaders.length === 2 && (
          <ExperienceBanner leftImage={experience.leaders[0].avatarURL!} rightImage={experience.leaders[1].avatarURL} />
        )}
      </CardWrapper>
      <Content>
        <Title>
          {t('showmatch.title', {
            challenger: experience.leaders[0],
            rival: experience.leaders[1],
          })}
        </Title>
        <Subtitle>
          <SubtitleItem>{t(`exp2:${experience.template.name}.name`)}</SubtitleItem>
          <SubtitleItem>{t('showmatch.startsAt', { date: experience.startAt })}</SubtitleItem>
        </Subtitle>
        {(() => {
          if (
            experience.checkIn.isActive &&
            experience.findContestantById(user?.uid) &&
            !experience.checkIn.hasParticipant(user?.uid)
          ) {
            return <YellowMeta>{t('showmatch.checkIn')}</YellowMeta>;
          }

          if (Boolean(experience.findActiveReadyCheckByMember(experience.findMemberById(user?.uid)?.id))) {
            return <GreenMeta>{t('showmatch.readyCheck')}</GreenMeta>;
          }

          if (experience.isPublished) {
            return <YellowMeta>{t('showmatch.published')}</YellowMeta>;
          }

          if (experience.isDraft) {
            return <YellowMeta>{t('showmatch.draft')}</YellowMeta>;
          }

          return <GreenMeta>Live</GreenMeta>;
        })()}
      </Content>
    </Root>
  );
};
export default UserExperienceParticipationComponent;
