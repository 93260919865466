import { navigate } from '@reach/router';
import React from 'react';
import * as Sentry from '@sentry/react';

import { errorToast } from './common/Toast';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    errorToast(
      'Unexpected error occured, we redirected you to the safe place. Please contact support if needed.',
    );
    navigate('/');
    this.setState({ hasError: false });
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;

    if (hasError) {
      return null;
    }

    return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
  }
}

export default ErrorBoundary;
