import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from '../styleConstants';

const SystemMessageText = styled.div`
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 16px;
  color: white;
  text-align: left;
  opacity: 0.35;
  color: #d3ceef;
  font-size: 12px;
  margin-top: 4px;
`;

interface SystemMessageProps {
  children: React.ReactNode;
}

export const SystemMessage = ({ children, ...props }: SystemMessageProps) => (
  <SystemMessageText {...props}>{children}</SystemMessageText>
);
