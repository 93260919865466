import { getPlainForgotPasswordPath } from './getForgotPasswordPath';
import { getPlainLogInPath } from './getSignInPath';

export const getPlainSignUpPath = () => '/sign-up';

const getSignUpPath = (): string => {
  const { hash, pathname, search } = window.location;
  const redirectURL = `${pathname}${search}${hash}`;
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('redirectURL')) {
    return `${getPlainSignUpPath()}?${searchParams.toString()}`;
  }

  if (!['/', getPlainLogInPath(), getPlainSignUpPath(), getPlainForgotPasswordPath()].includes(pathname)) {
    searchParams.append('redirectURL', redirectURL);
  }
  return `${getPlainSignUpPath()}${searchParams.toString() ? `?${searchParams.toString()}` : searchParams.toString()}`;
};

export default getSignUpPath;
