import styled, { css } from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from './ui';

export type TextFieldType = 'default' | 'light' | 'dark';

interface TextFieldProps {
  $appearance?: TextFieldType;
  $disabled?: boolean;
}

const BACKGROUND: Record<TextFieldType, string> = {
  default: 'rgba(10, 16, 35, 0.5)',
  light: 'rgba(211, 206, 239, 0.1)',
  dark: 'rgba(10, 16, 35, 0.1)',
};

const BOX_SHADOW_FOCUS: Record<TextFieldType, string> = {
  default: 'rgba(211, 206, 239, 0.35)',
  light: 'rgba(211, 206, 239, 0.35)',
  dark: 'rgba(10, 16, 35, 0.35)',
};

const BOX_SHADOW_HOVER: Record<TextFieldType, string> = {
  default: 'rgba(211, 206, 239, 0.2)',
  light: 'rgba(211, 206, 239, 0.2)',
  dark: 'rgba(10, 16, 35, 0.2)',
};

const TEXT_COLOR: Record<TextFieldType, string> = {
  default: 'rgba(255, 255, 255, 0.8)',
  light: 'rgba(255, 255, 255, 0.8)',
  dark: 'rgba(10, 16, 35, 1)',
};

const PLACEHOLDER_COLOR: Record<TextFieldType, string> = {
  default: 'rgba(211, 206, 239, 0.35)',
  light: 'rgba(211, 206, 239, 0.5)',
  dark: 'rgba(10, 16, 35, 0.35)',
};

const TextField = styled.input<TextFieldProps>`
  background: ${({ $appearance }) => BACKGROUND[$appearance!]};
  border: none;
  border-radius: 10px;
  color: ${({ $appearance }) => TEXT_COLOR[$appearance!]};
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 16px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  height: 40px;
  padding: 0 15px;
  width: 100%;

  &::placeholder {
    color: ${({ $appearance }) => PLACEHOLDER_COLOR[$appearance!]};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ $appearance }) => BOX_SHADOW_FOCUS[$appearance!]};
  }

  &:hover:not(:focus) {
    box-shadow: 0 0 0 2px ${({ $appearance }) => BOX_SHADOW_HOVER[$appearance!]};
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
          user-select: none;
        `
      : ''}

  &:disabled {
    opacity: 0.5;
    ${({ onClick }) =>
      onClick
        ? ''
        : css`
            user-select: none;
            pointer-events: none;
          `}
  }
`;

TextField.defaultProps = {
  $appearance: 'default',
};

export default TextField;
