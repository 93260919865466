import { navigate } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BackButton from '@/common/BackButton';
import Button from '@/common/Button';
import ClientErrorCode from '@/common/ClientErrorCode';
import Field from '@/common/Field';
import { ForgotPasswordErrorCode } from '@/common/types';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import getSignInPath from '@/paths/getSignInPath';
import Label from '@/common/Label';
import PrimaryHeading from '@/common/PrimaryHeading';
import PrimaryParagraph from '@/common/PrimaryParagraph';
import TextField from '@/common/TextField';
import useForgotPasswordForm from '@/hooks/useForgotPasswordForm';
import FormError, { FormErrorType } from '@/common/FormError';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
  align-items: center;
  background: rgba(43, 38, 96, 0.5);
`;

const HeaderWrapper = styled.div`
  margin-top: 10px;
`;

const PrimaryParagraphWrapper = styled.div`
  margin-top: 15px 0 0;
`;

const FormErrorWrapper = styled.div`
  margin-top: 20px;
`;

const FormWrapper = styled.div`
  margin-top: 20px;
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EMAIL_INPUT_ID = 'forgotPasswordFormEmail';

const ForgotPasswordForm = () => {
  const { t } = useTranslation('auth');
  const formError: Record<number, FormErrorType> = {
    [ForgotPasswordErrorCode.UserNotFound]: t('forgotPasswordForm.errorMessage.userNotFound'),
    [ForgotPasswordErrorCode.InvalidEmail]: t('forgotPasswordForm.errorMessage.invalidEmail'),
    [ClientErrorCode.NoInternetOrServerConnection]: t('forgotPasswordForm.errorMessage.noConnection'),
    [ClientErrorCode.UnknownError]: t('forgotPasswordForm.errorMessage.unknownError'),
  };
  const { email, setEmail, loadingState, error, resetPassword } = useForgotPasswordForm();
  return (
    <Container>
      <Wrapper>
        {loadingState.loaded ? (
          <ForgotPasswordSuccess email={email} />
        ) : (
          <>
            <BackButton onClick={() => navigate(getSignInPath())}>{t('forgotPasswordForm.backButton')}</BackButton>
            <HeaderWrapper>
              <PrimaryHeading as="h3">{t('forgotPasswordForm.heading')}</PrimaryHeading>
            </HeaderWrapper>
            <PrimaryParagraphWrapper>
              <PrimaryParagraph>{t('forgotPasswordForm.description')}</PrimaryParagraph>
            </PrimaryParagraphWrapper>
            {loadingState.loadFailed && (
              <FormErrorWrapper>
                <FormError error={formError[error!.code]} />
              </FormErrorWrapper>
            )}
            <FormWrapper>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  resetPassword();
                }}
              >
                <Field label={<Label htmlFor={EMAIL_INPUT_ID}>{t('forgotPasswordForm.emailLabel')}</Label>}>
                  <TextField
                    id={EMAIL_INPUT_ID}
                    disabled={loadingState.loading}
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Field>
                <Buttons>
                  <Button loading={loadingState.loading} htmlType="submit" type="opaque" size="large">
                    {t('forgotPasswordForm.submitButton')}
                  </Button>
                </Buttons>
              </form>
            </FormWrapper>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default ForgotPasswordForm;
