import { HandleSteamConnectErrorCode } from '@/common/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '@/common/Button';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '@/common/ui';
import DefaultErrorView from '../DefaultErrorView';
import ErrorPageProps from '../ErrorPageProps';

const Content = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorRoot = styled.div`
  text-align: center;
  max-width: 700px;
`;

const CloseButton = styled(Button)`
  margin-top: 30px;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorHeader = styled.h1`
  color: rgba(233, 30, 99, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 32px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  line-height: 70px;
`;

const ErrorReasonHeader = styled.h2`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 20px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  line-height: 35px;
  margin-top: 20px;
`;

const ErrorReasonsList = styled.ul`
  max-width: 400px;
`;

const ErrorReasonsItem = styled.li`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
`;

const ErrorReasonsItemLink = styled.a`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  color: #fff;
`;

const ErrorMessageKey: Record<number, string> = {
  [HandleSteamConnectErrorCode.SteamProfileIsNotPublic]: 'steamProfileIsNotPublic',
  [HandleSteamConnectErrorCode.SteamAccountIsVacBanned]: 'steamAccountIsVacBanned',
  [HandleSteamConnectErrorCode.CounterStrikeIsNotPurchased]: 'counterStrikeIsNotPurchased',
  [HandleSteamConnectErrorCode.NotEnoughTimePlayed]: 'notEnoughTimePlayed',
  [HandleSteamConnectErrorCode.PlayerNotFoundError]: 'playerNotFoundError',
  [HandleSteamConnectErrorCode.SteamAccountIsBannedByPlatform]: 'steamAccountIsBannedByPlatform',
  [HandleSteamConnectErrorCode.ExternalAccountAlreadyInUse]: 'externalAccountAlreadyInUse',
  [HandleSteamConnectErrorCode.ProfileNotFound]: 'profileNotFound',
};

const SteamConnectErrorPage = ({ errorCode }: ErrorPageProps) => {
  const { t } = useTranslation('errors');

  if (
    [
      HandleSteamConnectErrorCode.ExternalAccountAlreadyInUse,
      HandleSteamConnectErrorCode.PlayerNotFoundError,
      HandleSteamConnectErrorCode.ProfileNotFound,
      HandleSteamConnectErrorCode.SteamAccountIsBannedByPlatform,
    ].includes(errorCode)
  ) {
    return (
      <DefaultErrorView
        heading={t('steamConnectErrorPage.heading')}
        description={t(`steamConnectErrorPage.errorCodes.${ErrorMessageKey[errorCode]}`)}
      />
    );
  }

  return (
    <Content>
      <ErrorRoot>
        <ErrorContainer>
          <ErrorHeader>{t('steamConnectErrorPage.heading')}</ErrorHeader>
          <ErrorReasonHeader>{t('steamConnectErrorPage.reasonHeading')}</ErrorReasonHeader>
          <ErrorReasonsList>
            {errorCode === HandleSteamConnectErrorCode.SteamProfileIsNotPublic && (
              <ErrorReasonsItem>
                <Trans
                  t={t}
                  i18nKey={`steamConnectErrorPage.errorCodes.${
                    ErrorMessageKey[HandleSteamConnectErrorCode.SteamProfileIsNotPublic]
                  }`}
                  components={{
                    lnk: <ErrorReasonsItemLink target="_blank" href="https://steamcommunity.com/my/edit/settings" />,
                  }}
                />
              </ErrorReasonsItem>
            )}
            {errorCode <= HandleSteamConnectErrorCode.SteamAccountIsVacBanned && (
              <ErrorReasonsItem>
                {t(
                  `steamConnectErrorPage.errorCodes.${
                    ErrorMessageKey[HandleSteamConnectErrorCode.SteamAccountIsVacBanned]
                  }`
                )}
              </ErrorReasonsItem>
            )}
            {errorCode <= HandleSteamConnectErrorCode.CounterStrikeIsNotPurchased && (
              <ErrorReasonsItem>
                {t(
                  `steamConnectErrorPage.errorCodes.${
                    ErrorMessageKey[HandleSteamConnectErrorCode.CounterStrikeIsNotPurchased]
                  }`
                )}
              </ErrorReasonsItem>
            )}
            {errorCode <= HandleSteamConnectErrorCode.NotEnoughTimePlayed && (
              <ErrorReasonsItem>
                <Trans
                  t={t}
                  i18nKey={`steamConnectErrorPage.errorCodes.${
                    ErrorMessageKey[HandleSteamConnectErrorCode.NotEnoughTimePlayed]
                  }`}
                  components={{
                    lnk: <ErrorReasonsItemLink target="_blank" href="https://steamcommunity.com/my/edit/settings" />,
                  }}
                />
              </ErrorReasonsItem>
            )}
          </ErrorReasonsList>
        </ErrorContainer>
        <CloseButton type="transparent" size="small" onClick={window.close}>
          {t('cancelButton')}
        </CloseButton>
      </ErrorRoot>
    </Content>
  );
};

export default SteamConnectErrorPage;
