import { FirestoreExperienceContestant } from './types';

export default class ExperienceContestantViewModel {
  id: string;
  contest: string;
  entries: {
    id: string;
    value: number;
    method: string;
    createdAt: Date;
  }[];
  totalValues: number;
  username: string;
  tag: string;
  avatarURL?: string;
  bannerURL?: string;
  title?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(props: {
    id: string;
    contest: string;
    entries: {
      id: string;
      value: number;
      method: string;
      createdAt: Date;
    }[];
    totalValues: number;
    username: string;
    tag: string;
    avatarURL?: string;
    bannerURL?: string;
    title?: string;
    createdAt: Date;
    updatedAt: Date;
  }) {
    this.id = props.id;
    this.contest = props.contest;
    this.entries = props.entries;
    this.totalValues = props.totalValues;
    this.username = props.username;
    this.tag = props.tag;
    this.avatarURL = props.avatarURL;
    this.bannerURL = props.bannerURL;
    this.title = props.title;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  static fromFirestore(data: FirestoreExperienceContestant) {
    return new ExperienceContestantViewModel({
      id: data.id,
      contest: data.contest,
      entries: data.entries.map((e) => ({
        id: e.id,
        method: e.method,
        value: e.value,
        createdAt: e.created_at.toDate(),
      })),
      totalValues: data.total_value,
      username: data.username,
      tag: data.tag,
      avatarURL: data.avatar_url ?? undefined,
      bannerURL: data.banner_url ?? undefined,
      title: data.title ?? undefined,
      createdAt: data.created_at.toDate(),
      updatedAt: data.updated_at.toDate(),
    });
  }

  get hasUsedFreeMethod() {
    return this.entries.some((e) => e.method === 'free'); // TODO: do better
  }
}
