import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../ui';

const Button = styled.button<{ $active?: boolean }>`
  align-items: center;
  background: none;
  border: none;
  border-radius: 20px;
  color: ${({ $active }) => ($active ? 'rgba(43, 38, 96, 1)' : 'rgba(255, 255, 255, 1)')};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  padding: 0 11px;
  position: relative;
  transition: all 300ms ease-out;

  svg {
    height: 18px;
    fill: currentColor;
    transition: all 300ms ease-out;
    width: 18px;
  }

  span {
    font-family: ${FONT_FAMILY_BRUTAL_TYPE};
    font-size: 17px;
    font-weight: ${FONT_WEIGHT_MEDIUM};
  }

  &::before {
    content: '';
    background: ${({ $active }) =>
      $active ? 'rgba(255, 255, 255, 1)' : 'rgba(211, 206, 239, 0.15)'};
    bottom: 0;
    border-radius: 20px;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 300ms ease-out;
    right: 0;
    z-index: -1;
  }

  /* &:hover,
  &:focus {
    &::before {
      transform: scale(1.1);
    }
  } */

  &:not(:active) {
    &:hover,
    &:focus {
      &::before {
        background: ${({ $active }) =>
          $active ? 'rgba(255, 255, 255, 1)' : 'rgba(211, 206, 239, 0.25)'};
      }
    }
  }

  &:active {
    color: rgba(43, 38, 96, 1);

    &::before {
      background: rgba(255, 255, 255, 1);
    }
  }
`;

export interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const MenuButton = React.forwardRef(
  ({ active, ...props }: MenuButtonProps, ref: React.Ref<any>) => (
    <Button $active={active} ref={ref} {...props} />
  ),
);
export default MenuButton;
