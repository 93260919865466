import { Link } from '@reach/router';
import React, { MouseEventHandler, ReactNode, RefCallback, RefObject } from 'react';
import styled, { keyframes } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE } from './styleConstants';

export type ButtonType =
  | 'coloredOpaque'
  | 'opaque'
  | 'transparent'
  | 'text'
  | 'success'
  | 'primary'
  | 'secondary';

export type ButtonSize = 'larger' | 'large' | 'base' | 'small';

interface StyledButtonProps {
  $buttonType: ButtonType;
  $buttonSize: ButtonSize;
  $loading: boolean;
}

const BACKGROUND: Record<ButtonType, string> = {
  coloredOpaque: '#ede7f6',
  opaque: '#ede7f6',
  transparent: 'rgba(211, 206, 239, 0.15)',
  success: 'linear-gradient(#7ED754, #1DB553)',
  text: 'none',
  primary: 'linear-gradient(#03A9F4, #2F73D4, #5E35B1)',
  secondary: 'linear-gradient(#00BCD4, #0277BD)',
};

const BORDER_RADIUS: Record<ButtonSize, string> = {
  larger: '100px',
  large: '100px',
  base: '100px',
  small: '100px',
};

const BOX_SHADOW: Record<ButtonType, string> = {
  coloredOpaque: 'inset 0 2px 0 rgba(255, 255, 255, 0.2), 0 2px 2px rgba(16, 9, 48, 0.25)',
  opaque: 'inset 0 2px 0 #fff, 0 2px 2px rgba(16, 9, 48, 0.25)',
  transparent: 'none',
  success: 'inset 0 2px 0 rgba(255, 255, 255, 0.2), 0 1px 1px rgba(126, 215, 84, 0.15)',
  text: 'none',
  primary: 'inset 0 2px 0 rgba(255, 255, 255, 0.2)',
  secondary: 'inset 0 2px 0 rgba(255, 255, 255, 0.2)',
};

const COLOR: Record<ButtonType, string> = {
  coloredOpaque: '#673ab7',
  opaque: '#673ab7',
  transparent: 'rgba(255, 255, 255, 0.8)',
  success: '#0A1023',
  text: 'rgba(255, 255, 255, 0.5)',
  primary: '#fff',
  secondary: '#fff',
};

const FONT_SIZE: Record<ButtonSize, string> = {
  larger: '20px',
  large: '20px',
  base: '16px',
  small: '14px',
};

const FONT_WEIGHT: Record<ButtonSize, number> = {
  larger: 500,
  large: 500,
  base: 500,
  small: 700,
};

const HEIGHT: Record<ButtonSize, string> = {
  larger: '50px',
  large: '40px',
  base: '40px',
  small: '30px',
};

const LINE_HEIGHT: Record<ButtonSize, string> = {
  larger: '30px',
  large: '30px',
  base: '24px',
  small: '18px',
};

const PADDING: Record<ButtonSize, string> = {
  larger: '0 40px',
  large: '0 30px',
  base: '0 30px',
  small: '0 15px',
};

const BACKGROUND_HOVER: Record<ButtonType, string> = {
  coloredOpaque: '#fff',
  opaque: '#fff',
  transparent: 'rgba(211, 206, 239, 0.2)',
  text: 'none',
  success: 'linear-gradient(#ABEA7F, #1DC461)',
  primary: 'linear-gradient(#12B4FF, #277CF2, #6A2ED1)',
  secondary: 'linear-gradient(#4DD0E1, #0288D1)',
};

const COLOR_HOVER: Record<ButtonType, string> = {
  coloredOpaque: '#673ab7',
  opaque: '#673ab7',
  transparent: 'rgba(255, 255, 255, 0.95)',
  text: 'rgba(255, 255, 255, 0.65)',
  success: '#0A1023',
  primary: '#fff',
  secondary: '#fff',
};

export const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  justify-content: center;
  background: ${({ $buttonType }) => BACKGROUND[$buttonType]};
  border: none;
  border-radius: ${({ $buttonSize }) => BORDER_RADIUS[$buttonSize]};
  box-shadow: ${({ $buttonType }) => BOX_SHADOW[$buttonType]};
  color: ${({ $buttonType, $loading }) => ($loading ? 'transparent' : COLOR[$buttonType])};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  display: inline-flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: ${({ $buttonSize }) => FONT_SIZE[$buttonSize]};
  font-weight: ${({ $buttonSize }) => FONT_WEIGHT[$buttonSize]};
  height: ${({ $buttonSize }) => HEIGHT[$buttonSize]};
  line-height: ${({ $buttonSize }) => LINE_HEIGHT[$buttonSize]};
  padding: ${({ $buttonSize }) => PADDING[$buttonSize]};
  text-decoration: none;

  &:focus:not(:focus-visible),
  &:hover {
    background: ${({ $buttonType, disabled }) => !disabled && BACKGROUND_HOVER[$buttonType]};
    color: ${({ $buttonType, disabled }) => !disabled && COLOR_HOVER[$buttonType]};
  }
`;

const SpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface SpinnerProps {
  $buttonSize: ButtonSize;
  $buttonType: ButtonType;
}

const SPINNER_COLOR: Record<ButtonType, string> = {
  coloredOpaque: '103, 58, 183',
  opaque: '103, 58, 183',
  transparent: '211, 206, 239',
  text: '255, 255, 255',
  success: '255, 255, 255',
  primary: '255, 255, 255',
  secondary: '255, 255, 255',
};

const SPINNER_SIZE: Record<ButtonSize, string> = {
  larger: '16px',
  large: '16px',
  base: '12px',
  small: '12px',
};

const Spinner = styled.div<SpinnerProps>`
  animation: ${SpinnerAnimation} 1s ease infinite;
  border-bottom: 2px solid rgba(${({ $buttonType }) => SPINNER_COLOR[$buttonType]}, 0.6);
  border-left: 2px solid rgba(${({ $buttonType }) => SPINNER_COLOR[$buttonType]}, 0.6);
  border-right: 2px solid rgba(${({ $buttonType }) => SPINNER_COLOR[$buttonType]}, 0.6);
  border-top: 2px solid rgba(${({ $buttonType }) => SPINNER_COLOR[$buttonType]}, 1);
  border-radius: 50%;
  display: inline-flex;
  height: ${({ $buttonSize }) => SPINNER_SIZE[$buttonSize]};
  width: ${({ $buttonSize }) => SPINNER_SIZE[$buttonSize]};
  margin: 0 auto;
`;

const Content = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

export type ButtonHtmlType = 'button' | 'reset' | 'submit';

export interface IButtonProps {
  className?: string;
  href?: string;
  rel?: string;
  target?: string;
  type?: ButtonType;
  size?: ButtonSize;
  htmlType?: ButtonHtmlType;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  loading?: boolean;
  children: ReactNode;
  ref?: RefCallback<any> | RefObject<any> | null;
}

export const Button = ({
  className,
  type = 'transparent',
  size = 'small',
  href,
  htmlType = 'button',
  onClick,
  disabled,
  loading,
  children,
  ...props
}: IButtonProps) => {
  const inner = (
    <Content>
      {children}
      {Boolean(loading) && (
        <SpinnerWrapper>
          <Spinner $buttonSize={size} $buttonType={type} />
        </SpinnerWrapper>
      )}
    </Content>
  );
  if (href) {
    return (
      <StyledButton
        as={Link}
        to={href}
        className={className}
        $buttonType={type}
        $buttonSize={size}
        onClick={onClick}
        $loading={Boolean(loading)}
        {...props}
      >
        {inner}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      className={className}
      disabled={disabled || loading}
      $buttonType={type}
      $buttonSize={size}
      type={htmlType}
      onClick={onClick}
      $loading={Boolean(loading)}
      {...props}
    >
      {inner}
    </StyledButton>
  );
};
