import { useState, useEffect, useRef } from 'react';

const useDropdown = (defaultState = false) => {
  const triggerRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const [isShown, setIsShown] = useState(() => defaultState);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isShown && event.code === 'Escape') {
      setIsShown(false);
    }
  };

  const handleClick = (event: MouseEvent) => {
    if (isShown) {
      const target = event.target as (Node & ParentNode) | null;

      if (!dropdownRef.current.contains(target) && !triggerRef.current?.contains(target)) {
        setIsShown(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, [isShown]);

  const toggleShown = () => {
    setIsShown(!isShown);
  };

  return { triggerRef, dropdownRef, isShown, toggleShown };
};

export default useDropdown;
