import SweepstakeEntriesBundle from './SweepstakeEntriesBundle';

const SweepstakeEntriesBundleSize: Record<SweepstakeEntriesBundle, number> = {
  [SweepstakeEntriesBundle.Free]: 1,
  [SweepstakeEntriesBundle.XSmall]: 5,
  [SweepstakeEntriesBundle.Small]: 25,
  [SweepstakeEntriesBundle.Medium]: 150,
  [SweepstakeEntriesBundle.Large]: 1000,
  [SweepstakeEntriesBundle.ManyLarge]: 5000,
};

export default SweepstakeEntriesBundleSize;
