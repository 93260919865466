import React from 'react';
import styled, { css } from 'styled-components';

import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from '@/common/ui';

const spanToPercent = (span: number) => (span / 24) * 100;

const ColEl = styled.div<{
  $span?: number;
  $xs?: number;
  $sm?: number;
  $md?: number;
  $lg?: number;
  $flex?: string | number;
}>`
  position: relative;
  max-width: 100%;
  min-height: 1px;

  ${({ $span }) =>
    $span &&
    css`
      display: block;
      max-width: ${spanToPercent($span)}%;
      flex: 0 0 ${spanToPercent($span)}%;
    `}

  ${({ $flex }) =>
    $flex &&
    (typeof $flex === 'number'
      ? `
        flex: 0 0 ${$flex};
      `
      : `
        flex: 1 1 ${$flex};
      `)}

  @media (max-width: ${BREAKPOINT_EXTRA_SMALL}px) {
    ${({ $xs }) =>
      $xs &&
      $xs > 0 &&
      `
        max-width: ${spanToPercent($xs)}%;
        flex: 0 0 ${spanToPercent($xs)}%;
      `}
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    ${({ $sm }) =>
      $sm &&
      $sm > 0 &&
      `
        max-width: ${spanToPercent($sm)}%;
        flex: 0 0 ${spanToPercent($sm)}%;
      `}
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    ${({ $md }) =>
      $md &&
      $md > 0 &&
      `
        max-width: ${spanToPercent($md)}%;
        flex: 0 0 ${spanToPercent($md)}%;
      `}
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    ${({ $lg }) =>
      $lg &&
      $lg > 0 &&
      `
        max-width: ${spanToPercent($lg)}%;
        flex: 0 0 ${spanToPercent($lg)}%;
      `}
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  span?: number;
  flex?: string | number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

const Col = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { children, span, flex, xs, sm, md, lg, xl, xxl, ...rest } = props;
  return (
    <ColEl $span={span} $flex={flex} $xs={xs} $sm={sm} $md={md} $lg={lg} ref={ref as any} {...rest}>
      {children}
    </ColEl>
  );
});

export default Col;
