import React from 'react';
import styled from 'styled-components';

const ImageEl = styled.img`
  height: auto;
`;

interface Props {
  src: string;
  width?: number;
}

const Image: React.FC<Props> = (props) => {
  const { src, width, ...rest } = props;
  return <ImageEl src={src} width={width} {...rest} />;
};

export default Image;
