import React from 'react';
import styled from 'styled-components';

import SuccessSvg from './success.svg';
import TransparentSuccessSvg from './transparent-success.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 140px;
`;

const LeftTeamWrapper = styled(TeamWrapper)`
  justify-content: flex-end;
`;

const Success = styled.div`
  border-radius: 50px;
  width: 100px;
  height: 100px;
  border: 4px solid #1db553;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
`;

const AcceptedStatus = styled.div`
  width: 20px;
  height: 20px;
  background-color: rgba(29, 181, 83, 10%);
  fill: rgba(29, 181, 83);
  border-radius: 50%;

  margin-left: 10px;

  &:nth-child(${5}n + 1) {
    margin-left: 0;
  }

  &:nth-child(n + 6) {
    margin-top: 5px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface Team {
  [key: string]: boolean | undefined;
}

interface ReadyCheckSuccessProps {
  leftTeam: Team;
  rightTeam: Team;
}

export const ReadyCheckSuccess = ({ leftTeam, rightTeam }: ReadyCheckSuccessProps) => (
  <Wrapper>
    <LeftTeamWrapper>
      {Object.keys(leftTeam).map((playerId) => (
        <AcceptedStatus key={playerId}>
          <SuccessSvg />
        </AcceptedStatus>
      ))}
    </LeftTeamWrapper>

    <Success>
      <TransparentSuccessSvg />
    </Success>

    <TeamWrapper>
      {Object.keys(rightTeam).map((playerId) => (
        <AcceptedStatus key={playerId}>
          <SuccessSvg />
        </AcceptedStatus>
      ))}
    </TeamWrapper>
  </Wrapper>
);
