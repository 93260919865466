import ExperienceMemberViewModel from '../ExperienceMemberViewModel';
import { FirestoreExperienceTemplate } from '../template/types';

import TeamSeatViewModel from './TeamSeatViewModel';
import { FirestoreTeam } from './types';

export default class TeamViewModel {
  readonly id: string;
  readonly name: string;
  readonly seats: TeamSeatViewModel[];
  readonly stats?: { [key: string]: number };

  constructor(props: {
    id: string;
    seats: TeamSeatViewModel[];
    name: string;
    stats?: { [key: string]: number };
  }) {
    this.id = props.id;
    this.name = props.name;
    this.seats = props.seats;
    this.stats = props.stats;
  }

  get size() {
    return this.seats.length;
  }

  get freeSeats(): TeamSeatViewModel[] {
    return this.seats.filter((seat) => seat.isFree);
  }

  get takenSeats(): TeamSeatViewModel[] {
    return this.seats.filter((seat) => seat.isTaken);
  }

  get reservedSeats(): TeamSeatViewModel[] {
    return this.seats.filter((seat) => seat.isReserved);
  }

  get members(): ExperienceMemberViewModel[] {
    return this.takenSeats.map((seat) => seat.user!);
  }

  get leaders(): ExperienceMemberViewModel[] {
    return this.members.filter((member) => member.isLeader);
  }

  get contests(): string[] {
    const contestsSet = new Set<string>([]);
    this.seats.forEach((seat) => seat.isContestable && contestsSet.add(seat.contest!));
    return Array.from(contestsSet);
  }

  static fromFirestore(
    data: FirestoreTeam,
    template: FirestoreExperienceTemplate,
    entities: {
      members: Record<string, ExperienceMemberViewModel>;
      playerStats: Partial<Record<string, { [key: string]: number }>>;
      teamStats: Partial<Record<string, { [key: string]: number }>>;
    },
  ) {
    const templateTeam = template.teams.find((t) => t.id === data.id)!;

    return new TeamViewModel({
      id: data.id,
      name: templateTeam.name,
      seats: data.seats.map((seat) => {
        const templateSeat = templateTeam.seats.find((s) => s.id === seat.id)!;
        return TeamSeatViewModel.fromFirestore(seat, templateSeat, entities);
      }),
      stats: entities.teamStats[data.id],
    });
  }

  findSeatById(id?: string): TeamSeatViewModel | undefined {
    return this.seats.find((seat) => seat.id === id);
  }

  findSeatByMemberId(id?: string): TeamSeatViewModel | undefined {
    return this.seats.find((seat) => seat.user?.id === id);
  }

  get isAssembled(): boolean {
    return this.members.length === this.seats.length;
  }
}
