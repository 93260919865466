import React from 'react';
import styled from 'styled-components';

import ChattingSvg from './chatting.svg';
import CSGOSvg from './csgo.svg';
import LOLSvg from './league.svg';
import OtherSvg from './other.svg';
import CODSvg from './warzone.svg';

export enum BadgeGames {
  CSGO = 'counter-strike-global-offensive',
  Fortnite = 'fortnite',
  Valorant = 'valorant',
  Warzone = 'warzone',
  Chess = 'chess',
  LeagueOfLegends = 'leagueOfLegends',
  JustChatting = 'justChatting',
  OtherGames = 'otherGames',
}

const GameIcon = {
  [BadgeGames.CSGO]: CSGOSvg,
  [BadgeGames.Fortnite]: undefined,
  [BadgeGames.Valorant]: undefined,
  [BadgeGames.Warzone]: CODSvg,
  [BadgeGames.Chess]: undefined,
  [BadgeGames.LeagueOfLegends]: LOLSvg,
  [BadgeGames.JustChatting]: ChattingSvg,
  [BadgeGames.OtherGames]: OtherSvg,
};

const BadgeWrapper = styled.div<{ $game: BadgeGames }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface BadgeProps {
  game: BadgeGames;
}

const Reclama = () => <div>Место для рекламы</div>;

export const GameBadge = ({ game, ...props }: BadgeProps) => {
  const Icon = GameIcon[game] || Reclama;

  return (
    <BadgeWrapper $game={game} {...props}>
      <Icon />
    </BadgeWrapper>
  );
};
