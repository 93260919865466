import config from '~/.ampli.json';
import amplitude, { AmplitudeClient } from 'amplitude-js';

const API_KEY = config?.[process.env.NODE_ENV]?.API_KEY;

class Ampli {
  private client?: AmplitudeClient;
  isReady: boolean;

  constructor() {
    this.isReady = false;
  }

  init(): Promise<void> {
    return new Promise((resolve) => {
      amplitude.getInstance().init(API_KEY, '', {}, (clientInstance) => {
        this.client = clientInstance;
        this.isReady = true;
      });
      resolve();
    });
  }

  setUserId(userId: string): void {
    try {
      this.client?.setUserId(userId);
    } catch (err: any) {}
  }

  log(event: string, data?: any): void {
    try {
      if (this.isReady && event) {
        this.client?.logEvent(event, data);
      }
    } catch (err: any) {}
  }
}

export default new Ampli();
