import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '@/common/ui';

const IconEl = styled.div`
  background: rgb(86, 177, 94);
  border-radius: 15px;
  color: rgb(255, 255, 255);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  text-transform: uppercase;
`;

const IconNew: React.FC = () => {
  const { t } = useTranslation();
  return <IconEl>{t('new')}</IconEl>;
};

export default IconNew;
