import { Timestamp } from 'firebase/firestore';

import { FirestoreCheckIn } from './checkIn/types';
import FirestoreGame from './game/FirestoreGame';
import { FirestoreReadyCheck } from './readyCheck/types';
import { FirestoreExperienceServer } from './server/types';
import { FirestoreTeam } from './team/types';
import { ExperienceStrategyType, FirestoreExperienceTemplate } from './template/types';

export enum ExperienceStatus {
  Draft = 'draft',
  Published = 'published',
  Failed = 'failed',
  Finished = 'finished',
  Live = 'live',
}

export enum FailureCause {
  Cancel = 'cancel',
  Contest = 'contest',
  Game = 'game',
  Timeout = 'timeout',
  PublishRequirement = 'publish_requirement',
}

export enum MemberRole {
  Owner = 'owner',
  Leader = 'leader',
  Beneficiary = 'beneficiary',
  Player = 'player',
  Spectator = 'spectator',
}

export enum InvitationStatus {
  Active = 'active',
  Used = 'used',
  Revoked = 'revoked',
}

export interface ExperienceMemberRole {
  id: MemberRole;
  permissions: number;
}

export enum MemberPermission {
  PlayGame = 1 << 0,
  SpectateGame = 1 << 1,
  SharePrizePool = 1 << 2,
  ManageExperience = 1 << 3,
  ManageTeams = 1 << 4,
  ManageTeam = 1 << 5,
  ManageMethods = 1 << 6,
  ManagePersonalMethods = 1 << 7,
}

export enum FirestoreStatusGroup {
  Unknown = 'unknown',
  Upcoming = 'upcoming',
  Past = 'past',
}

export interface ServerConfig {
  [key: string]: string;
}

export interface FirestoreExperience {
  id: string;
  title?: string;
  template: FirestoreExperienceTemplate;
  check_in: FirestoreCheckIn;
  game: FirestoreGame;
  status: ExperienceStatus;
  teams: FirestoreTeam[];
  start_at: Timestamp;
  members: FirestoreExperienceMember[];
  strategy: FirestoreExperienceStrategy;
  server?: FirestoreExperienceServer;
  created_at: Timestamp;
  updated_at: Timestamp;
  finished_at: Timestamp | null;
  failure: {
    failed_at: Timestamp;
    type: FailureCause;
  } | null;
  contestants?: FirestoreExperienceContestant[];
  ready_checks?: FirestoreReadyCheck[];
  invitations?: ExperienceInvitation[];
  chat?: string;
  private_status_group: FirestoreStatusGroup;
  public_status_group: FirestoreStatusGroup;
  server_config?: ServerConfig;
  team_stats?: {
    team: string;
    stats: { [key: string]: number };
  }[];
  player_stats?: {
    user: string;
    stats: { [key: string]: number };
  }[];
  pools?: {
    chat_id: string;
    contest: string;
    contestants?: string[];
    next: number;
  }[];
  replay_url?: string;
  tournament?: FirestoreExperienceTournamentInfo;
}

export interface FirestoreExperienceContestant {
  id: string;
  check_in: Timestamp | null;
  contest: string;
  entries: {
    id: string;
    value: number;
    method: string;
    created_at: Timestamp;
  }[];
  total_value: number;
  created_at: Timestamp;
  updated_at: Timestamp;
  avatar_url?: string | null;
  banner_url?: string | null;
  tag: string;
  title: string | null;
  username: string;
}

export interface ExperienceMemberGamingAccount {
  game_id: string;
  steam?: {
    username: string;
    id: string;
    id64: string;
  };
  battle_net?: {
    id: string;
    battle_tag: string;
  };
  custom?: {
    username: string;
    platform?: string;
  };
}

export interface FirestoreExperienceMember {
  id: string;
  avatar_url?: string | null;
  banner_url?: string | null;
  username: string;
  tag: string;
  title: string | null;
  roles: ExperienceMemberRole[];
  gaming_account?: ExperienceMemberGamingAccount;
}

export interface ExperienceInvitation {
  id: string;
  roles: ExperienceMemberRole[];
  team: string;
  seat: string;
  status: InvitationStatus;
  creator: string;
}

export interface FirestoreExperienceTournamentInfo {
  id: string;
  name: string;
  banner_url: string;
  color: string;
  finish_at: Timestamp;
  logo_url: string;
  start_at: Timestamp;
}

export interface ExperienceTournamentInfo {
  id: string;
  name: string;
  bannerURL: string;
  color: string;
  finishAt: Date;
  logoURL: string;
  startAt: Date;
}

export interface FirestoreExperienceStrategy {
  type: ExperienceStrategyType;
  name: string;
  data: {
    [key: string]: any;
  };
}

export interface FirestoreExperienceMemberResources {
  experience: string;
  member_ids: string[];
  members: {
    id: string;
    battle_net?: {
      id: number;
      battletag: string;
    };
    steam?: {
      id: string;
      id64: string;
    };
  }[];
}
