import loadable from '@loadable/component';
import { Router } from '@reach/router';
import React, { Suspense } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import './i18n/i18n';

import AppStatus from './serviceWorker/AppStatus';
import CampaignContextProvider from '@/contexts/campaign/CampaignContextProvider';
import ErrorBoundary from './ErrorBoundary';
import ForgotPasswordPage from '@/pages/ForgotPassword';
import GlobalStyle from './common/GlobalStyle';
import LocaleAndCurrencyContextProvider from './common/LocaleAndCurrencyContextProvider';
import MasterErrorPage from './errors/MasterErrorPage';
import ModalLayerContextProvider from './common/ModalLayerContextProvider';
import NotificationsContainer from './common/Notifications/NotificationsContainer';
import Page from './common/Page';
import PageLoader from './common/PageLoader';
import prosPagePath from './pros/prosPagePath';
import ReferralCode from './referral/ReferralCode';
import ServiceWorkerManager from './serviceWorker/Manager';
import SessionContextProvider from './common/SessionContextProvider';
import StreamerModeContextProvider from './common/streamerMode/StreamerModeContextProvider';
import TemporaryAdaptiveLayout from './common/TemporaryAdaptiveLayout';
import ToastContainer from './common/ToastContainer';
import UserTelemetry from './telemetry/UserTelemetry';
import {
  ApexLegendsTemplateTypes,
  CSGOTemplateTypes,
  WarzoneTemplateTypes,
} from './pages/Experience/games/ExperienceTemplates';

import getCampaignPagePath from '@/paths/getCampaignPagePath';
import getErrorPagePath from './errors/getErrorPagePath';
import getExperiencesPagePath from '@/paths/getExperiencesPagePath';
import getHomePagePath from '@/paths/getHomePagePath';
import { getPlainCreateExperiencePagePath } from '@/paths/getCreateExperiencePagePath';
import { getPlainForgotPasswordPath } from '@/paths/getForgotPasswordPath';
import { getPlainLogInPath } from '@/paths/getSignInPath';
import { getPlainSignUpPath } from '@/paths/getSignUpPath';
import getPlatformCallbackPath from '@/paths/getPlatformCallbackPath';
import getProfilePagePath from '@/paths/getProfilePagePath';
import getRedirectToPaymentProviderPagePath from './wallet/getRedirectToPaymentProviderPagePath';
import getSettingsPagePath from './settings/getSettingsPagePath';
import getTournamentPath from '@/paths/getTournamentPath';
import getTournamentsPath from './tournaments/getTournamentsPath';
import getDonationPerCampaignPath, { getDonationPagePath } from '@/paths/getDonationPerCampaignPath';
import getExperiencePagePath, { getExperienceDraftPagePath } from '@/paths/getExperiencePagePath';

const CharityPage = loadable(() => import('@/pages/Charity'));
const DonationPage = loadable(() => import('@/pages/Charity/temp/donation/DonationPage'));
const ExperienceCreatePage = loadable(() => import('@/pages/ExperienceCreate'));
const ExperienceByTemplatePage = loadable(() => import('./experiences2/ExperienceByTemplatePage'));
const ExperiencePage = loadable(() => import('@/pages/Experience'));
const ExperiencesPage = loadable(() => import('./experiences/ExperiencesPage'));
const HomePage = loadable(() => import('@/pages/Home'));
const SignInPage = loadable(() => import('@/pages/SignIn'));
const PlatformCallbackPage = loadable(() => import('@/pages/PlatformCallback'));
const NotFoundPage = loadable(() => import('./notFound/NotFoundPage'));
const RedirectToPaymentProviderPage = loadable(() => import('./wallet/RedirectToPaymentProviderPage'));
const SettingsPage = loadable(() => import('./settings/SettingsPage'));
const ProfilePage = loadable(() => import('./profile/ProfilePage'));
const ProsPage = loadable(() => import('./pros/ProsPage'));
const SignUpPage = loadable(() => import('@/pages/SignUp'));
const TournamentPage = loadable(() => import('./tournament/TournamentPage'));
const TournamentsPage = loadable(() => import('./tournaments/TournamentsPage'));

const Application = () => (
  <ModalLayerContextProvider>
    <GlobalStyle />
    <ToastContainer position="top-right" newestOnTop draggable pauseOnHover pauseOnFocusLoss limit={5} />
    <ErrorBoundary>
      <ServiceWorkerManager>
        <AppStatus>
          <SessionContextProvider>
            <UserTelemetry />
            <NotificationsContainer>
              <StreamerModeContextProvider>
                <LocaleAndCurrencyContextProvider>
                  <ReferralCode />
                  <Suspense fallback={<PageLoader />}>
                    <Router>
                      <TemporaryAdaptiveLayout path={getCampaignPagePath(':campaignId')}>
                        <CampaignContextProvider path="/">
                          <CharityPage path="/" />
                          <DonationPage path={getDonationPagePath()} />
                        </CampaignContextProvider>
                      </TemporaryAdaptiveLayout>
                      <ExperienceCreatePage path={getPlainCreateExperiencePagePath()} />
                      <Page path="/">
                        <ExperienceByTemplatePage
                          path={`/experiences/${CSGOTemplateTypes.Duel}`}
                          templateId={CSGOTemplateTypes.Duel}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${CSGOTemplateTypes.Casual}`}
                          templateId={CSGOTemplateTypes.Casual}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${CSGOTemplateTypes.Showmatch}`}
                          templateId={CSGOTemplateTypes.Showmatch}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${CSGOTemplateTypes.TreasureHunt}`}
                          templateId={CSGOTemplateTypes.TreasureHunt}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${CSGOTemplateTypes.Wingman}`}
                          templateId={CSGOTemplateTypes.Wingman}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${WarzoneTemplateTypes.Party}`}
                          templateId={WarzoneTemplateTypes.Party}
                        />
                        <ExperienceByTemplatePage
                          path={`/experiences/${ApexLegendsTemplateTypes.Party}`}
                          templateId={ApexLegendsTemplateTypes.Party}
                        />
                        <ProfilePage path={getProfilePagePath(':userId')} />
                        <SignUpPage path={getPlainSignUpPath()} />
                        <SignInPage path={getPlainLogInPath()} />
                        <PlatformCallbackPage path={getPlatformCallbackPath()} />
                        <ForgotPasswordPage path={getPlainForgotPasswordPath()} />
                        <HomePage path={getHomePagePath()} />
                        <ExperiencePage path={`${getExperiencePagePath(':experienceId')}`} />
                        <ExperiencesPage path={getExperiencesPagePath()} />
                        <ProsPage path={prosPagePath.getPath()} />
                        <TournamentPage path={getTournamentPath(':tournamentId')} />
                        <TournamentsPage path={getTournamentsPath()} />
                        <SettingsPage path={getSettingsPagePath(':category')} />
                        <DonationPage path={`${getDonationPerCampaignPath(':campaignId')}`} />
                        <RedirectToPaymentProviderPage path={getRedirectToPaymentProviderPagePath()} />
                        <NotFoundPage default />
                      </Page>
                      <MasterErrorPage path={getErrorPagePath()} />
                    </Router>
                  </Suspense>
                </LocaleAndCurrencyContextProvider>
              </StreamerModeContextProvider>
            </NotificationsContainer>
          </SessionContextProvider>
        </AppStatus>
      </ServiceWorkerManager>
    </ErrorBoundary>
  </ModalLayerContextProvider>
);

export default Application;
