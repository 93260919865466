import { FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import { Campaign } from './types';

const CampaignDataConverter: FirestoreDataConverter<Campaign> = {
  fromFirestore(snapshot: QueryDocumentSnapshot<Campaign>): Campaign {
    const data = snapshot.data();
    return data as Campaign;
  },

  toFirestore(): {} {
    return {};
  },
};

export default CampaignDataConverter;
