export enum DefaultTemplateTypes {
  None = 'None',
  Party = 'party',
  Casual = 'casual',
  Tournament = 'tournament',
  Showmatch = 'showmatch',
  Coaching = 'coaching',
}

export enum WarzoneTemplateTypes {
  Party = 'warzone-party',
  PartyDuo = 'warzone-party-duo',
  PartyTrio = 'warzone-party-trio',
  PartyQuad = 'warzone-party-quad',
}

export enum ApexLegendsTemplateTypes {
  Party = 'apex-legends-party',
  PartyDuo = 'apex-legends-party-duo',
  PartyTrio = 'apex-legends-party-trio',
}

export enum CSGOTemplateTypes {
  Wingman = 'csgo-wingman',
  Duel = 'csgo-duel',
  Casual = 'csgo-casual',
  Showmatch = 'csgo-showmatch',
  TreasureHunt = 'csgo-treasure-hunt',
}

export enum GTATemplateTypes {
  Survival = 'survival',
  StreetRace = 'streetRace',
}

export enum DotaTypes {
  Duel = 'dota2-duel',
  AllPick = 'dota2-allpick',
  Deathmatch = 'dota2-deathmatch',
  Captains = 'dota2-captains',
  AllRandom = 'dota2-allrandom',
  AbilityDraft = 'dota2-abilitydraft',
  Turbo = 'dota2-turbo',
  Coaching = 'dota2-coaching',
}

type ExperienceTemplates =
  | DefaultTemplateTypes
  | CSGOTemplateTypes
  | GTATemplateTypes
  | WarzoneTemplateTypes
  | ApexLegendsTemplateTypes
  | DotaTypes;
export default ExperienceTemplates;
