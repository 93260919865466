import React from 'react';
import styled from 'styled-components';

const TagEl = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: block;
  margin: 0 8px 0 0;
  padding: 8px 16px;
  white-space: nowrap;
  background: #12192d;
  line-height: 1.25em;
  border-radius: 32px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);
`;

interface Props {
  onClick?: () => void;
}

const Tag: React.FC<Props> = (props) => {
  return <TagEl {...props} />;
};

export default Tag;
