import { ExperienceStrategy } from '../types';
import {
  ExperienceSweepstakeStrategyMethod,
  SerializedExperienceSweepstakeStrategy,
  SweepstakeStrategyData,
} from './types';

export default class ExperienceSweepstakeStrategy
  implements ExperienceStrategy<SweepstakeStrategyData>
{
  public readonly template: string;

  public readonly name: string;

  public readonly type: 'sweepstake';

  public readonly data: SweepstakeStrategyData;

  constructor(props: { template: string; name: string; data: SweepstakeStrategyData }) {
    this.template = props.template;
    this.name = 'strategy.sweepstake';
    this.type = 'sweepstake';
    this.data = props.data;
  }

  static fromFirestore(data: SerializedExperienceSweepstakeStrategy): ExperienceSweepstakeStrategy {
    return new ExperienceSweepstakeStrategy({
      template: data?.template,
      name: data?.name,
      data: data?.data,
    });
  }

  findMethodById(id: string): ExperienceSweepstakeStrategyMethod | undefined {
    return this.data.methods.find((method) => method.id === id);
  }
}
