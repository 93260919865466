import React from 'react';
import styled from 'styled-components';

const RowEl = styled.div<{ $gap?: string; $align?: string; $justify?: string; $wrap?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  ${({ $gap }) => $gap && `gap: ${$gap}`};
  ${({ $align }) => $align && `align-items: ${$align}`};
  ${({ $justify }) => $justify && `justify-content: ${$justify}`};
  ${({ $wrap }) => $wrap !== undefined && `flex-wrap: ${$wrap ? 'wrap' : 'nowrap'}`};
`;

type Gutter = number | [number, number];
type JustifyType = 'space-between' | 'space-around' | 'flex-start' | 'center' | 'flex-end' | 'space-evenly';

interface Props {
  align?: string;
  gutter?: Gutter;
  justify?: JustifyType;
  style?: React.CSSProperties;
  wrap?: boolean;
  onMouseOver?: (e: any) => void;
  onMouseOut?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
}

const gutterToGap = (gutter: Gutter): string => {
  const gap = Array.isArray(gutter) ? gutter : [gutter, gutter];
  return gap
    .map((g) => `${g}px`)
    .reverse()
    .join(' ');
};

const Row: React.FC<Props> = (props) => {
  const { children, style, gutter = 0, align, justify, wrap, ...rest } = props;

  return (
    <RowEl style={style} $gap={gutterToGap(gutter)} $align={align} $justify={justify} $wrap={wrap} {...rest}>
      {children}
    </RowEl>
  );
};

export default Row;
