enum BanUserInChatRoomErrorCode {
  ChatRoomIsNotExists = 4100,
  InvalidChatRoomId = 4101,
  InvalidProfileId = 4102,
  UserIsNotParticipant = 4103,
  CanNotBanAdmin = 4104,
  OnlyProCanBanUsers = 4105,
}

export default BanUserInChatRoomErrorCode;
