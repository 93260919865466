import styled from 'styled-components';

import TextField from './TextField';

export type TextAreaType = 'dark' | 'light';

const TextArea = styled(TextField).attrs({ as: 'textarea' })`
  height: auto;
  min-height: 100px;
  padding: 11px 15px;
  resize: none;

  &::placeholder {
    line-height: 22px;
  }
`;

export default TextArea;
