import { NotificationType } from '../types';
import React from 'react';
import { toast } from 'react-toastify';

import NotificationConsumer from './Consumers/NotificationConsumer';
import NotificationsContext from './NotificationsContext';
import useNotifications from './useNotifications';
import ExperienceCheckInStartNotificationConsumer from './Consumers/ExperienceCheckInStartNotificationConsumer';
import ExperienceCreatedNotificationConsumer from './Consumers/ExperienceCreatedNotificationConsumer';
import ExperienceFailedNotificationConsumer from './Consumers/ExperienceFailedNotificationConsumer';
import SlotWinNotificationConsumer from './Consumers/SlotWinNotificationConsumer';
import EntriesReceivedNotificationConsumer from './Consumers/EntriesReceivedNotificationConsumer';

export interface NotificationsContainerProps {
  children: React.ReactNode;
}

const consumers = new Map<NotificationType, NotificationConsumer>();
consumers
  .set(NotificationType.ExperienceCheckInStart, new ExperienceCheckInStartNotificationConsumer())
  .set(NotificationType.ExperienceCreated, new ExperienceCreatedNotificationConsumer())
  .set(NotificationType.ExperienceFailed, new ExperienceFailedNotificationConsumer())
  .set(NotificationType.SlotWin, new SlotWinNotificationConsumer())
  .set(NotificationType.EntriesReceived, new EntriesReceivedNotificationConsumer());

const NotificationsContainer = ({ children }: NotificationsContainerProps) => {
  const { notifications, read, readAll } = useNotifications(consumers, toast.dismiss);

  return (
    <NotificationsContext.Provider value={{ consumers, notifications, read, readAll }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContainer;
