import React from 'react';
import styled from 'styled-components';

import { BadgeGames } from './Default';
import ChatSmall from './small_chat.svg';
import CSGOSmall from './small_csgo.svg';
import LOLSmall from './small_lol.svg';
import OtherSmall from './small_other.svg';
import WzSmall from './small_wz.svg';

const GameToBadge: Record<BadgeGames, any> = {
  [BadgeGames.CSGO]: CSGOSmall,
  [BadgeGames.Fortnite]: undefined,
  [BadgeGames.Valorant]: undefined,
  [BadgeGames.Warzone]: WzSmall,
  [BadgeGames.Chess]: undefined,
  [BadgeGames.LeagueOfLegends]: LOLSmall,
  [BadgeGames.JustChatting]: ChatSmall,
  [BadgeGames.OtherGames]: OtherSmall,
};

const BadgeWrapper = styled.svg``;

export const GameBadgeSmall = ({ game, ...props }: { game: BadgeGames }) => (
  <BadgeWrapper as={GameToBadge[game]} {...props} />
);
