import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Z_INDEX_1, Z_INDEX_2 } from './styleConstants';

const loading = keyframes`
    0% {
        left: -45%;
    }
    100%{
        left: 100%;
    }
`;

const LoadWrapper = styled.div<Partial<{ $width: number; $height: number; $radius: number }>>`
  position: relative;
  height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  background-color: transparent;
  z-index: ${Z_INDEX_1};
  overflow: hidden;
  border-radius: ${({ $radius }) => ($radius ? `${$radius}px` : '5px')};
`;

const Activity = styled.div`
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(211, 206, 239, 0),
    rgba(211, 206, 239, 0.03),
    rgba(211, 206, 239, 0)
  );
  animation: ${loading} 1.5s infinite;
  z-index: ${Z_INDEX_2};
`;

export interface IContentPlaceholderProps {
  width?: number;
  height?: number;
  radius?: number;
}

export const ContentPlaceholder = React.forwardRef(
  ({ width, height, radius, ...props }: IContentPlaceholderProps, ref: any) => (
    <LoadWrapper $width={width} $height={height} $radius={radius} {...props} ref={ref}>
      <Activity />
    </LoadWrapper>
  ),
);
