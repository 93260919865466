import { ParsedUrlQuery, stringify } from 'querystring';

export interface ExperiencesQueryParams extends ParsedUrlQuery {
  filters?: string[];
}

const getExperiencePagePath = (templateId: string, params?: ExperiencesQueryParams) => {
  let path = `/experiences/${templateId}`;

  if (params) {
    const sanitizedParams: ExperiencesQueryParams = {};
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        sanitizedParams[key] = value;
      }
    });

    path = `${path}?${stringify(sanitizedParams)}`;
  }

  return path;
};

export const getExperienceDraftPagePath = () => getExperiencePagePath('draft');

export default getExperiencePagePath;
