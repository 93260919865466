export const BREAKPOINT_EXTRA_SMALL = 480;
export const BREAKPOINT_SMALL = 576;
export const BREAKPOINT_MEDIUM = 768;
export const BREAKPOINT_LARGE = 992;
export const BREAKPOINT_EXTRA_LARGE = 1200;

export const FONT_NAME_BRUTAL_TYPE = 'Brutal GG';
export const FONT_NAME_ROBOTO = 'Roboto';

export const FONT_FAMILY_ROBOTO = `${FONT_NAME_ROBOTO}, sans-serif`;
export const FONT_FAMILY_BRUTAL_TYPE = `"${FONT_NAME_BRUTAL_TYPE}", sans-serif`;

export const FONT_WEIGHT_THIN = 100;
export const FONT_WEIGHT_EXTRA_LIGHT = 200;
export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_EXTRA_BOLD = 800;
export const FONT_WEIGHT_BLACK = 900;

export const Z_INDEX_1 = 100;
export const Z_INDEX_2 = 200;
export const Z_INDEX_3 = 300;
export const Z_INDEX_4 = 400;
export const Z_INDEX_5 = 500;
export const Z_INDEX_6 = 600;
export const Z_INDEX_7 = 700;
export const Z_INDEX_8 = 800;
export const Z_INDEX_9 = 900;
export const Z_INDEX_10 = 1000;
