import { doc } from 'firebase/firestore';
import { useMemo } from 'react';

import { firestore } from '@/common/firebaseClient';
import { Database } from '@/common/types';
import useFirestoreDoc from '@/common/useFirestoreDocSnapshot';
import CampaignFundraiserDataConverter from '@/pages/Charity/temp/model/CampaignFundraiserDataConverter';

const useCampaignFundraiser = (campaign: string, id: string) => {
  const ref = useMemo(
    () =>
      doc(firestore, Database.Campaigns, campaign, Database.CampaignFundraisers, id).withConverter(
        CampaignFundraiserDataConverter
      ),
    [campaign, id]
  );
  const { data, loadingState, error } = useFirestoreDoc(ref);

  return {
    fundraiser: data,
    loadingState,
    error,
  };
};

export default useCampaignFundraiser;
