enum Database {
  Games = 'Games',
  Experiences = 'Experiences',
  Experiences2 = 'Experiences2',
  ExperienceMemberResources = 'ExperienceMemberResources',
  ReadyChecks = 'ReadyChecks',
  ProAccessRequests = 'ProAccessRequests',
  EarlyBirdEmails = 'EarlyBirdEmails',
  Votes = 'Votes',
  MailQueue = 'MailQueue',
  MailTemplates = 'MailTemplates',
  Feedbacks = 'Feedbacks',
  Purchases = 'Purchases',
  CheckoutSessions = 'CheckoutSessions',
  WalletTransactions = 'WalletTransactions',
  EmailConfirmationCodes = 'EmailConfirmationCodes',
  WithdrawalRequests = 'WithdrawalRequests',
  Tasks = 'Tasks',
  OauthStates = 'OauthStates',
  PublicProfiles = 'PublicProfiles',
  PrivateProfiles = 'PrivateProfiles',
  UsernameTags = 'UsernameTags',
  AlternativeEntries = 'AlternativeEntries',
  DiscordCommunications = 'DiscordCommunications',
  EmailSubscriptions = 'EmailSubscriptions',
  SteamGameServerAccounts = 'SteamGameServerAccounts',
  SteamAccountTrustFactors = 'SteamAccountTrustFactors',
  ChatMessages = 'ChatMessages',
  Chats = 'Chats',
  ChatRooms = 'ChatRooms',
  ChatParticipants = 'ChatParticipants',

  Notifications = 'Notifications',

  Tournaments = 'Tournaments',
  TournamentParticipants = 'TournamentParticipants',
  TournamentExperiences = 'TournamentExperiences',

  Profiles = 'Profiles',
  Users = 'Users',
  UserTransactions = 'UserTransactions',
  UserParticipations = 'UserParticipations',
  UserLastExperiences = 'UserLastExperiences',

  Showmatches = 'Showmatches',
  ShowmatchSweepstakeStats = 'ShowmatchSweepstakeStats',
  ShowmatchStats = 'ShowmatchStats',
  ShowmatchReadyChecks = 'ShowmatchReadyChecks',
  ShowmatchParticipants = 'ShowmatchParticipants',
  ShowmatchSweepstakeDrawings = 'ShowmatchSweepstakeDrawings',
  ShowmatchSweepstakeEntries = 'ShowmatchSweepstakeEntries',
  ShowmatchInviteCodes = 'ShowmatchInviteCodes',
  ShowmatchExternalActionRecords = 'ShowmatchExternalActionRecords',

  Configs = 'Configs',
  FsPackages = 'FsPackages',
  RewardTracks = 'RewardTracks',
  RewardTrackQuests = 'RewardTrackQuests',

  Leaderboards = 'Leaderboards',
  LeaderboardParticipants = 'LeaderboardParticipants',

  Parties = 'Parties',
  PartyStats = 'PartyStats',
  PartyParticipants = 'PartyParticipants',
  PartySweepstakeDrawings = 'PartySweepstakeDrawings',
  PartySweepstakeEntries = 'PartySweepstakeEntries',
  PartyReadyChecks = 'PartyReadyChecks',
  PartyInviteCodes = 'PartyInviteCodes',
  PartyDiscordCommunications = 'PartyDiscordCommunications',

  DuelsOfHonor = 'DuelsOfHonor',
  DuelOfHonorReadyChecks = 'DuelOfHonorReadyChecks',

  ShortURLs = 'ShortURLs',
  UserShortURLs = 'UserShortURLs',

  ReferralCodes = 'ReferralCodes',
  Referrals = 'Referrals',

  Collections = 'Collections',

  Campaigns = 'Campaigns',
  CampaignSupporters = 'CampaignSupporters',
  CampaignFundraisers = 'CampaignFundraisers',
  CampaignContributions = 'CampaignContributions',
}

export default Database;
