enum TaskAssignment {
  WolfPackChallengeInvalidation = 'wolfPackChallengeInvalidation',
  WolfPackChallengeSweepstakeStart = 'wolfPackChallengeSweepstakeStart',
  // WolfPackChallengeLaunchInvalidation = 'WolfPackChallengeLaunchInvalidation',
  WolfPackChallengeCheckInRemind = 'wolfPackChallengeCheckInRemind',
  WolfPackChallengeEndDrawing = 'wolfPackChallengeEndDrawing',
  WolfPackChallengeReadyCheckInvalidation = 'wolfPackChallengeReadyCheckInvalidation',

  DuelOfHonorReadyCheckInvalidation = 'duelOfHonorReadyCheckInvalidation',
  DuelOfHonorLaunchInvalidation = 'duelOfHonorLaunchInvalidation',

  CreateNotification = 'createNotification',

  DestroyDiscordCommunication = 'destroyDiscordCommunication',
  JoinUserToDiscordCommunication = 'joinCurrentCommunication',

  StartWolfPackChallengeCheckIn = 'startWolfPackChallengeCheckIn',
}

export default TaskAssignment;
