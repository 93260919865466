import ExperienceMemberViewModel from '../../../pages/Experience/model/ExperienceMemberViewModel';
import FirestoreGame from '../../../pages/Experience/model/game/FirestoreGame';
import GameViewModel from '@/models/GameViewModel';
import TeamViewModel from '../../../pages/Experience/model/team/TeamViewModel';
import { FirestoreTeam } from '../../../pages/Experience/model/team/types';
import { FirestoreExperienceTemplate } from '../../../pages/Experience/model/template/types';
import { FirestoreExperienceMember } from '../../../pages/Experience/model/types';

export interface NotificationExperience {
  id: string;
  members: FirestoreExperienceMember[];
  game: FirestoreGame;
  template: FirestoreExperienceTemplate;
  teams: FirestoreTeam[];
}

export default class NotificationExperienceViewModel {
  readonly id: string;
  readonly members: ExperienceMemberViewModel[];
  readonly game: GameViewModel;
  readonly template: FirestoreExperienceTemplate;
  readonly teams: TeamViewModel[];

  constructor(props: {
    id: string;
    template: FirestoreExperienceTemplate;
    game: GameViewModel;
    members: ExperienceMemberViewModel[];
    teams: TeamViewModel[];
  }) {
    this.id = props.id;
    this.template = props.template;
    this.game = props.game;
    this.members = props.members;
    this.teams = props.teams;
  }

  static fromFirestore(data: NotificationExperience): NotificationExperienceViewModel {
    const membersMap = data.members.reduce<Record<string, ExperienceMemberViewModel>>((map, member) => {
      map[member.id] = ExperienceMemberViewModel.fromFirestore(member);
      return map;
    }, {});
    const entities = {
      members: membersMap,
      teamStats: {},
      playerStats: {},
    };

    return new NotificationExperienceViewModel({
      id: data.id,
      template: data.template,
      game: GameViewModel.fromFirestore(data.game),
      teams: data.teams.map((team) => TeamViewModel.fromFirestore(team, data.template, entities)),
      members: data.members.map((member) => ExperienceMemberViewModel.fromFirestore(member)),
    });
  }

  get owners(): ExperienceMemberViewModel[] {
    return this.members.filter((member) => member.isOwner);
  }

  get leaders(): ExperienceMemberViewModel[] {
    return this.members.filter((member) => member.isLeader);
  }

  toPlain() {
    return {
      id: this.id,
      teams: this.teams.map((team) => ({
        id: team.id,
        team: team.name,
        leaders: team.leaders,
        members: team.members,
        seats: team.seats,
      })),
      owners: this.owners,
      leaders: this.leaders,
    };
  }
}
