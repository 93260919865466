import FPrice from '@/common/FPrice';
import {
  FONT_FAMILY_BRUTAL_TYPE,
  FONT_FAMILY_ROBOTO,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from '@/common/ui';
import { readableColor, tint } from 'polished';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import MethodToggle from './components';

const Root = styled.button<{ borderColor: string; backgroundColor: string }>`
  width: 310px;
  height: 395px;
  background: ${({ backgroundColor }) => backgroundColor};
  align-items: stretch;
  border-radius: 20px;
  border: 5px solid ${({ borderColor }) => borderColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 5px;

  &:focus:not(:focus-visible),
  &:hover {
    background: ${({ backgroundColor }) => tint(0.05, backgroundColor)};
    border-color: ${({ borderColor }) => tint(0.05, borderColor)};
  }

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 30px 10px;
  flex-grow: 1;
`;

const Title = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 73px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  line-height: 60px;
  text-align: center;
`;

const Subtitle = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 25px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  line-height: 30px;
  text-align: center;
`;

const Description = styled.div`
  color: rgba(211, 206, 239, 0.5);
  font-family: ${FONT_FAMILY_ROBOTO};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT_REGULAR};
  line-height: 20px;
  text-align: center;
  margin-top: 30px;
  display: -moz-box;
  display: -webkit-box;
  display: box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 80px;
`;

type BonusType = 'warm' | 'hot';

const Bonus = styled.div<{ type: BonusType; color: string }>`
  background: ${({ color }) => color};
  border-radius: 15px;
  border: 2px solid #fbc02d;
  color: ${({ color }) => readableColor(color)};
  display: inline-flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  line-height: 20px;
  margin-top: 16px;
  padding: 3px 10px;
  position: relative;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 2px solid;
  }

  &::before {
    display: ${({ type }) => (['hot', 'warm'].includes(type) ? 'block' : 'none')};
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border-color: rgba(251, 193, 45, 0.5);
    border-radius: 19px;
  }

  &::after {
    display: ${({ type }) => (type === 'hot' ? 'block' : 'none')};
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-color: rgba(251, 193, 45, 0.2);
    border-radius: 23px;
  }
`;

const BonusPlaceholder = styled.div`
  height: 30px;
  margin-top: 16px;
`;

const Price = styled(FPrice)`
  font-size: 19px;
`;

const Button = styled.div<{ color: string }>`
  width: 290px;
  height: 52px;
  align-items: center;
  background: ${({ color }) => color};
  border-radius: 10px;
  color: ${({ color }) => readableColor(color)};
  display: flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 19px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  justify-content: center;
  margin-top: auto;
  padding: 20px;

  ${Price} {
    margin-left: 0.5em;
  }
`;

const SpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${SpinnerAnimation} 1s ease infinite;
  border-bottom: 2px solid rgba(103, 58, 183, 0.6);
  border-left: 2px solid rgba(103, 58, 183, 0.6);
  border-right: 2px solid rgba(103, 58, 183, 0.6);
  border-top: 2px solid rgba(103, 58, 183, 1);
  border-radius: 50%;
  display: inline-flex;
  height: 16px;
  width: 16px;
`;

const Icon = styled.img`
  display: block;
  margin-top: -15px;
  height: 85px;
  object-fit: contain;
  width: 100%;
`;

const IconSubtitle = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 21px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: -30px;
`;

const Label = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 12px;
  min-height: 15px;
  letter-spacing: 0.02em;
  line-height: 14px;
  margin-top: 10px;
  text-align: center;
`;

interface ParticipationMethodProps {
  type: 'toggle' | 'button';
  title: string;
  image?: string;
  subtitle: string;
  description: string;
  label?: {
    color: string;
    text: string;
  };
  bonus?: {
    color: string;
    text: string;
    type: BonusType;
  };
  action: {
    text: string;
    color: string;
  };
  borderColor: string;
  backgroundColor: string;
  checked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  claimed?: boolean;
  onClick: (toggle?: boolean) => void;
}

const ParticipationMethod = (props: ParticipationMethodProps) => {
  const {
    type,
    title,
    subtitle,
    image,
    label,
    description,
    action,
    borderColor,
    backgroundColor,
    bonus,
    checked,
    loading,
    disabled,
    claimed,
    onClick,
  } = props;
  var toggle = typeof checked === 'undefined' ? false : checked!;

  return (
    <Root borderColor={borderColor} backgroundColor={backgroundColor} disabled={disabled || claimed}>
      {label && <Label color={label.color}>{label.text}</Label>}
      <Content onClick={() => onClick(!toggle)}>
        {image ? (
          <>
            <Icon src={image} alt={title} />
            <IconSubtitle>{subtitle}</IconSubtitle>
          </>
        ) : (
          <>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </>
        )}

        {bonus ? (
          <Bonus type={bonus.type} color={bonus.color}>
            {bonus.text}
          </Bonus>
        ) : (
          <BonusPlaceholder />
        )}
        <Description>{description}</Description>
      </Content>
      {type === 'toggle' && <MethodToggle checked={toggle} content={action.text} onClick={() => onClick(!toggle)} />}
      {type === 'button' && (
        <Button onClick={() => onClick(!toggle)} color={action.color}>
          {loading ? <Spinner /> : claimed ? 'Claimed' : action.text}
        </Button>
      )}
    </Root>
  );
};

export default ParticipationMethod;
