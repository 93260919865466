import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from './ui';

const ActionButton = styled.button`
  align-items: center;
  background: rgba(10, 16, 35, 0.05);
  border: none;
  border-left: 1px solid rgba(10, 16, 35, 0.05);
  color: rgba(76, 69, 142, 0.8);
  cursor: pointer;
  display: flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0 30px;

  &:hover {
    border-color: transparent;
    background: rgba(10, 16, 35, 0.1);
    color: rgba(76, 69, 142, 1);
  }
`;

const HideButton = ({ closeToast }: { closeToast: React.MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const onClick = (event: React.MouseEvent<any, MouseEvent>) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    closeToast(event);
  };

  return <ActionButton onClick={onClick}>{t('toast.hide')}</ActionButton>;
};

const StyledToastContainer = styled(ToastContainer).attrs({
  closeButton: HideButton,
  transition: Slide,
  hideProgressBar: true,
})`
  top: 70px;
  max-width: 100%;
  width: 500px;
  align-items: center;

  .Toastify__toast-container {
  }
  .Toastify__toast {
    /* background: rgba(240, 244, 248, 1); */
    background: #4c458e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px rgba(10, 16, 35, 0.25);
    font-family: ${FONT_FAMILY_ROBOTO};
    /* color: #0a1023; */
    padding: 0;
    width: 100%;

    ${ActionButton} {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .Toastify__toast--error {
    background: rgba(233, 30, 99, 1);
    color: rgba(255, 255, 255, 1);

    ${ActionButton} {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .Toastify__toast--warning {
    background: rgba(251, 192, 45, 1);
  }
  .Toastify__toast--success {
    background: rgba(126, 215, 84, 1);
  }
  .Toastify__toast-body {
    padding: 20px;
  }
  .Toastify__progress-bar {
    &--default {
      background: rgba(10, 16, 35, 0.5);
    }
  }
`;

export default StyledToastContainer;
