import React from 'react';
import styled from 'styled-components';

import FSvg from './F.svg';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from './ui';

const Price = styled.div`
  align-items: center;
  color: currentColor;
  display: inline-flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: inherit;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.02em;
  text-decoration: none;
`;

const FsIcon = styled(FSvg)`
  fill: currentColor;
  height: 1.05em;
`;

const Amount = styled.div`
  line-height: 1;
  margin-left: 0.25em;
  margin-top: 0.05em;
`;

interface FPriceProps {
  children: React.ReactNode;
  className?: string;
}

const FPrice = ({ children, ...props }: FPriceProps) => (
  <Price {...props}>
    <FsIcon />
    <Amount>{children}</Amount>
  </Price>
);

export default FPrice;
