import React from 'react';
import styled, { keyframes } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '../styleConstants';

import SuccessSvg from './success.svg';

const Loader = new URL('./loader.png', import.meta.url).href;

const StatusWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: rgba(29, 181, 83, 10%);
  fill: rgba(29, 181, 83);
  border-radius: 50%;

  margin-left: 10px;

  &:nth-child(${5}n + 1) {
    margin-left: 0;
  }

  &:nth-child(n + 6) {
    margin-top: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Timer = styled.div`
  color: #7ed754;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 49px;
  line-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-variant-numeric: tabular-nums;
  width: 100px;
  height: 100px;
  margin: 0 15px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderIcon = styled.img`
  animation: ${rotate} 1s linear infinite;
  position: absolute;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 140px;
`;

const LeftTeamWrapper = styled(TeamWrapper)`
  justify-content: flex-end;
`;

interface Team {
  [key: string]: boolean | undefined;
}

interface ReadyCheckProps {
  time: string | number;
  leftTeam: Team;
  rightTeam: Team;
}

export const ReadyCheck = ({ time, leftTeam, rightTeam, ...props }: ReadyCheckProps) => (
  <Wrapper {...props}>
    <LeftTeamWrapper>
      {Object.keys(leftTeam).map((playerId) => (
        <StatusWrapper key={playerId}>{leftTeam[playerId] && <SuccessSvg />}</StatusWrapper>
      ))}
    </LeftTeamWrapper>
    <Timer>
      {time}
      <LoaderIcon src={Loader} alt="loader" />
    </Timer>
    <TeamWrapper>
      {Object.keys(rightTeam).map((playerId) => (
        <StatusWrapper key={playerId}>{rightTeam[playerId] && <SuccessSvg />}</StatusWrapper>
      ))}
    </TeamWrapper>
  </Wrapper>
);
