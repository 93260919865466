enum TransactionDetailsType {
  ShowmatchPurchase = 'showmatchPurchase',
  ShowmatchReward = 'showmatchReward',
  DuelOfHonorPurchase = 'duelOfHonorPurchase',
  DuelOfHonorReward = 'duelOfHonorReward',
  TournamentReward = 'tournamentReward',
  PartyPurchase = 'partyPurchase',
  PartyReward = 'partyReward',
  FsPurchase = 'fsPurchase',
  WolfredGenerosity = 'wolfredGenerosity',
  Withdrawal = 'withdrawal',
  EarlyBirdReward = 'earlyBirdReward',
  RewardTrackReward = 'rewardTrackReward',
}

export default TransactionDetailsType;
