import { HandleDiscordConnectErrorCode } from '@/common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultErrorView from '../DefaultErrorView';
import ErrorPageProps from '../ErrorPageProps';

const ErrorMessageKey: Record<number, string> = {
  [HandleDiscordConnectErrorCode.InvalidCode]: 'invalidCode',
  [HandleDiscordConnectErrorCode.InvalidState]: 'invalidState',
  [HandleDiscordConnectErrorCode.ExternalAccountAlreadyInUse]: 'externalAccountAlreadyInUse',
  [HandleDiscordConnectErrorCode.ProfileNotFound]: 'profileNotFound',
};

const DiscordConnectErrorPage = ({ errorCode }: ErrorPageProps) => {
  const { t } = useTranslation('errors');
  return (
    <DefaultErrorView
      heading={t(`discordConnectErrorPage.heading`)}
      description={t(`discordConnectErrorPage.errorCodes.${ErrorMessageKey[errorCode]}`)}
    />
  );
};

export default DiscordConnectErrorPage;
