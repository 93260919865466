import React from 'react';
import styled from 'styled-components';

import { ThreeDots } from './ThreeDots';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

interface PageLoaderProps {
  className?: string;
}

export const PageLoader = ({ className }: PageLoaderProps) => (
  <Container className={className}>
    <ThreeDots />
  </Container>
);
