import { UserRewardType } from './types';
import { RouteComponentProps } from '@reach/router';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Footer from '../footer/Footer';
import NetworkStatusContext from '../serviceWorker/NetworkStatusContext';
import OfflineBanner from '../serviceWorker/OfflineBanner';

import Header from './Header';
import RewardsBanner from './RewardsBanner';
import SessionContext from './SessionContext';
import { Z_INDEX_5 } from './ui';
import Sidebar from '../sidebar/Sidebar';
import СлаваУкраїні from './GloryToUkraine/Header';

const Root = styled.div`
  background: #0a1023;
  margin: 0 auto;
  width: 100%;
  display: flex;
  min-height: 100vh;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: inherit;
  flex-grow: 1;
  position: relative;
  margin-left: 60px;
`;

const MyRewardsBanner = styled(RewardsBanner)`
  max-width: inherit;
  width: 100%;
  z-index: ${Z_INDEX_5};
`;

const MyOfflineBanner = styled(OfflineBanner)`
  max-width: inherit;
  width: 100%;
  z-index: ${Z_INDEX_5};
`;

const Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-bottom: 60px;
  margin-top: -60px;
`;

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
`;

interface PageProps extends RouteComponentProps {
  children: ReactNode;
}

const Page = ({ children }: PageProps) => {
  const { privateProfile } = useContext(SessionContext);
  const { offline } = useContext(NetworkStatusContext);
  const hasUnfinishedRewards =
    privateProfile &&
    privateProfile.rewards &&
    (!privateProfile.rewards[UserRewardType.Email] ||
      !privateProfile.rewards[UserRewardType.Discord] ||
      !privateProfile.rewards[UserRewardType.Steam]);

  return (
    <Root>
      <Sidebar />
      <Container>
        {offline && <MyOfflineBanner />}
        {hasUnfinishedRewards && <MyRewardsBanner rewards={privateProfile!.rewards} />}
        {/* <СлаваУкраїні /> */}
        <StyledHeader />

        <Main>{children}</Main>

        <Footer />
      </Container>
    </Root>
  );
};

export default Page;
