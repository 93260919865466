import React from 'react';
import styled from 'styled-components';

const StyledDot = styled.div`
  color: rgba(211, 206, 239, 0.2);
  font-size: 0.75em;
  padding: 0 0.3em;
  flex-shrink: 0;
`;

export const Dot = ({ ...props }) => <StyledDot {...props}>•</StyledDot>;
