enum LaunchDuelOfHonorCounterStrikeErrorCode {
  ExperienceNotFound = 3501,
  InvalidServerLocation = 3502,
  InvalidGameMap = 3503,
  RequestNotFound = 3504,
  InvalidExperience = 3505,
  InvalidStatus = 3506,
  InsufficientRights = 3507,
}

export default LaunchDuelOfHonorCounterStrikeErrorCode;
