import { Database, NotificationStatus, NotificationType, SerializedNotification } from '../types';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { firestore } from '../firebaseClient';
import SessionContext from '../SessionContext';
import useLoadingState from '../useLoadingState';

import NotificationConsumer from './Consumers/NotificationConsumer';
import NotificationDataConverter from './model/NotificationDataConverter';
import NotificationViewModel from './model/NotificationViewModel';
import axiosApiGatewayClient from '../axiosApiGatewayClient';

const useNotifications = (
  consumers: Map<NotificationType, NotificationConsumer>,
  onNotificationRead?: (notificationId: string) => any,
) => {
  const isFirstLoad = useRef<boolean>(true);
  const [notifications, setNotifications] = useState<NotificationViewModel[]>([]);
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const { user } = useContext(SessionContext);

  const read = useCallback(
    async (notificationId: string) => {
      await axiosApiGatewayClient.put(`/notifications/${notificationId}/read`);
      if (typeof onNotificationRead === 'function') {
        onNotificationRead(notificationId);
      }
    },
    [onNotificationRead],
  );

  const readAll = useCallback(
    () => notifications.forEach((notification) => read(notification.id)),
    [notifications, read],
  );

  const onFirestoreSnapshot = (snapshot: QuerySnapshot<NotificationViewModel>) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === 'added' && !isFirstLoad.current) {
        const notification = change.doc.data();

        if (consumers.has(notification.type)) {
          consumers.get(notification.type)?.perform(notification, () => read(notification.id));
        }
      }

      if (typeof onNotificationRead === 'function' && change.type === 'removed') {
        onNotificationRead(change.doc.id);
      }
    });

    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }

    const data = snapshot.docs.map((notification) => notification.data());
    if (data) {
      unstable_batchedUpdates(() => {
        setLoaded();
        setNotifications(data);
      });
    } else {
      setLoadFailed();
    }
  };

  const onFirestoreSnapshotError = (_error: FirestoreError) => {
    console.log(_error);
    unstable_batchedUpdates(() => {
      setLoadFailed();
    });
  };

  useEffect(() => {
    if (!user) {
      isFirstLoad.current = true;
      return () => {};
    }
    setLoading();

    const unsubscribe = onSnapshot(
      query(
        collection(firestore, Database.Notifications),
        where('status', '==', NotificationStatus.NotRead),
        where('recipient', '==', user!.uid),
        orderBy('created_at', 'desc'),
      ).withConverter(NotificationDataConverter),
      onFirestoreSnapshot,
      onFirestoreSnapshotError,
    );
    return unsubscribe;
  }, [user]);

  return {
    read,
    readAll,
    notifications,
    loadingState,
  };
};

export default useNotifications;
