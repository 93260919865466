import { useState } from 'react';

export interface LoadingStateType {
  loading: boolean;
  loaded: boolean;
  loadFailed: boolean;
}

export const defaultLoadingState: LoadingStateType = {
  loading: false,
  loaded: false,
  loadFailed: false,
};

const useLoadingState = (initialState?: LoadingStateType) => {
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    initialState || defaultLoadingState,
  );
  const setLoaded = () => {
    setLoadingState({
      loading: false,
      loaded: true,
      loadFailed: false,
    });
  };
  const setLoading = () => {
    setLoadingState({
      loading: true,
      loaded: false,
      loadFailed: false,
    });
  };
  const setLoadFailed = () => {
    setLoadingState({
      loading: false,
      loaded: false,
      loadFailed: true,
    });
  };
  const reset = () => {
    setLoadingState(defaultLoadingState);
  };
  return {
    loadingState,
    setLoaded,
    setLoading,
    setLoadFailed,
    reset,
  };
};

export default useLoadingState;
