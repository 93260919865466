import { signOut } from 'firebase/auth';

import { auth } from './firebaseClient';

const useLogOut = () => {
  const logOut = () => {
    signOut(auth);
  };
  return logOut;
};

export default useLogOut;
