import React from 'react';
import styled from 'styled-components';

const IconEl = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 3px;
`;

interface Props {
  src: string;
}

const Icon: React.FC<Props> = (props) => {
  const { src, ...rest } = props;
  return <IconEl src={src} {...rest} />;
};

export default Icon;
