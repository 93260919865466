enum UpdateDuelOfHonorCounterStrikeErrorCode {
  ExperienceNotFound = 3401,
  RequestNotFound = 3402,
  InvalidMessage = 3403,
  InvalidDonation = 3404,
  NotEnoughMoney = 3405,
  InvalidExperience = 3406,
  InvalidStatus = 3407,
  ChallengerNotFound = 3408,
  ChallengerSteamRequired = 3409,
}

export default UpdateDuelOfHonorCounterStrikeErrorCode;
