import styled, { keyframes, css } from 'styled-components';
import { rgba } from 'polished';

const SpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface SpinnerProps {
  $color?: string;
  $size?: string | number;
}

export const SolidSpinner = styled.div<SpinnerProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    border-radius: 50%;
  }

  ${({ $color = 'white', $size = '16' }) => {
    const size = Number($size);
    return css`
      height: ${size}px;
      width: ${size}px;

      &::before {
        border-bottom: 2px solid ${rgba($color, 0.6)};
        border-left: 2px solid ${rgba($color, 0.6)};
        border-right: 2px solid ${rgba($color, 0.6)};
        border-top: 2px solid ${rgba($color, 1)};
        animation: ${SpinnerAnimation} 1s ease infinite;
      }
    `;
  }};
`;
