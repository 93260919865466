import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import { AmpliWrapper } from '@/components';
import Dropdown from '../Dropdown';
import LanguagesPage from './LanguagesPage';
import MainPage from './MainPage';
import MenuButton from '../MenuButton';
import MenuPageProps from './MenuPageProps';
import { MenuPages } from './MenuPages';
import SessionContext from '../../SessionContext';
import Tooltip from '../../Tooltip';
import useDropdown from '../useDropdown';
import { AvatarLegacy, BREAKPOINT_MEDIUM } from '../../ui';

import ArrowDownSvg from './arrow-down.svg';

const ProfileMenuButton = styled(MenuButton)`
  padding: 4px;
`;

const ProfileAvatar = styled(AvatarLegacy)`
  flex-shrink: 0;
`;

const PageToContent: Record<MenuPages, React.ComponentType<MenuPageProps>> = {
  [MenuPages.Main]: MainPage,
  [MenuPages.Languages]: LanguagesPage,
};

const Menu = () => {
  const { t } = useTranslation('header');
  const { publicProfile } = useContext(SessionContext);
  const { triggerRef, dropdownRef, isShown, toggleShown } = useDropdown();
  const [page, setPage] = useState<MenuPages>(MenuPages.Main);

  const isMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINT_MEDIUM}px)` });

  useEffect(() => {
    if (isShown === false) {
      setPage(MenuPages.Main);
    }
  }, [isShown]);

  const DropdownPage = PageToContent[page];

  return (
    <>
      <Tooltip content={t('menu.tooltip')} strategy="fixed" offset="12" ref={triggerRef} disabled={isShown}>
        <AmpliWrapper event="click_menu_top">
          {isMobile ? (
            <ProfileMenuButton type="button" onClick={toggleShown}>
              <ProfileAvatar profile={publicProfile} size="32" plain />
            </ProfileMenuButton>
          ) : (
            <MenuButton type="button" onClick={toggleShown}>
              <ArrowDownSvg />
            </MenuButton>
          )}
        </AmpliWrapper>
      </Tooltip>
      {isShown && (
        <Dropdown ref={dropdownRef}>
          <DropdownPage setPage={setPage} toggleDropdown={toggleShown} />
        </Dropdown>
      )}
    </>
  );
};

export default Menu;
