import { Currency, Locale } from '../../types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LocaleAndCurrencyContext from '../../LocaleAndCurrencyContext';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../../ui';
import CheckSvg from '../check.svg';
import { DropdownGroup, DropdownItem, ItemIcon, ItemText } from '../Dropdown';

import ArrowLeftSvg from './arrow-left.svg';
import MenuPageProps from './MenuPageProps';
import { MenuPages } from './MenuPages';

const MenuContainer = styled.div`
  width: 310px;
`;

const DarkDropdownGroup = styled(DropdownGroup)`
  background: rgba(43, 38, 96, 1);
`;

const HeadingText = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  flex-grow: 1;
  text-align: center;
`;

const SmallIconButton = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  color: inherit;
  position: absolute;
  transition: all 300ms ease-out;

  &::before {
    content: '';
    background: rgba(211, 206, 239, 0.15);
    bottom: 0;
    border-radius: 20px;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 300ms ease-out;
    right: 0;
    z-index: auto;
    opacity: 0;
  }

  svg {
    height: 18px;
    fill: currentColor;
    transition: all 300ms ease-out;
    width: 18px;
  }
`;

const Heading = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: flex;
  height: 50px;
  padding: 0 14px;
  width: 100%;
  text-align: center;

  &:hover,
  &:focus {
    ${SmallIconButton} {
      &::before {
        opacity: 1;
      }
    }
  }
`;

const ItemIconToggled = styled(ItemIcon)<{ $active?: boolean }>`
  opacity: ${({ $active }) => ($active ? '1' : '0')};
`;

const LanguagePage = () => {
  const { t } = useTranslation('header');
  const { currency, setCurrency } = useContext(LocaleAndCurrencyContext);

  const onCurrencySwitch = (crr: Currency) => () => {
    setCurrency(crr);
  };

  return (
    <MenuContainer>
      <Heading>
        <HeadingText>{t('languagesPage.currency')}</HeadingText>
      </Heading>
      <DarkDropdownGroup>
        <DropdownItem onClick={onCurrencySwitch(Currency.USD)}>
          <ItemIconToggled as={CheckSvg} $active={currency === Currency.USD} />
          <ItemText>USD</ItemText>
        </DropdownItem>
        <DropdownItem onClick={onCurrencySwitch(Currency.EUR)}>
          <ItemIconToggled as={CheckSvg} $active={currency === Currency.EUR} />
          <ItemText>EUR</ItemText>
        </DropdownItem>
      </DarkDropdownGroup>
    </MenuContainer>
  );
};

export default LanguagePage;
