import { useContext, useEffect } from 'react';
import SessionContext from '../common/SessionContext';
import userTracking from '../common/userTracking';
import useUserTelemetry from './useUserTelemetry';

const UserTelemetry = () => {
  const { user } = useContext(SessionContext);
  const { telemetry, loadingState } = useUserTelemetry(user?.uid);

  useEffect(() => {
    if (user && telemetry) {
      userTracking.updateTelemetry(telemetry);
    }
  }, [user, telemetry, loadingState]);

  return null;
};

export default UserTelemetry;
