enum PartyInviteCodeErrorCode {
  PartyNotFound = 72789420,
  LeaderNotFound = 72789421,
  WrongStatus = 72789422,
  MaxParticipantsReached = 72789423,
  NoEmptySots = 72789424,
  UserNotFound = 72789425,
  CodeAlreadyUsed = 72789426,
  CodeNotFound = 72789427,
  AlreadyParticipating = 72789428,
  MaxReservedSlotsReached = 72789429,
  CantRevoke = 727894210,
  FanNotFound = 727894211,
  FanNotInvited = 727894212,
  NoReservedSlots = 727894213,
}

export default PartyInviteCodeErrorCode;
