import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

import Dropdown from '../Dropdown';
import getExperiencesPagePath from '@/paths/getExperiencesPagePath';
import MenuButton from '../MenuButton';
import { pulse } from '@/common/ui';
import SessionContext from '../../SessionContext';
import Tooltip from '../../Tooltip';
import useDropdown from '../useDropdown';
import usePrevious from '../../usePrevious';
import UserParticipationComponent from '../../UserParticipation/UserParticipationComponent';
import useUserParticipations from '../../UserParticipation/useUserParticipations';

import { BREAKPOINT_MEDIUM, Button, FONT_WEIGHT_EXTRA_BOLD, TruncateMixin, Z_INDEX_1 } from '../../ui';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../../ui';

import ArrowDownSvg from './arrow-down.svg';
import TimerSvg from './timer.svg';

const DropdownContainer = styled.div`
  width: 420px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: 420px;
    width: 100%;
    z-index: ${Z_INDEX_1};
  }
`;

const Border = styled.span<{ $active: boolean }>`
  border-right: 2px solid;
  border-color: ${({ $active }) => ($active ? 'rgba(43, 38, 96, 1)' : 'rgba(255, 255, 255, 0.15)')};
  margin: 0 15px;
  height: 21px;
`;

const Text = styled.div<{ $active: boolean }>`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 14px;
  line-height: 125%;
  color: ${({ $active }) => ($active ? 'rgba(43, 38, 96, 1)' : 'rgba(255, 255, 255, 0.9))')};
`;

const ActivityButton = styled(MenuButton)<{ $pulse?: string }>`
  ${({ $pulse }) =>
    $pulse &&
    css`
      animation: ${pulse($pulse)} 2s linear infinite;
      &::before {
        background: ${rgba($pulse, 1)};
      }

      * {
        color: rgba(43, 38, 96, 1);
        border-color: rgba(43, 38, 96, 1);
      }
    `}
`;

const HeadingText = styled.div`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: rgba(211, 206, 239, 0.8);
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
`;

const ActivitiesContainer = styled.div`
  background: rgba(43, 38, 96, 1);
  margin-bottom: 10px;
  max-height: 600px;
  overflow-y: auto;
`;

const StyledUserParticipationComponent = styled(UserParticipationComponent)`
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover,
  &:focus {
    background: rgba(211, 206, 239, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const Placeholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  padding: 40px;
`;

const PlaceholderHeading = styled.h4`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.01em;
  line-height: 24px;
  text-align: center;
`;

const PlaceholderButtonWrapper = styled.div`
  margin-top: 15px;
`;

const TruncateComponent = styled.span`
  display: inline-block;
  vertical-align: bottom;
  font-size: 14px !important;
  ${TruncateMixin};
`;

const Time = styled.div<{ $active: boolean }>`
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  font-size: 18px;
  line-height: 125%;
  color: ${({ $active }) => ($active ? 'rgba(43, 38, 96, 1)' : 'rgba(255, 255, 255, 0.9))')};
  margin-left: 5px;
`;

const Live = styled.span`
  align-items: center;
  background: #e91e63;
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 11px !important;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  height: 16px;
  padding: 0 8px;
  text-transform: uppercase;
`;

const ActivitiesMenu = () => {
  const { t } = useTranslation(['header', 'exp2']);
  const { triggerRef, dropdownRef, isShown, toggleShown } = useDropdown();
  const { user } = useContext(SessionContext);
  const { participations, loadingState } = useUserParticipations(user?.uid!, {
    privateGroup: true,
  });
  const isSomeReadyCheck = useMemo(
    () =>
      participations.some((participation) =>
        Boolean(participation.findActiveReadyCheckByMember(participation.findMemberById(user?.uid)?.id))
      ),
    [participations]
  );
  const previousIsSomeReadyCheck = usePrevious(isSomeReadyCheck);

  let pulse: string | undefined;
  if (
    participations.some(
      (participation) =>
        participation.checkIn.isActive &&
        participation.findContestantById(user?.uid) &&
        !participation.checkIn.hasParticipant(user?.uid)
    )
  ) {
    pulse = '#fbc02d';
  }
  if (isSomeReadyCheck) {
    pulse = '#7ed754';
  }

  // useEffect(() => {
  //   if (!previousIsSomeReadyCheck && isSomeReadyCheck) {
  //     const horn = new Audio(HornMp3);
  //     try {
  //       horn.play();
  //     } catch (e) {}
  //   }
  // }, [participations]);

  const upcomingExperience = participations[0];

  return (
    <>
      <Tooltip content={t('activities.tooltip')} strategy="fixed" offset="12" ref={triggerRef} disabled={isShown}>
        <ActivityButton onClick={toggleShown} active={isShown} $pulse={isShown ? undefined : pulse}>
          {upcomingExperience && (
            <>
              {upcomingExperience.isLive ? (
                <Live>Live</Live>
              ) : (
                <>
                  <TimerSvg />
                  <Time $active={isShown}>{t('activities.startAt', { time: upcomingExperience.startAt })}</Time>
                </>
              )}
              <Border $active={isShown} />
            </>
          )}
          <Text $active={isShown}>
            {(() => {
              if (pulse) {
                if (isSomeReadyCheck) {
                  return t('activities.buttonText.readyCheck');
                }
                return t('activities.buttonText.checkIn');
              }

              if (upcomingExperience) {
                return t(`exp2:${upcomingExperience.template.name}.name`);
              }

              return t('activities.buttonText.noExperience');
            })()}
          </Text>
          <Border $active={isShown} />
          <ArrowDownSvg />
        </ActivityButton>
      </Tooltip>

      {isShown && (
        <Dropdown ref={dropdownRef}>
          <DropdownContainer>
            <Heading>
              <HeadingText>{t('activities.heading')}</HeadingText>
            </Heading>
            <ActivitiesContainer>
              {participations.map((experience) => (
                <StyledUserParticipationComponent
                  key={`participation_${experience.id}`}
                  experience={experience}
                  onClick={toggleShown}
                />
              ))}
              {!loadingState.loading && !participations.length && (
                <Placeholder>
                  <PlaceholderHeading>{t('activities.placeholder.heading')}</PlaceholderHeading>
                  <PlaceholderButtonWrapper>
                    <Button type="opaque" size="large" href={getExperiencesPagePath()} onClick={toggleShown}>
                      {t('activities.placeholder.action')}
                    </Button>
                  </PlaceholderButtonWrapper>
                </Placeholder>
              )}
            </ActivitiesContainer>
          </DropdownContainer>
        </Dropdown>
      )}
    </>
  );
};

export default ActivitiesMenu;
