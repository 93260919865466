import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { ForgotPasswordErrorCode } from '@/common/types';
import ClientErrorCode from '../common/ClientErrorCode';
import ErrorWithCode from '../common/ErrorWithCode';
import { auth } from '../common/firebaseClient';
import useLoadingState from '../common/useLoadingState';

const FORGOT_PASSWORD_ERROR: Record<string, number | undefined> = {
  'auth/invalid-email': ForgotPasswordErrorCode.InvalidEmail,
  'auth/user-not-found': ForgotPasswordErrorCode.UserNotFound,
  'auth/network-request-failed': ClientErrorCode.NoInternetOrServerConnection,
};

const useForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<ErrorWithCode>();
  const { loadingState, setLoading, setLoaded, setLoadFailed } = useLoadingState();
  const resetPassword = async () => {
    setLoading();
    try {
      await sendPasswordResetEmail(auth, email, { url: window.location.href });
      setLoaded();
    } catch (_error) {
      let myError: ErrorWithCode;
      if (_error instanceof ErrorWithCode) {
        myError = _error;
      } else {
        const errorCode = FORGOT_PASSWORD_ERROR[_error.code];
        if (typeof errorCode === 'number') {
          myError = new ErrorWithCode(errorCode);
        } else {
          myError = new ErrorWithCode(ClientErrorCode.UnknownError);
        }
      }
      unstable_batchedUpdates(() => {
        setError(myError);
        setLoadFailed();
      });
    }
  };
  return {
    email,
    setEmail,
    loadingState,
    error,
    resetPassword,
  };
};

export default useForgotPasswordForm;
