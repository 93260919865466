enum WolfPackChallengeStatus {
  ToBeAccepted = 'toBeAccepted',
  Accepted = 'accepted',
  WaitingForFans = 'waitingForFans',
  Drawing = 'drawing',
  TeamIsAssembled = 'teamIsAssembled',
  ToBeReadyChecked = 'toBeReadyChecked',
  ReadyCheck = 'readyCheck',
  ReadyCheckFailed = 'readyCheckFailed',
  Launching = 'launching',
  Launched = 'launched',
  Finished = 'finished',
  Failed = 'failed',
}

export default WolfPackChallengeStatus;
