import { useMemo } from 'react';
import { doc, QueryDocumentSnapshot } from 'firebase/firestore';

import { firestore } from '../common/firebaseClient';
import useFirestoreDoc from '../common/useFirestoreDocSnapshot';

interface AppConfig {
  feature: {
    [key: string]: any;
  };
  maintenance: boolean;
  minVersion: string;
}

const DataConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot<AppConfig>): AppConfig {
    return snapshot.data();
  },

  toFirestore(): {} {
    return {};
  },
};

const useAppConfig = () => {
  const ref = useMemo(() => doc(firestore, 'Configs', 'app').withConverter(DataConverter), []);

  const { data, loadingState, error } = useFirestoreDoc(ref);

  return {
    config: data,
    loadingState,
    error,
  };
};

export default useAppConfig;
