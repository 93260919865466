export const enum ExternalPlatformType {
  BattleNet = 'battlenet',
  Discord = 'discord',
  Steam = 'steam',
  Twitch = 'twitch',
}

export enum ExternalPlatformActionType {
  SignIn = 'signin',
  SignUp = 'signup',
  Connect = 'connect',
}
