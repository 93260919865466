import { Currency, Locale } from './types';
import { createContext } from 'react';

export interface LocaleAndCurrencyContextType {
  locale: Locale;
  currency: Currency;
  setLocale: (locale: Locale) => void;
  setCurrency: (currency: Currency) => void;
}

const LocaleAndCurrencyContext = createContext<LocaleAndCurrencyContextType>({
  locale: Locale.English,
  currency: Currency.USD,
  setLocale: () => {},
  setCurrency: () => {},
});

export default LocaleAndCurrencyContext;
