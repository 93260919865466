import styled from 'styled-components';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from './styleConstants';

export const StyledTable = styled.table`
  border-spacing: 0 2px;
  width: 100%;
  height: 100%;

  &:first-child {
    th {
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }

  &:last-child {
    th {
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
`;

export const HeaderTr = styled.tr`
  height: 40px;
  background: #33344c;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
  font-size: 14px;
  line-height: 20px;
  color: rgba(211, 206, 239, 0.5);

  th {
    &:first-child {
      padding-left: 35px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }
`;

export const BodyTr = styled.tr`
  background: #33344c;
`;

export const StyledTd = styled.td<Partial<{ $width: number }>>`
  padding: 0;
  text-align: center;
  width: ${({ $width }) => `${$width}px` || 'initial'};

  &:first-child {
    padding-left: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    padding-right: 20px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
