import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FONT_FAMILY_ROBOTO, FONT_WEIGHT_REGULAR } from '@/common/ui';

enum Status {
  Success,
  Warning,
  Error,
}

const Root = styled.div`
  .react-datepicker-popper {
    width: 328px;
  }
`;

const Heading = styled.span`
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.01em;
`;

const CustomInputEl = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const COLOR_BY_STATUS: Record<Status, string> = {
  [Status.Success]: 'rgba(126, 215, 84, 1)',
  [Status.Warning]: 'rgba(251, 192, 45, 1)',
  [Status.Error]: 'rgba(233, 30, 99, 1)',
};

const Hint = styled.span<{ $status: Status }>`
  color: ${({ $status }) => COLOR_BY_STATUS[$status]};
  font-size: 12px;
  font-family: ${FONT_FAMILY_ROBOTO};
  font-weight: ${FONT_WEIGHT_REGULAR};
`;

const filterDate = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date >= today;
};

const TIME_INTERVALS = 15;

const filterTime = (date: Date) => {
  const a = date.getMinutes() % TIME_INTERVALS;
  const b = new Date();
  b.setMinutes(b.getMinutes() + TIME_INTERVALS - a);
  return date >= b;
};

const getStatus = (date: Date) => {
  const diff = date.getTime() - Date.now();
  if (diff < 0) {
    return Status.Error;
  }
  if (diff >= 15 * 60 * 1000) {
    return Status.Success;
  }
  if (diff >= 5 * 60 * 1000) {
    return Status.Warning;
  }
  return Status.Error;
};

interface DatePickerProps {
  id?: string;
  value: Date;
  onChange: (date: Date) => void;
}

const DatePicker = (props: DatePickerProps) => {
  const { id, value, onChange } = props;
  const { t } = useTranslation('createExperience');

  const handleChange = (date: Date) => {
    onChange?.(date);
  };

  return (
    <Root>
      <ReactDatePicker
        id={id}
        showTimeInput
        showTimeSelect
        timeIntervals={TIME_INTERVALS}
        selected={value}
        filterDate={filterDate}
        filterTime={filterTime}
        onChange={handleChange}
        customInput={
          <CustomInputEl>
            <Heading>{t('experienceDetails.input.datePicker.heading', { date: value })}</Heading>
            <Hint $status={getStatus(value)}>{t('experienceDetails.input.datePicker.hint', { date: value })}</Hint>
          </CustomInputEl>
        }
      />
    </Root>
  );
};

export default DatePicker;
