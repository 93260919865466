enum WolfPackChallengeCounterStrikeMap {
  WingmanVertigo = 'de_vertigo',
  WingmanInferno = 'de_inferno',
  WingmanOverpass = 'de_overpass',
  WingmanCbble = 'de_cbble',
  WingmanTrain = 'de_train',
  WingmanShortnuke = 'de_shortnuke',
  WingmanShortdust = 'de_shortdust',
  WingmanGuard = 'de_guard',
  WingmanElysion = 'de_elysion',
  WingmanLake = 'de_lake',
  CompetitiveMirage = 'de_mirage',
  CompetitiveInferno = 'de_inferno',
  CompetitiveOverpass = 'de_overpass',
  CompetitiveVertigo = 'de_vertigo',
  CompetitiveNuke = 'de_nuke',
  CompetitiveTrain = 'de_train',
  CompetitiveDust2 = 'de_dust2',
  CompetitiveAnubis = 'de_anubis',
  CompetitiveApollo = 'de_apollo',
  CompetitiveSwamp = 'de_ancient',
  CompetitiveEngage = 'de_engage',
  CompetitiveCache = 'de_cache',
  CompetitiveCbble = 'de_cbble',
  CompetitiveAgency = 'cs_agency',
  CompetitiveOffice = 'cs_office',
  AimUsp = '2634052720_1563657794',
  AimBioveTexture = '2260023316_535541366',
  AimCanoodle = '2634052720_942708831',
  AimDust1on1 = '2634052720_305726849',
  AimMap = '2258973237_122443683',
  AimPillars = '2634052720_405447392',
  AimRedline = '2260020824_125995702',
  AimAwpIndia = '2260016171_125610094',
}

export default WolfPackChallengeCounterStrikeMap;
