import { HandleTwitchConnectErrorCode } from '@/common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultErrorView from '../DefaultErrorView';
import ErrorPageProps from '../ErrorPageProps';

const ErrorMessageKey: Record<number, string> = {
  [HandleTwitchConnectErrorCode.InvalidCode]: 'invalidCode',
  [HandleTwitchConnectErrorCode.InvalidState]: 'invalidState',
  [HandleTwitchConnectErrorCode.ExternalAccountAlreadyInUse]: 'externalAccountAlreadyInUse',
  [HandleTwitchConnectErrorCode.ProfileNotFound]: 'profileNotFound',
};

const TwitchConnectErrorPage = ({ errorCode }: ErrorPageProps) => {
  const { t } = useTranslation('errors');
  return (
    <DefaultErrorView
      heading={t(`twitchConnectErrorPage.heading`)}
      description={t(`twitchConnectErrorPage.errorCodes.${ErrorMessageKey[errorCode]}`)}
    />
  );
};

export default TwitchConnectErrorPage;
