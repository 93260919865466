enum PartyStatus {
  WaitingForFans = 'waitingForFans',
  Drawing = 'drawing',
  TeamIsAssembled = 'teamIsAssembled',
  ToBeReadyChecked = 'toBeReadyChecked',
  ReadyCheck = 'readyCheck',
  ReadyCheckFailed = 'readyCheckFailed',
  Launched = 'launched',
  Finished = 'finished',
  Failed = 'failed',
}

export default PartyStatus;
