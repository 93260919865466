import { createContext } from 'react';

import { Campaign, CampaignFundraiser } from '@/pages/Charity/temp/model/types';

interface CampaignContextType {
  campaign: Campaign;
  fundraiser?: CampaignFundraiser;
}

const CampaignContext = createContext<CampaignContextType>({} as CampaignContextType);

export default CampaignContext;
