enum PartyCheckInErrorCode {
  PartyNotFound = 273320,
  UserNotFound = 273321,
  NotSweepstake = 273322,
  StatsNotFound = 273323,
  CheckInWrongStatus = 273324,
  ParticipantNotFound = 273325,
}

export default PartyCheckInErrorCode;
