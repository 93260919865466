import { PaymentMethod } from '@/common/types';
import { tint } from 'polished';
import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '@/common/ui';

import ArrowSvg from './arrow-right.svg';
import CardSvg from './card.svg';
import PaypalSvg from './paypal.svg';
import SkinscashSvg from './skinscash.svg';

const MethodSpecificStyles: Record<PaymentMethod, FlattenSimpleInterpolation> = {
  [PaymentMethod.Stripe]: css`
    background: linear-gradient(90deg, #486581 0%, #334e68 100%);
    &:focus:not(:focus-visible),
    &:hover {
      background: linear-gradient(90deg, ${tint(0.1, '#486581')} 0%, ${tint(0.1, '#334e68')} 100%);
    }
  `,
  [PaymentMethod.PayPal]: css`
    background: linear-gradient(90deg, #1992d4 0%, #0b69a3 100%);

    &:focus:not(:focus-visible),
    &:hover {
      background: linear-gradient(90deg, ${tint(0.1, '#1992d4')} 0%, ${tint(0.1, '#0b69a3')} 100%);
    }
  `,
  [PaymentMethod.SkinsCash]: css`
    background: linear-gradient(90deg, #079a82 0%, #016457 100%);
    &:focus:not(:focus-visible),
    &:hover {
      background: linear-gradient(90deg, ${tint(0.1, '#079a82')} 0%, ${tint(0.1, '#016457')} 100%);
    }
  `,
};

const Container = styled.button<{ $paymentMethod: PaymentMethod }>`
  align-items: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  width: 100%;
  ${({ $paymentMethod }) => MethodSpecificStyles[$paymentMethod]};
`;

const Icon = styled.svg`
  width: 36px;
  color: #fff;
`;

const PaymentMethodIcon: Record<PaymentMethod, FunctionComponent> = {
  [PaymentMethod.Stripe]: CardSvg,
  [PaymentMethod.PayPal]: PaypalSvg,
  [PaymentMethod.SkinsCash]: SkinscashSvg,
};

const Arrow = styled(ArrowSvg)`
  height: 24px;
  margin-left: auto;
`;

const Text = styled.span`
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  letter-spacing: -0.02em;
  line-height: 30px;
  margin-left: 20px;
`;

const PaymentMethodName: Record<PaymentMethod, string> = {
  [PaymentMethod.Stripe]: 'Credit/debit card',
  [PaymentMethod.PayPal]: 'PayPal',
  [PaymentMethod.SkinsCash]: 'Skins.Cash',
};

interface PaymentMethodProps {
  className?: string;
  paymentMethod: PaymentMethod;
  onClick: () => void;
}

const PaymentMethodComponent = ({ className, paymentMethod, onClick }: PaymentMethodProps) => (
  <Container type="button" $paymentMethod={paymentMethod} className={className} onClick={onClick}>
    <Icon as={PaymentMethodIcon[paymentMethod]} />
    <Text>{PaymentMethodName[paymentMethod]}</Text>
    <Arrow />
  </Container>
);

export default PaymentMethodComponent;
