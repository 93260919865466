import React, { EventHandler, MouseEventHandler, SyntheticEvent } from 'react';

import ampli from '@/services/ampli';
import { Col } from '@/components';

interface Props {
  children: React.ReactElement;
  event?: string;
  data?: any;
  wrap?: boolean;
}

const wrapHandlers = (
  listener: EventHandler<SyntheticEvent>,
  decorator: EventHandler<SyntheticEvent>
): EventHandler<SyntheticEvent> => {
  return (event: SyntheticEvent) => {
    event.persist();
    decorator(event);
    listener(event);
  };
};

const mergeHandlers = (childOnClick: MouseEventHandler, onClick: MouseEventHandler) => {
  return childOnClick ? wrapHandlers(childOnClick, onClick) : onClick;
};

const AmpliWrapper: React.FC<Props> = React.forwardRef((props, ref) => {
  const { children, event, data, wrap = false, ...rest } = props;

  const handleClick = () => {
    if (event) {
      ampli.log(event, data);
    }
  };

  if (wrap) {
    return (
      <Col onClick={handleClick} {...rest}>
        {children}
      </Col>
    );
  }

  return React.cloneElement(children, {
    ...children.props,
    ...rest,
    onClick: mergeHandlers(children?.props?.onClick, handleClick),
    ref,
  });
});

export default AmpliWrapper;
