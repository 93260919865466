import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_EXTRA_BOLD } from '@/common/ui';

const IconEl = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  color: #fff;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT_EXTRA_BOLD};
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
`;

const IconSoon: React.FC = () => {
  const { t } = useTranslation();
  return <IconEl>{t('soon')}</IconEl>;
};

export default IconSoon;
