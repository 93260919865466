import styled from 'styled-components';
import { v4 } from 'uuid';
import React, { FC, ReactNode, useRef } from 'react';

import { SolidSpinner } from '@/common/ui/SolidSpinner';
import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '@/common/ui';

const Root = styled.label`
  align-items: center;
  background: rgba(210, 207, 236, 0.15);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 14px;
  line-height: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  justify-content: space-between;
  height: 60px;
  margin-top: auto;
  padding: 0 25px;
  opacity: 1;
  cursor: pointer;
  width: 100%;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  opacity: 0;
  width: 0;
  clip: rect(0, 0, 0, 0);
  position: absolute;
`;

const SLIDER_WIDTH = 48;
const DOT_SIZE = 20;
const SLIDER_PADDING = 2;

const Slider = styled.div`
  background: rgba(210, 207, 236, 0.45);
  border-radius: 15px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  transition: 0.4s;
  padding: ${SLIDER_PADDING}px;
  width: ${SLIDER_WIDTH}px;
  height: ${DOT_SIZE + 2 * SLIDER_PADDING}px;
`;

const Dot = styled.div`
  background-color: white;
  border-radius: 100px;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  left: ${SLIDER_PADDING}px;
  position: absolute;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Toggle = styled.div`
  display: inline-block;
  position: relative;

  & ${Checkbox}:checked + ${Slider} {
    background-color: #56B15E;
    ${Dot} {
      left: ${SLIDER_WIDTH - DOT_SIZE - SLIDER_PADDING}px;
    }
  }

  & ${Checkbox}:disabled + ${Slider} {
    opacity: 0.4;
    pointer-events: none;
  }
}
`;

const Spinner = styled(SolidSpinner)`
  margin-left: 10px;
`;

interface MethodToggleProps {
  checked: boolean;
  content: ReactNode;
  onClick: () => any;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const MethodToggle: FC<MethodToggleProps> = ({ content, checked, onClick, loading, ...props }) => {
  const ref = useRef<string>(v4());
  return (
    <Root htmlFor={ref.current} {...props}>
      <Content>
        {content}
        {loading && <Spinner />}
      </Content>
      <Toggle>
        <Checkbox id={ref.current} type="checkbox" checked={checked} disabled={props.disabled} onChange={onClick} />
        <Slider>
          <Dot />
        </Slider>
      </Toggle>
    </Root>
  );
};

export default MethodToggle;
