enum PartyPurchaseEntriesErrorCode {
  PartyNotFound = 72789730,
  UserNotFound = 7278971,
  NotSweepstake = 7278972,
  UserIsBanned = 7278973,
  StatsNotFound = 7278974,
  InsufficientFunds = 7278975,
  AlternativeEntryAlreadyUsed = 7278976,
}

export default PartyPurchaseEntriesErrorCode;
