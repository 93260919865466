import styled from 'styled-components';

const Link = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  color: rgba(211, 206, 239, 0.8);
  text-decoration: none;

  &:focus:not(:focus-visible),
  &:hover {
    color: rgba(211, 206, 239, 1);
  }
`;

export default Link;
