import { Component, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
}

export default class Portal extends Component<PortalProps> {
  private node?: HTMLDivElement;

  componentWillUnmount() {
    if (this.node) {
      document.body.removeChild(this.node);
    }
  }

  render() {
    const { children } = this.props;
    if (!this.node) {
      this.node = document.createElement('div');
      document.body.appendChild(this.node);
    }
    return createPortal(children, this.node);
  }
}
