import { ParsedUrlQuery } from 'querystring';

import ExperienceTemplates from '../pages/Experience/games/ExperienceTemplates';
import GameTypes from '../pages/Experience/games/GameTypes';

export interface CreateExperienceQueryParams extends ParsedUrlQuery {
  game?: GameTypes;
  template?: ExperienceTemplates;
}

export const getPlainCreateExperiencePagePath = () => '/experiences/create';

const getCreateExperiencePagePath = (params?: CreateExperienceQueryParams): string => {
  const { hash, pathname, search } = window.location;
  const fromURL = `${pathname}${search}${hash}`;
  const searchParams = new URLSearchParams(search);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.append(key, value as string);
      }
    });
  }

  if (searchParams.has('fromURL')) {
    return `${getPlainCreateExperiencePagePath()}?${searchParams.toString()}`;
  }

  searchParams.append('fromURL', fromURL);

  return `${getPlainCreateExperiencePagePath()}${
    searchParams.toString() ? `?${searchParams.toString()}` : searchParams.toString()
  }`;
};

export default getCreateExperiencePagePath;
