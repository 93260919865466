enum AcceptDuelOfHonorErrorCode {
  ExperienceNotFound = 3200,
  InvalidExperienceType = 3201,
  InvalidDuelOfHonorStatus = 3202,
  UserIsNotARival = 3203,
  SteamRequired = 3204,
  RivalNotFound = 3205,
}

export default AcceptDuelOfHonorErrorCode;
