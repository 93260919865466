import { css } from 'styled-components';

const Scroll = css`
  scrollbar-color: #d3ceef10 #00000000;
  scrollbar-width: 16px;

  ::-webkit-scrollbar {
    width: 16px;
    background-color: #00000000;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
    background: transparent;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #d3ceef10;
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: content-box;

    &:hover {
      background-color: #d3ceef18;
    }
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background-color: #d3ceef20;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
  }
`;

export default Scroll;
