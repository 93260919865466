import styled from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE, FONT_WEIGHT_MEDIUM } from '../ui';

const Dropdown = styled.div`
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 55px;
  right: -5px;
  background: #3a3377;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(10, 16, 35, 0.5);
  display: flex;
  flex-direction: column;
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 17px;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  overflow: hidden;
`;

export const ItemText = styled.span`
  font-weight: ${FONT_WEIGHT_MEDIUM};
  margin-left: 15px;
`;

export const ItemIcon = styled.svg`
  height: 18px;
  width: 18px;
`;

export const ItemStatus = styled.div`
  margin-left: auto;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

export const DropdownGroup = styled.div`
  padding: 10px 0;
`;

export const DropdownGroupSeparator = styled.div`
  height: 1px;
  padding: 0 20px;

  &::before {
    background: rgba(255, 255, 255, 0.1);
    content: '';
    display: block;
    height: 1px;
  }
`;

export const DropdownItem = styled.button`
  align-items: center;
  background: rgba(255, 255, 255, 0);
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  min-height: 50px;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;

  ${ItemIcon} {
    fill: currentColor;
  }

  ${ItemStatus} {
    fill: currentColor;
  }

  &:hover,
  &:focus {
    background: rgba(211, 206, 239, 0.1);
  }
`;

export default Dropdown;
