enum TaskStatus {
  Created = 'created',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Fulfilled = 'fulfilled',
  Deferred = 'deferred',
}

export default TaskStatus;
