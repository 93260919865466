import { parse, ParsedUrlQuery } from 'querystring';

const useQueryParams = <T extends ParsedUrlQuery = ParsedUrlQuery>(defaults?: Partial<T>): Partial<T> => {
  const { location } = window;
  const params = parse(location.search.slice(1)) as T;
  if (defaults) {
    return { ...defaults, ...params };
  }
  return params;
};

export default useQueryParams;
