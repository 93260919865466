import styled from 'styled-components';

import { FONT_FAMILY_BRUTAL_TYPE } from './ui';

const Label = styled.label`
  color: rgba(255, 255, 255, 0.3);
  font-family: ${FONT_FAMILY_BRUTAL_TYPE};
  font-size: 14px;
  font-weight: 700;
`;

export default Label;
