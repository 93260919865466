import { getPlainForgotPasswordPath } from './getForgotPasswordPath';
import { getPlainSignUpPath } from './getSignUpPath';

export const getPlainLogInPath = () => '/log-in';

const getSignInPath = (): string => {
  const { hash, pathname, search } = window.location;
  const redirectURL = `${pathname}${search}${hash}`;
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('redirectURL')) {
    return `${getPlainLogInPath()}?${searchParams.toString()}`;
  }

  if (!['/', getPlainLogInPath(), getPlainSignUpPath(), getPlainForgotPasswordPath()].includes(pathname)) {
    searchParams.append('redirectURL', redirectURL);
  }
  return `${getPlainLogInPath()}${searchParams.toString() ? `?${searchParams.toString()}` : searchParams.toString()}`;
};

export default getSignInPath;
