import SweepstakeEntriesBundle from './SweepstakeEntriesBundle';

const SweepstakeEntriesBundlePrice: Record<SweepstakeEntriesBundle, number> = {
  [SweepstakeEntriesBundle.Free]: 0,
  [SweepstakeEntriesBundle.XSmall]: 20,
  [SweepstakeEntriesBundle.Small]: 80,
  [SweepstakeEntriesBundle.Medium]: 400,
  [SweepstakeEntriesBundle.Large]: 2000,
  [SweepstakeEntriesBundle.ManyLarge]: 6000,
};

export default SweepstakeEntriesBundlePrice;
